import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const EyeCrossedIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.86723 7.1162L2.7601 5.2432L3.93843 4.1958L20.3196 18.7609L19.1412 19.8041L16.4472 17.4054L16.0545 17.0562C14.8022 17.504 13.4344 17.7504 12.0019 17.7504C7.38101 17.7504 3.43478 15.1956 1.83594 11.5892C2.5568 9.95859 3.76285 8.54972 5.28774 7.49L4.86723 7.1162ZM9.29869 11.0512L7.87084 9.78196C7.56586 10.3323 7.38101 10.9403 7.38101 11.5892C7.38101 13.8565 9.45118 15.6967 12.0019 15.6967C12.732 15.6967 13.4159 15.5324 14.0305 15.2613L12.6026 13.9921C12.4086 14.0291 12.2099 14.0537 12.0019 14.0537C10.4724 14.0537 9.22938 12.9488 9.22938 11.5892C9.22938 11.4044 9.2571 11.2278 9.29869 11.0512ZM12.0031 7.48194C14.5538 7.48194 16.624 9.32209 16.624 11.5894C16.624 12.1193 16.5039 12.6245 16.2959 13.0886L18.9992 15.4915C20.3947 14.4564 21.4944 13.1174 22.1737 11.5894C20.5703 7.98307 16.6286 5.42822 12.0031 5.42822C10.7093 5.42822 9.47091 5.63358 8.32031 6.00325L10.3165 7.77356C10.8386 7.59284 11.407 7.48194 12.0031 7.48194ZM11.8516 9.13716L14.7627 11.7249L14.7766 11.5893C14.7766 10.2297 13.5336 9.12484 12.0041 9.12484L11.8516 9.13716Z"
        fill="currentColor"
      />
    </Icon>
  );
};
