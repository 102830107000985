import { Box, Tooltip, TooltipProps } from '@chakra-ui/react';
import { ReactElement, useState } from 'react';

export const TooltipWithTouch = ({ children, ...restToolTipProps }: TooltipProps): ReactElement => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);

  return (
    <Tooltip isOpen={isLabelOpen} {...restToolTipProps}>
      <Box
        onMouseEnter={() => setIsLabelOpen(true)}
        onMouseLeave={() => setIsLabelOpen(false)}
        onClick={() => setIsLabelOpen(true)}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
