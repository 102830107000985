import { ReactElement } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ChakraProvider } from '@chakra-ui/react';
import { queryClient } from 'utils/react-query';
import { theme } from 'styles/theme/theme';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AlgoliaInstantSearchProvider } from './InstantSearchProvider';
import { TopBarProvider } from './TopBarProvider';
import { MetaProvider } from './MetaProvider';
import { BreadcrumbProvider } from './BreadcrumbProvider';
import { ModalContextProvider } from './ModalContextProvider';
import { ModalProvider } from './ModalProvider';
import { GOOGLE_AUTH_CLIENT_ID } from '../../config/env';
import { FeatureFlagsProvider } from './FeatureFlags';

type Props = {
  children: ReactElement | ReactElement[];
};

export const Providers = ({ children }: Props): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <ReactQueryDevtools />
        <FeatureFlagsProvider>
          <TopBarProvider>
            <MetaProvider>
              <ModalContextProvider>
                <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
                  <ModalProvider>
                    <BreadcrumbProvider>
                      <AlgoliaInstantSearchProvider>{children}</AlgoliaInstantSearchProvider>
                    </BreadcrumbProvider>
                  </ModalProvider>
                </GoogleOAuthProvider>
              </ModalContextProvider>
            </MetaProvider>
          </TopBarProvider>
        </FeatureFlagsProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};
