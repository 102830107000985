import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const AgegroupSeniorsIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <rect
        x="2.08438"
        y="1.15762"
        width="19.8312"
        height="21.6847"
        rx="3.4"
        stroke="currentColor"
        strokeWidth="1.2"
        fill="none"
      />
      <path
        d="M11.992 17.8543C11.064 17.8543 10.2853 17.7157 9.656 17.4383C9.02667 17.1503 8.54133 16.7823 8.2 16.3343C7.86933 15.8757 7.69333 15.3903 7.672 14.8783C7.672 14.793 7.704 14.7183 7.768 14.6543C7.84267 14.5797 7.928 14.5423 8.024 14.5423H8.84C8.968 14.5423 9.064 14.5743 9.128 14.6383C9.192 14.7023 9.23467 14.777 9.256 14.8623C9.30933 15.1183 9.43733 15.3743 9.64 15.6303C9.85333 15.8757 10.1467 16.0837 10.52 16.2543C10.904 16.4143 11.3947 16.4943 11.992 16.4943C12.9093 16.4943 13.5813 16.3343 14.008 16.0143C14.4453 15.6837 14.664 15.2463 14.664 14.7023C14.664 14.329 14.5467 14.0303 14.312 13.8063C14.088 13.5717 13.7413 13.3637 13.272 13.1823C12.8027 13.001 12.1947 12.8037 11.448 12.5903C10.6693 12.3663 10.024 12.121 9.512 11.8543C9 11.5877 8.616 11.257 8.36 10.8623C8.11467 10.4677 7.992 9.96634 7.992 9.35834C7.992 8.78234 8.14667 8.27034 8.456 7.82234C8.76533 7.36367 9.208 7.001 9.784 6.73433C10.3707 6.46767 11.08 6.33434 11.912 6.33434C12.5733 6.33434 13.1493 6.425 13.64 6.60634C14.1413 6.777 14.5573 7.01167 14.888 7.31034C15.2187 7.59834 15.4693 7.913 15.64 8.25434C15.8107 8.585 15.9013 8.91034 15.912 9.23034C15.912 9.305 15.8853 9.37967 15.832 9.45434C15.7787 9.51834 15.6933 9.55034 15.576 9.55034H14.728C14.6533 9.55034 14.5733 9.529 14.488 9.48634C14.4133 9.44367 14.3547 9.36367 14.312 9.24634C14.248 8.809 14.0027 8.441 13.576 8.14234C13.1493 7.84367 12.5947 7.69434 11.912 7.69434C11.2187 7.69434 10.6533 7.82767 10.216 8.09434C9.78933 8.361 9.576 8.777 9.576 9.34234C9.576 9.705 9.67733 10.009 9.88 10.2543C10.0827 10.489 10.4027 10.697 10.84 10.8783C11.2773 11.0597 11.848 11.2517 12.552 11.4543C13.4053 11.689 14.104 11.9397 14.648 12.2063C15.192 12.4623 15.592 12.7877 15.848 13.1823C16.1147 13.5663 16.248 14.0623 16.248 14.6703C16.248 15.3423 16.072 15.9183 15.72 16.3983C15.368 16.8677 14.872 17.2303 14.232 17.4863C13.6027 17.7317 12.856 17.8543 11.992 17.8543Z"
        fill="currentColor"
      />
    </Icon>
  );
};
