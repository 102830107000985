import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const ClockIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 18 18" {...props} fill="none">
      <g clipPath="url(#clip0_7824_177865)">
        <path d="M9 4.5V9.75L12 12" stroke="#808080" strokeWidth="1.2" strokeLinecap="round" />
        <circle cx="9" cy="9" r="7.05" stroke="#808080" strokeWidth="1.2" />
      </g>
      <defs>
        <clipPath id="clip0_7824_177865">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
