import { axios } from 'utils/axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { queryClient } from 'utils/react-query';

export const removeLike = async (id: string): Promise<void> => {
  await axios.delete(`user/like/${id}`);
};

export const useRemoveLike = (): UseMutationResult<void, unknown, string, unknown> => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['posts'],
      });
    },
    mutationFn: removeLike,
  });
};
