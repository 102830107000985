import { useCallback, useEffect, useState } from 'react';

export const useDocumentHeight = (): { height: number; offsetTop: number } => {
  const getHeight = useCallback(
    () => (window.visualViewport ? window.visualViewport.height : window.innerHeight),
    []
  );

  const getOffset = useCallback(
    () => (window.visualViewport ? window.visualViewport.offsetTop : 0),
    []
  );

  const [height, setHeight] = useState<number>(getHeight());
  const [offsetTop, setOffsetTop] = useState<number>(getOffset());

  useEffect(() => {
    const handleResize = (): void => {
      setHeight(getHeight());
      setOffsetTop(getOffset());
    };

    window.addEventListener('resize', handleResize);
    // for ios
    window.visualViewport?.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.visualViewport?.removeEventListener('resize', handleResize);
    };
  }, [getHeight, getOffset]);

  return { height, offsetTop };
};
