/**
 * For all textStyles look at Typography/Font-Styles in Figma UI Kit.
 *
 * The proper style to use is displayed in the "Text Styles" section of the text component in Figma.
 * - for headings use h1-h5 (only in h4 there's a fontWeight distinction: add .reg/med)
 * - for body use body.fontSize.fontWeight, e.g. body.16.med
 * IMPORTANT: only works on Text components (not on Headings or Buttons) -> do not use the Heading component, use Text with the appropriate style instead
 */

export const textStyles = {
  h1: {
    fontSize: '26px',
    fontWeight: '500',
    lineHeight: '100%',
  },
  h2: {
    fontSize: '22px',
    fontWeight: '500',
    lineHeight: '120%',
  },
  h3: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '120%',
  },
  h4: {
    med: {
      fontSize: '18px',
      fontWeight: '500',
      lineHeight: '100%',
    },
    reg: {
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '130%',
    },
  },
  body: {
    18: {
      med: {
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '140%',
      },
      reg: {
        fontSize: '18px',
        lineHeight: '140%',
      },
      ita: {
        fontSize: '18px',
        lineHeight: '140%',
        fontStyle: 'italic',
      },
    },
    16: {
      med: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '22.4px',
      },
      reg: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '130%',
      },
      ita: {
        fontSize: '16px',
        fontWeight: '400',
        fontStyle: 'italic',
        lineHeight: '20.8px',
      },
      lig: {
        fontSize: '16px',
        fontWeight: '300',
        lineHeight: '100%',
      },
    },
    15: {
      med: {
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '100%',
      },
    },
    14: {
      med: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '130%',
      },
      reg: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '134.5%',
      },
      lig: {
        fontSize: '14px',
        fontWeight: '300',
        lineHeight: '130%',
      },
    },
    12: {
      med: {
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '130%',
      },
      reg: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '130%',
      },
      lig: {
        fontSize: '12px',
        fontWeight: '300',
        lineHeight: '130%',
      },
    },
    10: {
      med: {
        fontSize: '10px',
        fontWeight: '500',
        lineHeight: '100%',
      },
      reg: {
        fontSize: '2xs',
        fontWeight: '400',
        lineHeight: '100%',
      },
    },
  },
};
