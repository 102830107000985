export const getYearCollectionString = (years: string[], customSuffix?: string): string => {
  const getYearType = (year: string): string => customSuffix || `${year.split('.')[1]}`;
  const getYearNumber = (year: string): number => parseInt(year.split('.')[0], 10);

  let yearNumbersAsString = '';
  const yearType = getYearType(years[0]);

  if (years.length > 1) {
    years
      .map((year: string) => getYearNumber(year))
      .sort((a: number, b: number) => a - b)
      .forEach((num: number, index: number) => {
        yearNumbersAsString += index >= years.length - 1 ? `${num}.` : `${num}. + `;
      });

    return `${yearNumbersAsString} ${yearType}`;
  }

  yearNumbersAsString = `${getYearNumber(years[0])}.`;
  return years[0];
};
