import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

type Props = IconProps & {
  bgColor?: string;
};
export const ExclamationCircleIcon = ({ bgColor, ...props }: Props): ReactElement => {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <g id="icon">
        <circle
          id="Ellipse 190"
          cx="16"
          cy="16"
          r="12.25"
          fill={bgColor || 'transparent'}
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          id="Vector"
          d="M16.0001 18.0729C16.2352 18.0729 16.4607 17.9511 16.627 17.7344C16.7933 17.5177 16.8867 17.2238 16.8867 16.9173V11.5662C16.8867 11.2597 16.7933 10.9658 16.627 10.7491C16.4607 10.5324 16.2352 10.4106 16.0001 10.4106C15.7649 10.4106 15.5394 10.5324 15.3731 10.7491C15.2068 10.9658 15.1134 11.2597 15.1134 11.5662V16.8995C15.1116 17.0528 15.1332 17.205 15.1769 17.3472C15.2207 17.4895 15.2857 17.619 15.3682 17.7282C15.4507 17.8373 15.5491 17.9241 15.6576 17.9832C15.766 18.0424 15.8825 18.0729 16.0001 18.0729Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M16 21.5894C16.5523 21.5894 17 21.1416 17 20.5894C17 20.0371 16.5523 19.5894 16 19.5894C15.4477 19.5894 15 20.0371 15 20.5894C15 21.1416 15.4477 21.5894 16 21.5894Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
};
