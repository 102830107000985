import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const QuestionCircleIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M11.9998 21.3744C9.56042 21.3744 7.38837 20.7779 5.6635 19.053C3.93862 17.3281 2.77847 14.506 2.77847 12.0667C2.77847 9.62731 3.8512 7.36785 5.57608 5.64297C7.30095 3.9181 9.56042 2.88801 11.9998 2.88801C14.4391 2.88801 17.1275 4.00158 18.8524 5.72645C20.5773 7.45133 21.1802 9.62731 21.1802 12.0667C21.1802 14.506 20.2318 16.8919 18.5069 18.6168C16.782 20.3417 14.4391 21.3744 11.9998 21.3744ZM11.9998 22.5781C14.7876 22.5781 17.4612 21.4707 19.4325 19.4994C21.4038 17.5281 22.5112 14.8545 22.5112 12.0667C22.5112 9.27883 21.4038 6.6052 19.4325 4.63392C17.4612 2.66263 14.7876 1.55518 11.9998 1.55518C9.21194 1.55518 6.5383 2.66263 4.56702 4.63392C2.59574 6.6052 1.48828 9.27883 1.48828 12.0667C1.48828 14.8545 2.59574 17.5281 4.56702 19.4994C6.5383 21.4707 9.21194 22.5781 11.9998 22.5781Z"
        fill="currentColor"
      />
      <path
        d="M8.40262 9.15209C8.40082 9.19452 8.40772 9.23688 8.4229 9.27654C8.43807 9.31621 8.4612 9.35235 8.49086 9.38275C8.52052 9.41315 8.55608 9.43716 8.59537 9.4533C8.63465 9.46945 8.67682 9.47739 8.71928 9.47663C8.71928 9.47663 9.64995 9.58284 9.64995 9.38275C9.64995 9.18266 9.6263 8.89365 9.64995 8.71364C9.7682 7.8517 10.9622 7.17874 12.0159 7.17874C12.9173 7.17874 14.3471 8.00897 14.3471 9.09296C14.3471 9.92731 13.7089 10.4418 12.9324 11.0252C12.2438 11.739 10.9995 12.387 11.0494 13.605L11.0533 13.8901C11.0547 13.9763 11.0899 14.0586 11.1514 14.119C11.2128 14.1795 11.2956 14.2134 11.3818 14.2134H12.238C12.3251 14.2134 12.4087 14.1788 12.4703 14.1172C12.5319 14.0556 12.5665 13.972 12.5665 13.8849V13.7469C12.5665 12.8035 12.9252 12.5289 13.8936 11.7944C14.6937 11.1861 15.5281 10.5107 15.5281 9.09296C15.5281 7.10761 13.8515 6.14844 12.0159 6.14844C10.3512 6.14844 8.52745 6.92366 8.40262 9.15209ZM10.84 16.7243C10.8533 17.2483 11.2531 17.6438 11.7755 17.6869C12.3455 17.734 12.8647 17.2961 12.8792 16.7243C12.894 16.1394 12.3586 15.663 11.7755 15.7113C11.2388 15.7557 10.8263 16.186 10.84 16.7243Z"
        fill="currentColor"
      />
    </Icon>
  );
};
