import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const SuppositoryIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M8.96484 8.29074C8.96484 7.52486 9.08994 6.79411 9.4153 6.15951C9.72941 5.54684 10.1749 4.81062 10.7212 4.23812C11.2734 3.65942 11.8638 3.31385 12.4748 3.32549C13.0823 3.33706 13.6598 3.70048 14.1913 4.28821C14.7169 4.8694 15.1368 5.60463 15.4275 6.20677C15.7186 6.80955 15.829 7.49576 15.829 8.2162V20.6749H8.96484V8.29074Z"
        stroke="currentColor"
        fill="none"
      />
      <path
        d="M12.0859 5.22192C14.0394 5.9595 14.3597 8.22491 14.267 10.0491C14.0834 13.6635 14.267 19.3126 14.267 19.3126"
        stroke="currentColor"
        strokeWidth="0.7"
        strokeLinecap="round"
        fill="none"
      />
    </Icon>
  );
};
