import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const DropsIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <mask id="path-1-inside-1_10416_122078" fill="white">
        <path d="M14.6835 13.173C14.2586 13.4808 13.6646 13.3857 13.3569 12.9608L10.5472 9.08089C10.2395 8.65593 10.3345 8.06197 10.7594 7.75423L17.7734 2.67501C18.1983 2.36727 18.7923 2.46229 19.1 2.88725L21.9097 6.76714C22.2174 7.19209 22.1224 7.78606 21.6974 8.09379L14.6835 13.173Z" />
      </mask>
      <path
        d="M21.9097 6.76714L21.525 7.04574L21.9097 6.76714ZM21.6974 8.09379L21.9761 8.47852L21.6974 8.09379ZM17.7734 2.67501L17.4948 2.29028L17.7734 2.67501ZM19.1 2.88725L19.4848 2.60865L19.1 2.88725ZM10.5472 9.08089L10.1625 9.35949L10.5472 9.08089ZM10.7594 7.75423L11.038 8.13896L10.7594 7.75423ZM13.3569 12.9608L13.7416 12.6822L13.3569 12.9608ZM14.6835 13.173L14.4049 12.7883L14.6835 13.173ZM13.7416 12.6822L10.9319 8.80229L10.1625 9.35949L12.9721 13.2394L13.7416 12.6822ZM11.038 8.13896L18.052 3.05973L17.4948 2.29028L10.4808 7.36951L11.038 8.13896ZM18.7153 3.16585L21.525 7.04574L22.2944 6.48853L19.4848 2.60865L18.7153 3.16585ZM21.4188 7.70907L14.4049 12.7883L14.9621 13.5577L21.9761 8.47852L21.4188 7.70907ZM21.525 7.04574C21.6788 7.25822 21.6313 7.5552 21.4188 7.70907L21.9761 8.47852C22.6135 8.01691 22.756 7.12597 22.2944 6.48853L21.525 7.04574ZM18.052 3.05973C18.2645 2.90586 18.5614 2.95338 18.7153 3.16585L19.4848 2.60865C19.0231 1.97121 18.1322 1.82868 17.4948 2.29028L18.052 3.05973ZM10.9319 8.80229C10.778 8.58981 10.8256 8.29283 11.038 8.13896L10.4808 7.36951C9.8434 7.83111 9.70086 8.72206 10.1625 9.35949L10.9319 8.80229ZM12.9721 13.2394C13.4337 13.8768 14.3247 14.0193 14.9621 13.5577L14.4049 12.7883C14.1924 12.9422 13.8954 12.8946 13.7416 12.6822L12.9721 13.2394Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_10416_122078)"
      />
      <path
        d="M11.0127 9.32435L13.2859 12.4634L10.5358 13.9232C10.5358 13.9232 8.34851 15.2799 6.57698 16.3158C4.80545 17.3516 3.3709 15.6624 5.09374 14.2676L10.1861 10.0277L11.0127 9.32435Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="0.475009"
      />
      <rect
        x="10.7891"
        y="15.5349"
        width="5.94824"
        height="2.0909"
        rx="0.475009"
        transform="rotate(-125.911 10.7891 15.5349)"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7494 6.7278L16.2852 11.6104L19.9382 8.96495L16.4024 4.08239L12.7494 6.7278ZM15.3272 8.79001L15.124 8.50934C15.0287 8.37773 15.0581 8.19378 15.1897 8.09848L15.575 7.81943L15.296 7.4341C15.2007 7.30249 15.2301 7.11854 15.3617 7.02323L15.6424 6.81998C15.774 6.72468 15.958 6.75411 16.0533 6.88572L16.3323 7.27105L16.7176 6.992C16.8493 6.8967 17.0332 6.92613 17.1285 7.05773L17.3318 7.3384C17.4271 7.47001 17.3976 7.65397 17.266 7.74927L16.8807 8.02832L17.1597 8.41365C17.2551 8.54526 17.2256 8.72921 17.094 8.82452L16.8133 9.02777C16.6817 9.12308 16.4978 9.09365 16.4025 8.96204L16.1234 8.5767L15.7381 8.85575C15.6065 8.95105 15.4225 8.92162 15.3272 8.79001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.52474 18.4741L5.66396 20.7475C5.68548 20.7857 5.70521 20.825 5.72304 20.8654L5.72552 20.871C5.79817 21.0375 5.83844 21.2212 5.83844 21.4143C5.83844 22.1694 5.22227 22.7816 4.46219 22.7816C3.70211 22.7816 3.08594 22.1694 3.08594 21.4143C3.08594 21.2224 3.12534 21.0397 3.1972 20.8739L3.19907 20.8705C3.23318 20.7924 3.29366 20.6554 3.34121 20.5856L4.52474 18.4741ZM4.06384 21.2056C4.02514 21.0976 3.97456 20.9564 4.00577 20.8294C4.04257 20.6797 4.14092 20.6026 4.21958 20.541C4.2963 20.4809 4.35428 20.4355 4.31816 20.3517C4.07978 20.2372 3.82788 20.4669 3.71253 20.7071C3.59719 20.9474 3.69693 21.2349 3.9353 21.3494C4.14569 21.4342 4.11526 21.3492 4.06384 21.2056Z"
        fill="currentColor"
      />
    </Icon>
  );
};
