import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const ArticleVideoIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2.76234"
          y="3.79042"
          width="18.4762"
          height="16.419"
          rx="1.63805"
          fill="#698BC1"
          stroke="#698BC1"
          strokeWidth="0.723899"
        />
        <path
          d="M16.0768 11.7847C16.2761 11.9012 16.2745 12.1898 16.0738 12.3043L10.2048 15.6513C10.0041 15.7657 9.75497 15.6201 9.75627 15.3892L9.79452 8.63427C9.79583 8.40333 10.0466 8.2603 10.246 8.3768L16.0768 11.7847Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
};
