import { Textarea } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type TextFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  placeholder?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const TextField = (props: TextFieldProps): ReactElement => {
  const { label, className, registration, error, placeholder } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <Textarea
        className={className}
        {...registration}
        placeholder={placeholder}
        bg="bg.input"
        color="grey.textPrimary"
        border="1px solid"
        borderColor="grey.20"
        textStyle="body.16.reg"
        focusBorderColor="green.primary"
        borderBottom="none"
        borderTopRadius="4px"
        borderBottomRadius="0px"
      />
    </FieldWrapper>
  );
};
