import { HStack, Image, VStack, Text, Box } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { RelevantYearName, AgeGroup, Tags } from '@novaheal/types';
import { AnimatedHeartIcon } from 'assets/icons/Icons';
import { ArticleTags } from './ArticleTags';
import InformationBar from './InformationBar';

type Props = {
  coverImageUrl: string;
  title: string;
  readingTime: number;
  relevantYears: RelevantYearName[];
  ageGroups: AgeGroup[];
  tags: Tags;
  handleLikeChange: () => void;
  isLiked?: boolean | null;
};

export const ArticleHeroDesktop = ({
  coverImageUrl,
  title,
  readingTime,
  relevantYears,
  ageGroups,
  tags,
  isLiked = false,
  handleLikeChange,
}: Props): ReactElement => {
  return (
    <Box width="100%" padding="40px" backgroundColor="bg.light" borderRadius="15px 15px 0 0">
      <HStack spacing="35px" position="relative" overflow="hidden">
        <Image src={coverImageUrl} height="200px" width="200px" objectFit="cover" />
        <HStack position="absolute" top="0" right="0">
          {/* Share func has no usage on desktop */}
          {/* <ShareIOSIcon cursor="pointer" color="grey.tertiary" /> */}
          <AnimatedHeartIcon
            checked={!!isLiked}
            onClick={handleLikeChange}
            cursor="pointer"
            color="green.primary"
            w="30px"
            h="30px"
          />
        </HStack>
        <VStack width="100%" alignItems="flex-start" spacing="20px" overflow="auto">
          <Text as="h4" textStyle={{ base: 'h2', md: 'h1' }} color="blue.textHeader">
            {title}
          </Text>

          <InformationBar
            readingTime={readingTime}
            relevantYears={relevantYears}
            ageGroups={ageGroups}
          />

          <ArticleTags tags={tags} />
        </VStack>
      </HStack>
    </Box>
  );
};
