import { StrippedArticle, EntryType, LastViewedEntry, LastViewedMedication } from '@novaheal/types';
import { ProtectedRoutes } from 'types/Routes';
import medicationPlaceholder from 'assets/common/medication-placeholder.png';

/**
 * It takes an entry and returns a LastViewedEntry. It defaults to Article if no entryType is provided.
 * @param {StrippedArticle | LastViewedMedication | undefined} entry - StrippedArticle |
 * LastViewedMedication | undefined
 * @returns LastViewedEntry | null
 */
export const modifyLastViewedEntry = (
  entry: StrippedArticle | LastViewedMedication | undefined | null
): LastViewedEntry | null => {
  if (!entry) return null;
  if (entry.entryType === EntryType.MEDICATION) {
    return {
      title: entry.title,
      image: { url: medicationPlaceholder, description: '', bgColor: '#cef4eb' },
      link: `${ProtectedRoutes.MEDICATION}/${entry.id}`,
      id: entry.id,
      category: {
        title: 'Medikament',
        id: 'medication',
        image: { url: medicationPlaceholder, description: '' },
      },
    };
  }
  return {
    title: entry.title,
    image: entry.image,
    link: `${ProtectedRoutes.ARTICLE}/${entry.id}`,
    id: entry.id,
    category: entry.category,
  };
};
