/* eslint-disable jsx-a11y/media-has-caption */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  AspectRatio,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { CustomPlayButton } from 'components/common/VideoBox/CustomPlayButton';

type Props = {
  videoUrl: string;
  videoDescription: string;
  isModalOpen: boolean;
  onModalClose: () => void;
  isEmbedded?: boolean;
  onVideoEnd?: () => void;
  onVideoAbort?: () => void;
};

export const VideoModal = ({
  videoUrl,
  videoDescription,
  isModalOpen,
  onModalClose,
  isEmbedded = false,
  onVideoEnd,
  onVideoAbort,
}: Props): ReactElement => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [shouldShowPlayButton, setShouldShowPlayButton] = useState<boolean>(!isEmbedded);

  const handlePlayClick = (): void => {
    if (isEmbedded) return;

    if (videoRef.current?.paused || videoRef.current?.ended) {
      videoRef.current?.play();
      setShouldShowPlayButton(false);
    } else {
      videoRef.current?.pause();
      setShouldShowPlayButton(true);
    }
  };

  useEffect(() => {
    if (isEmbedded) return;

    const videoInstance = videoRef.current;
    if (videoInstance) {
      videoInstance.addEventListener('ended', () => {
        setShouldShowPlayButton(true);
      });
    }
    return () => {
      if (videoInstance) {
        videoInstance?.removeEventListener('ended', () => {
          setShouldShowPlayButton(true);
        });
      }
    };
  }, [videoRef, isEmbedded]);

  const handleClose = (): void => {
    if (
      videoRef.current &&
      videoRef.current.currentTime < videoRef.current.duration &&
      videoRef.current.currentTime > 0 &&
      onVideoAbort
    ) {
      onVideoAbort();
    }
    onModalClose();
  };

  return (
    <Modal size="full" isCentered isOpen={isModalOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent bg="black" borderRadius="0%" color="grey.textinvers" overflow="none">
        <ModalCloseButton color="grey.textinvers" position="fixed" zIndex={2} />
        <ModalBody display="grid" placeItems="center">
          <Box position="relative" width="100%" onClick={handlePlayClick}>
            {shouldShowPlayButton && (
              <Box
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                display="grid"
                placeItems="center"
              >
                <CustomPlayButton />
              </Box>
            )}
            <AspectRatio maxW="100%" ratio={16 / 9} width="100%">
              {isEmbedded ? (
                <iframe title={videoDescription} src={videoUrl} />
              ) : (
                <video title="test" src={videoUrl} ref={videoRef} controls onEnded={onVideoEnd}>
                  <source src={videoUrl} type="video/mp4" />
                </video>
              )}
            </AspectRatio>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
