import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const AcademicHatIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8262 8.85256L12.7485 3.55211L3.74942 8.85255L12.7485 14.2046L21.8262 8.85256ZM13.2336 2.2914C12.9322 2.11542 12.5593 2.11613 12.2586 2.29325L2.53468 8.02061C2.37104 8.117 2.24968 8.24971 2.17066 8.39996C2.04459 8.52125 1.96615 8.69168 1.96615 8.88044V16.2257C1.11183 16.4942 0.492188 17.2924 0.492188 18.2354V21.4538C0.492188 21.6668 0.66478 21.8393 0.877683 21.8393H4.31915C4.53205 21.8393 4.70464 21.6668 4.70464 21.4538V18.2354C4.70464 17.3179 4.11806 16.5375 3.29948 16.2486V10.1363L5.52032 11.4571L5.51994 17.2381C5.5199 17.8121 5.82173 18.3439 6.3146 18.6381L7.43259 19.3056C10.5656 21.1759 14.4541 21.2499 17.656 19.5L19.2216 18.6444C19.7447 18.3585 20.0701 17.8099 20.0701 17.2138L20.0705 11.4355L23.046 9.68121C23.6799 9.30746 23.678 8.38983 23.0425 8.01877L13.2336 2.2914ZM18.7371 12.2217L13.2371 15.4644C12.934 15.6431 12.5575 15.6424 12.2551 15.4625L6.8536 12.25L6.85327 17.2382C6.85327 17.3428 6.90826 17.4397 6.99807 17.4933L8.11605 18.1607C10.8436 19.7891 14.229 19.8535 17.0166 18.33L18.5822 17.4743C18.6775 17.4223 18.7368 17.3223 18.7368 17.2137L18.7371 12.2217ZM3.37131 20.506V18.2354C3.37131 17.8085 3.02527 17.4625 2.59842 17.4625C2.17156 17.4625 1.82552 17.8085 1.82552 18.2354V20.506H3.37131Z"
        fill="currentColor"
      />
    </Icon>
  );
};
