import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const MenuIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M8.00391 6.66724H20.0039"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
      <path
        d="M8.00391 12.0005H20.0039"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
      <path
        d="M8.00391 17.5181H20.0039"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
      <circle
        cx="4.99219"
        cy="6.45459"
        r="0.666667"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.666667"
      />
      <circle
        cx="4.99219"
        cy="12.0005"
        r="0.666667"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.666667"
      />
      <circle
        cx="4.99219"
        cy="17.5457"
        r="0.666667"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.666667"
      />
    </Icon>
  );
};
