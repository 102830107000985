import React, { ReactElement, useState } from 'react';
import {
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { NavigationLinkProps, PanelLink } from 'types/NavigationBarDesktop';
import { NavigationLink } from './NavigationLink';
import { Panel } from './DropdownPanel';

type NavigationLinkWithDropdownProps = NavigationLinkProps & {
  panelLinks: PanelLink[];
};

export const NavigationLinkWithDropdown = ({
  to,
  Icon,
  isActive,
  title,
  panelLinks,
  hasTitle,
  onClick,
}: NavigationLinkWithDropdownProps): ReactElement => {
  const [isExtended, setIsExtended] = useState(isActive);

  return (
    <Accordion allowToggle>
      <AccordionItem border="none">
        <AccordionButton
          p="0"
          onClick={() => {
            setIsExtended(!isExtended);
          }}
        >
          <NavigationLink
            to={to}
            Icon={Icon}
            isActive={isActive}
            title={title}
            onClick={onClick}
            hasTitle={hasTitle}
          />
          {hasTitle && <AccordionIcon w="18px" h="18px" ml="6" />}
        </AccordionButton>
        {hasTitle && (
          <AccordionPanel pt="6">
            <VStack align="left" spacing="4" ml="9">
              {panelLinks.map(({ to, title, isExternal }: PanelLink) => (
                <Panel to={to} title={title} isExternal={isExternal} key={title} />
              ))}
            </VStack>
          </AccordionPanel>
        )}
      </AccordionItem>
    </Accordion>
  );
};
