import React, { createContext, ReactElement, useMemo, useState } from 'react';
import { ModalContextProps, ModalContextValueProps, ModalVariant } from 'types/Modal';

const defaultModalValues: ModalContextValueProps = {
  variant: ModalVariant.DISLIKE,
  isShown: false,
};

export const ModalContext = createContext<ModalContextProps>({
  currentModalValues: {
    variant: ModalVariant.DISLIKE,
    isShown: false,
  },
  setCurrentModalValues: () => null,
  defaultModalValues,
});

type Props = {
  children: ReactElement;
};

export const ModalContextProvider = ({ children }: Props): ReactElement => {
  const [currentModalValues, setCurrentModalValues] =
    useState<ModalContextValueProps>(defaultModalValues);

  const contextValue = useMemo(
    () => ({
      currentModalValues,
      setCurrentModalValues,
      defaultModalValues,
    }),
    [currentModalValues, setCurrentModalValues]
  );

  return <ModalContext.Provider value={contextValue}> {children} </ModalContext.Provider>;
};
