import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

type Props = IconProps & {
  circleBorderColor?: string;
  backgroundColor?: string;
  tickColor?: string;
};

export const TickCircleIcon = ({
  circleBorderColor = 'currentColor',
  backgroundColor = 'none',
  tickColor = 'currentColor',
  ...props
}: Props): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <circle
        cx="12"
        cy="12"
        r="9.1875"
        stroke={circleBorderColor}
        strokeWidth="1.125"
        fill={backgroundColor}
      />
      <g clipPath="url(#clip0_7305_94697)">
        <path
          d="M16.315 8.25L10.6342 13.9308L8.36743 11.6252L7.5 12.4927L9.11123 14.1039L10.7018 15.6945L12.2821 14.1142L17.25 9.14627L16.315 8.25Z"
          fill={tickColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_7305_94697">
          <rect width="9.75" height="9.75" fill="white" transform="translate(7.5 6.75)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
