import { ReactElement, useState } from 'react';
import { Box, VStack, Text } from '@chakra-ui/react';
import { CONTENTFUL_ENVIRONMENT } from 'config/env';
import { Z_INDEX } from 'styles/zIndex';

export const EnvironmentTag = (): ReactElement | null => {
  const [shouldShow, setShouldShow] = useState<boolean>(true);
  const environment = process.env.NODE_ENV;
  const isProd = environment === 'production';

  // TODO: Add build/Version number
  const build = 'xxx';

  if (!shouldShow || isProd) return null;
  return (
    <Box
      position="fixed"
      bottom="20px"
      left="20px"
      borderRadius="5px"
      background="#21130d80"
      p="10px 20px"
      zIndex={Z_INDEX.DEV_TAG}
      onClick={() => setShouldShow(false)}
      cursor="pointer"
    >
      <VStack
        color="white"
        textAlign="left"
        justifyContent="flex-start"
        alignItems="flex-start"
        fontSize="14px"
        spacing="0px"
      >
        <Text>FE Node ENV: {environment}</Text>
        <Text>FE CTF ENV: {CONTENTFUL_ENVIRONMENT}</Text>
        <Text>Version: {build}</Text>

        <Text fontSize="10px">(click to hide)</Text>
      </VStack>
    </Box>
  );
};
