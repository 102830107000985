import Axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import storage from 'utils/storage';
import errorSubject from 'components/providers/Error/errorSubject';
import { API_URL } from 'config/env';
import { refreshUserToken } from './auth';

// TODO: add proper types

function authRequestInterceptor(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
  const token = storage.getToken();

  if (token && config.headers) {
    config.headers.authorization = `${token}`;
    config.headers.Accept = 'application/json';
  }
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

function onResponse(response: AxiosResponse): AxiosResponse {
  return response;
}

export function caughtErrorGlobally(error: AxiosError): boolean {
  return error.status === 500 || error.status === 408;
}

async function onResponseError(error: AxiosError): Promise<never> {
  if (caughtErrorGlobally(error)) {
    errorSubject.notify(error);
  }

  // Prevent infinite loop by intervening only when any requests BESIDES refresh is unauthorized.
  // this line: const tokenRefreshed = await refreshUserToken(); would cause infinite loop otherwise, as it will call refresh route to check wether it has expired tokens
  // ?? we should think about a fn that is a plain expired check on the tokens.
  const shouldTryRefetch =
    error.response?.status === 401 && !error.config?.url?.includes('/auth/refresh');

  if (error.response && shouldTryRefetch) {
    const tokenRefreshed = await refreshUserToken();
    if (tokenRefreshed && error.config) {
      return axios.request(error.config);
    }
  }

  return Promise.reject(error);
}

axios.interceptors.response.use(onResponse, onResponseError);
axios.interceptors.request.use(authRequestInterceptor);
