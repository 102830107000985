import { EmbeddedEntry } from '@novaheal/types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CONTENTFUL_ACCESS_TOKEN, CONTENTFUL_ENVIRONMENT, CONTENTFUL_SPACE_ID } from 'config/env';
import { createClient } from 'contentful';
import { parseEmbeddedEntry } from '@novaheal/parsers';

const getEntry = async (id: string): Promise<EmbeddedEntry | null> => {
  const ctfClient = createClient({
    space: CONTENTFUL_SPACE_ID,
    environment: CONTENTFUL_ENVIRONMENT,
    accessToken: CONTENTFUL_ACCESS_TOKEN,
  });
  const entry = await ctfClient.getEntry(id);
  try {
    const parsedEntry = parseEmbeddedEntry(entry);
    return parsedEntry;
  } catch (e) {
    console.log('error', e);
    return null;
  }
};

export const useEntry = (id: string): UseQueryResult<EmbeddedEntry, unknown> => {
  return useQuery({
    queryKey: [`keyword-${id}`],
    queryFn: () => getEntry(id),
    enabled: !!id,
  });
};
