// This creates a client. It will be used to fetch data from algolia.
// TODO: only create one client for the whole app

import algoliasearch from 'algoliasearch/lite';
import { ALGOLIA_APPLICATION_ID, ALGOLIA_APPLICATION_SEARCH_API_KEY } from 'config/env';

export const searchClient = algoliasearch(
  ALGOLIA_APPLICATION_ID,
  ALGOLIA_APPLICATION_SEARCH_API_KEY
);
