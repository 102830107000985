import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const EmulsionIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 25 25" {...props}>
      <g clipPath="url(#clip0_10416_122145)">
        <path
          d="M10.1189 6.94232L15.472 9.74068C15.8634 9.94525 16.1533 10.3019 16.2736 10.7267L16.3677 11.0592C16.4722 11.4282 16.4413 11.8225 16.2804 12.1707L16.0878 12.5877L10.4222 23.4256C10.1126 24.0181 9.38125 24.2473 8.78882 23.9376L2.10884 20.4456C1.51642 20.1359 1.28722 19.4046 1.59691 18.8122L7.28441 7.93226C7.44792 7.61948 7.70987 7.36925 8.0298 7.22021L8.69618 6.90978C9.14963 6.69855 9.6756 6.71058 10.1189 6.94232Z"
          stroke="currentColor"
          strokeWidth="0.786736"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.78 14.321L6.58594 10.0376L3.38113 16.1682L11.5751 20.4517L14.78 14.321ZM9.83038 13.2538L10.3014 13.5C10.5223 13.6155 10.6077 13.8881 10.4923 14.109L10.1542 14.7556L10.8009 15.0937C11.0218 15.2092 11.1072 15.4818 10.9917 15.7027L10.7455 16.1737C10.6301 16.3946 10.3574 16.48 10.1365 16.3646L9.48987 16.0265L9.15181 16.6732C9.03635 16.8941 8.7637 16.9795 8.54283 16.8641L8.07181 16.6178C7.85094 16.5024 7.76548 16.2297 7.88094 16.0088L8.219 15.3622L7.57232 15.0241C7.35145 14.9087 7.266 14.636 7.38146 14.4151L7.62769 13.9441C7.74315 13.7232 8.0158 13.6378 8.23667 13.7532L8.88335 14.0913L9.2214 13.4446C9.33686 13.2238 9.60951 13.1383 9.83038 13.2538Z"
          fill="currentColor"
        />
        <rect
          x="9.96875"
          y="5.53418"
          width="7.15968"
          height="1.14387"
          rx="0.449564"
          transform="rotate(27.5985 9.96875 5.53418)"
          fill="currentColor"
        />
        <path
          d="M13.003 3.52186L11.3526 6.67895L14.446 8.29601L15.4188 6.43505C15.594 6.10001 15.9778 5.93097 16.3432 6.02799L16.5618 6.08602C16.8894 6.173 17.2336 6.02145 17.3906 5.72106C17.5761 5.36611 17.4388 4.92795 17.0839 4.7424L15.4014 3.86291L14.0946 3.17975C13.6987 2.97279 13.21 3.12596 13.003 3.52186Z"
          stroke="currentColor"
          fill="none"
          strokeWidth="0.7"
        />
        <ellipse
          cx="17.1492"
          cy="5.60327"
          rx="0.470895"
          ry="0.477543"
          transform="rotate(27.5985 17.1492 5.60327)"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2772 8.29419L21.6535 11.0405C21.6795 11.0867 21.7033 11.1342 21.7249 11.183L21.7279 11.1898C21.8156 11.391 21.8643 11.6129 21.8643 11.8461C21.8643 12.7584 21.1199 13.498 20.2017 13.498C19.2834 13.498 18.5391 12.7584 18.5391 11.8461C18.5391 11.6143 18.5867 11.3936 18.6735 11.1933L18.6757 11.1892C18.7169 11.0949 18.79 10.9293 18.8474 10.8451L20.2772 8.29419ZM19.7204 11.5941C19.6737 11.4636 19.6126 11.293 19.6503 11.1396C19.6947 10.9587 19.8136 10.8656 19.9086 10.7912C20.0013 10.7186 20.0713 10.6637 20.0277 10.5624C19.7397 10.4241 19.4354 10.7016 19.296 10.9918C19.1567 11.2821 19.2772 11.6294 19.5651 11.7677C19.8193 11.8701 19.7825 11.7675 19.7204 11.5941Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10416_122145">
          <rect width="24" height="24" fill="white" transform="translate(0.558594 0.931152)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
