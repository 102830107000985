import { PaymentInterval } from '@novaheal/types';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { axios } from 'utils/axios';

const createSubscription = async (
  variant: PaymentInterval,
  promoCodeId?: string | null
): Promise<string> => {
  const data: AxiosResponse = await axios.post('/subscription', {
    productId: variant.toUpperCase(),
    promoCodeId,
  });
  return data.data.url;
};

export const useCreateSubscription = (
  variant: PaymentInterval
): UseMutationResult<string, unknown, string | undefined | null, unknown> => {
  return useMutation({
    mutationKey: ['create-subscription'],
    mutationFn: (promoCodeId?: string | null) => createSubscription(variant, promoCodeId),
  });
};
