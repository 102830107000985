import { ReactElement } from 'react';
import { VStack, Text, Box } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

import { ArticleSliderVariants } from 'types/ArticleSliderVariants';

import { Breakpoints } from 'types/breakpoints';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { LastViewedEntry } from '@novaheal/types';
import { ArticleSlide } from './ArticleSlide';
import { Slider } from '../../atoms/Slider';

/**
 * It takes an array of entries and returns an array of React elements
 * @param {LastViewedEntry[]} entries - LastViewedEntry[] - an array of LastViewedEntry objects
 * @param {number} itemsPerSlide - number - The number of items to display per slide.
 */
const formatArticleSlides = (entries: LastViewedEntry[], itemsPerSlide: number): ReactElement[] => {
  // Creates an array with recommendation triplets
  const slides = entries.reduce((acc: LastViewedEntry[][], _val, i, arr) => {
    const shouldCreateNewSlide: boolean = i % itemsPerSlide === 0;
    const lastSlideIndex = i + itemsPerSlide;

    return !shouldCreateNewSlide ? [...acc] : [...acc, arr.slice(i, lastSlideIndex)];
  }, [] as LastViewedEntry[][]);

  const slidesWithId = slides.map((slide) => ({
    id: uuidv4(),
    content: slide,
  }));

  const slideComponents: ReactElement[] = slidesWithId.map((slide) => (
    <ArticleSlide items={slide.content} key={slide.id} />
  ));

  return slideComponents;
};

type ArticleSliderProps = {
  entries: LastViewedEntry[];
  variant: ArticleSliderVariants;
};

export const ArticleSlider = ({ entries, variant }: ArticleSliderProps): ReactElement => {
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);

  const sectionTitle =
    variant === ArticleSliderVariants.HISTORY ? 'Zuletzt geöffnet' : 'Passend dazu';
  const sectionDescription =
    variant === ArticleSliderVariants.HISTORY
      ? 'Deine zuletzt angesehenen Lernkarten'
      : 'Ähnliche Themen zum Weiterlernen';

  const itemsPerSlide = 3;

  const slidesWithId = formatArticleSlides(entries, itemsPerSlide);

  const showPagination = slidesWithId.length > 1 && !isDesktop;

  return (
    <VStack
      padding={{ base: '15px 0', md: '30px 0' }}
      align="left"
      spacing="10"
      bg="bg.light"
      width="full"
      borderRadius={isDesktop ? '15px' : '0'}
    >
      <VStack align="left" spacing="0">
        <Box pl="20px">
          <Text as="h2" textStyle="h4.med" color="grey.secondary" mb="15px">
            {sectionTitle}
          </Text>
          <Text textStyle="body.14.reg" color="grey.40" pb="15px">
            {sectionDescription}
          </Text>
        </Box>
        <Slider
          slides={slidesWithId}
          showPagination={showPagination}
          bgColor="bg.light"
          showArrows={isDesktop}
        />
      </VStack>
    </VStack>
  );
};
