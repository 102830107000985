import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const PillIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M18.0907 21.1974H17.2416C16.3292 21.1967 15.4544 20.834 14.8093 20.1889C14.1642 19.5437 13.8015 18.669 13.8008 17.7566V7.14231C13.8016 6.23018 14.1643 5.35565 14.8093 4.71067C15.4543 4.0657 16.3288 3.70298 17.2409 3.70215H18.0907C19.0026 3.70332 19.8768 4.06618 20.5215 4.71112C21.1663 5.35606 21.5288 6.2304 21.5296 7.14231V17.7566C21.529 18.6686 21.1665 19.5431 20.5218 20.1882C19.8771 20.8333 19.0027 21.1962 18.0907 21.1974ZM17.2409 4.09639C16.4335 4.09723 15.6594 4.41834 15.0885 4.98926C14.5176 5.56018 14.1965 6.33428 14.1957 7.14168V17.7566C14.1965 18.564 14.5176 19.3381 15.0885 19.909C15.6594 20.4799 16.4335 20.8011 17.2409 20.8019H18.0907C18.8981 20.8011 19.6722 20.4799 20.2431 19.909C20.8141 19.3381 21.1352 18.564 21.136 17.7566V7.14231C21.1353 6.3348 20.8143 5.56054 20.2434 4.98949C19.6724 4.41843 18.8982 4.09723 18.0907 4.09639H17.2409Z"
        fill="currentColor"
      />
      <path
        d="M18.0892 21.2466H17.2401C16.3147 21.246 15.4273 20.8781 14.773 20.2237C14.1186 19.5693 13.7507 18.682 13.75 17.7566V7.14228C13.7508 6.21708 14.1187 5.33001 14.773 4.67579C15.4272 4.02157 16.3142 3.65367 17.2394 3.65283H18.0892C19.0142 3.654 19.9009 4.02206 20.5549 4.67624C21.2088 5.33042 21.5766 6.2173 21.5774 7.14228V17.7566C21.5767 18.6817 21.2091 19.5687 20.5551 20.223C19.9012 20.8773 19.0143 21.2455 18.0892 21.2466ZM17.2394 3.75076C16.3403 3.75177 15.4783 4.10943 14.8426 4.74526C14.2069 5.38109 13.8494 6.24316 13.8486 7.14228V17.7566C13.8496 18.6556 14.2071 19.5175 14.8428 20.1532C15.4785 20.7889 16.3404 21.1465 17.2394 21.1475H18.0892C18.9881 21.1463 19.8497 20.7886 20.4852 20.1529C21.1207 19.5172 21.478 18.6554 21.4788 17.7566V7.14228C21.4782 6.24333 21.1209 5.38136 20.4854 4.74552C19.8499 4.10969 18.9882 3.75193 18.0892 3.75076H17.2394ZM18.0892 20.8524H17.2401C16.419 20.8524 15.6316 20.5262 15.051 19.9457C14.4704 19.3651 14.1442 18.5776 14.1442 17.7566V7.14228C14.1442 6.32121 14.4704 5.53378 15.051 4.95319C15.6316 4.37261 16.419 4.04645 17.2401 4.04645H18.0892C18.9103 4.04645 19.6977 4.37261 20.2783 4.95319C20.8589 5.53378 21.1851 6.32121 21.1851 7.14228V17.7566C21.1851 18.5776 20.8589 19.3651 20.2783 19.9457C19.6977 20.5262 18.9103 20.8524 18.0892 20.8524ZM17.2394 4.14564C16.445 4.14647 15.6833 4.46248 15.1216 5.0243C14.56 5.58611 14.2441 6.34784 14.2434 7.14228V17.7566C14.2443 18.5509 14.5602 19.3125 15.1219 19.8741C15.6836 20.4358 16.4451 20.7517 17.2394 20.7526H18.0892C18.8834 20.7514 19.6447 20.4354 20.2062 19.8737C20.7677 19.3121 21.0836 18.5507 21.0846 17.7566V7.14228C21.0838 6.348 20.768 5.58647 20.2064 5.0247C19.6449 4.46294 18.8835 4.14681 18.0892 4.14564H17.2394Z"
        fill="currentColor"
      />
      <path
        d="M14 12.4495V17.7566C14 18.6167 14.3417 19.4416 14.9499 20.0498C15.5581 20.658 16.3829 20.9997 17.243 20.9997H18.0928C18.9527 20.9993 19.7773 20.6575 20.3852 20.0493C20.9931 19.4412 21.3346 18.6165 21.3346 17.7566V12.4495H14Z"
        fill="currentColor"
      />
      <path
        d="M9.81933 18.7004C10.8203 18.2575 11.624 17.4627 12.0781 16.4667C12.5323 15.4708 12.6053 14.3428 12.2834 13.2966L4.16406 16.8897C4.72177 17.8313 5.60545 18.5357 6.64771 18.8694C7.68997 19.2031 8.81843 19.143 9.81933 18.7004Z"
        fill="currentColor"
      />
      <path
        d="M6.20085 10.5224C5.19988 10.9654 4.39618 11.7601 3.94204 12.7561C3.4879 13.752 3.41489 14.88 3.73682 15.9262L11.8561 12.3331C11.2983 11.3916 10.4146 10.6873 9.37241 10.3536C8.33019 10.0199 7.20178 10.0799 6.20085 10.5224Z"
        fill="currentColor"
      />
    </Icon>
  );
};
