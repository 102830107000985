import { useState } from 'react';

const initialValue = true;
const key = 'SIDEBAR_EXTENDED';

export const useIsDesktopNavOpen = (): {
  isOpen: boolean;
  toggle: () => void;
} => {
  const [isOpen, setIsOpen] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const toggle = (): void => {
    setIsOpen(!isOpen);
    window.localStorage.setItem(key, JSON.stringify(!isOpen));
  };

  return { isOpen, toggle };
};
