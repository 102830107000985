import React, { ReactElement } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { SearchBar } from 'components/Search/SearchBar';
import { useTopBarContext } from 'hooks/utils/useTopBarContext';
import { useNavigate } from 'react-router-dom';
import { Z_INDEX } from 'styles/zIndex';
import { InAppLayoutVariant } from 'types/Layout';
import { TopBarPrimary } from './TopBarPrimary';
import { TopBarSecondary } from './TopBarSecondary';
import { TopBarTertiary } from './TopBarTertiary';

type Props = {
  isHidden?: boolean;
};

export const TopBar = ({ isHidden = false }: Props): ReactElement => {
  const navigate = useNavigate();
  const { currentTopBarValues } = useTopBarContext();
  const {
    variant = InAppLayoutVariant.PRIMARY,
    customOnBackClick,
    title,

    // Primary only
    hasBackButton,
    topBarVariant,
    hasSearch,

    // Secondary only
    hasShareButton,
    hasLikeButton,
    handleLikeChange,
    isLiked,
    shareData,
  } = currentTopBarValues;

  const handleBackClick = (): void => (customOnBackClick ? customOnBackClick() : navigate(-1));

  const isPrimary = variant === InAppLayoutVariant.PRIMARY;
  const isSecondary = variant === InAppLayoutVariant.SECONDARY;

  if (isPrimary) {
    return (
      <VStack position="sticky" top="0" zIndex={Z_INDEX.NAVBAR} gap={0}>
        {!isHidden && (
          <TopBarPrimary
            onBackClick={handleBackClick}
            hasBackButton={hasBackButton}
            title={title}
            topBarVariant={topBarVariant}
          />
        )}
        {hasSearch && <SearchBar />}
      </VStack>
    );
  }
  if (isSecondary) {
    return (
      <Box position="sticky" top="0" zIndex={Z_INDEX.NAVBAR} height="0px">
        {!isHidden && (
          <TopBarSecondary
            hasShareButton={hasShareButton}
            hasLikeButton={hasLikeButton}
            handleLikeChange={handleLikeChange} // TODO: Check if we need to build the function later via article.title
            shareData={shareData}
            isLiked={isLiked}
            onBackClick={handleBackClick}
            title={title}
          />
        )}
      </Box>
    );
  }
  return (
    <Box position="sticky" top="0" zIndex={Z_INDEX.NAVBAR}>
      {!isHidden && <TopBarTertiary onBackClick={handleBackClick} />}
    </Box>
  );
};
