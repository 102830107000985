import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const ArrowIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M6.59375 12C6.59375 12.3659 6.73149 12.6795 7.02815 12.9513L15.2712 20.9168C15.5043 21.1468 15.8009 21.2722 16.1505 21.2722C16.8499 21.2722 17.4113 20.7286 17.4113 20.0283C17.4113 19.6832 17.2631 19.3801 17.03 19.1397L9.60277 12L17.03 4.86029C17.2631 4.61985 17.4113 4.30625 17.4113 3.97174C17.4113 3.27136 16.8499 2.72778 16.1505 2.72778C15.8009 2.72778 15.5043 2.85322 15.2712 3.0832L7.02815 11.0383C6.73149 11.3205 6.59375 11.6341 6.59375 12Z"
        fill="currentColor"
      />
    </Icon>
  );
};
