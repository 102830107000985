import { Icon, IconProps } from '@chakra-ui/react';

export const StarOutlineIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 19" {...props}>
      <path
        d="M10 0.618033L11.8819 6.40983C12.0157 6.82185 12.3997 7.10081 12.8329 7.10081H18.9228L13.996 10.6803C13.6455 10.935 13.4988 11.3864 13.6327 11.7984L15.5146 17.5902L10.5878 14.0106C10.2373 13.756 9.7627 13.756 9.41222 14.0106L4.48542 17.5902L6.36729 11.7984C6.50116 11.3864 6.3545 10.935 6.00402 10.6803L1.07722 7.10081L7.16708 7.10081C7.6003 7.10081 7.98426 6.82185 8.11813 6.40983L10 0.618033Z"
        stroke="#3EA37E"
        fill="transparent"
      />
    </Icon>
  );
};
