import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const ShareIOSIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5742 8.5C19.2311 8.5 20.5742 7.15685 20.5742 5.5C20.5742 3.84315 19.2311 2.5 17.5742 2.5C15.9174 2.5 14.5742 3.84315 14.5742 5.5C14.5742 5.75885 14.607 6.01003 14.6686 6.24964L8.86682 9.56496C8.31651 8.91363 7.49367 8.5 6.57422 8.5C4.91736 8.5 3.57422 9.84315 3.57422 11.5C3.57422 13.1569 4.91736 14.5 6.57422 14.5C7.39672 14.5 8.14191 14.169 8.68385 13.6329L14.3036 16.8733C14.2155 17.1559 14.168 17.4563 14.168 17.7679C14.168 19.4248 15.5111 20.7679 17.168 20.7679C18.8248 20.7679 20.168 19.4248 20.168 17.7679C20.168 16.111 18.8248 14.7679 17.168 14.7679C16.3023 14.7679 15.5222 15.1346 14.9746 15.7211L9.40092 12.5073C9.51312 12.1925 9.57422 11.8534 9.57422 11.5C9.57422 11.2412 9.54144 10.99 9.4798 10.7504L15.2816 7.43504C15.8319 8.08637 16.6548 8.5 17.5742 8.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};
