import { Button, Center, HStack, IconButton, InputProps, TypographyProps } from '@chakra-ui/react';
import { CloseIcon, EyeCrossedIcon, EyeIcon, LockIcon } from 'assets/icons/Icons';
import { InputField } from 'components/common/Form';
import React, { ReactElement, useMemo, useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

type Props = InputProps & {
  placeholder: string;
  label?: string;
  labelPosition?: TypographyProps['textAlign'];
  error: FieldError;
  value: string;
  labelMarginBottom?: string;
  register: () => UseFormRegisterReturn<string>;
  reset: () => void;
};

export const PasswordInputField = ({
  label,
  labelPosition = 'left',
  placeholder,
  error,
  value,
  register,
  reset,
  ...props
}: Props): ReactElement => {
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const InputIcons = useMemo((): ReactElement => {
    return (
      <HStack spacing="0">
        <IconButton
          tabIndex={-1}
          aria-label="hide/show"
          icon={shouldShowPassword ? <EyeCrossedIcon w="5" h="5" /> : <EyeIcon w="5" h="5" />}
          onClick={() => setShouldShowPassword(!shouldShowPassword)}
          variant="none"
          color="grey.40"
          _hover={{ bg: 'none' }}
          _active={{ bg: 'none' }}
        >
          {shouldShowPassword ? 'Hide' : 'Show'}
        </IconButton>
        <Button
          tabIndex={-1}
          width="0px"
          bg="none"
          _focus={{ outline: 'none' }}
          onClick={() => reset()}
          _hover={{ bg: 'none' }}
          _active={{ bg: 'none' }}
        >
          <Center bg="grey.40" rounded="full" boxSize="16px">
            <CloseIcon color="bg.light" mt="3px" w="10px" m={0} />
          </Center>
        </Button>
      </HStack>
    );
  }, [shouldShowPassword, setShouldShowPassword, reset]);

  return (
    <InputField
      {...props}
      type={shouldShowPassword ? 'text' : 'password'}
      label={label}
      labelPosition={labelPosition}
      placeholder={placeholder}
      error={error}
      registration={register()}
      leftElement={<LockIcon color={value ? 'green.primary' : 'grey.40'} />}
      rightElement={value && InputIcons}
      _focus={{ bg: 'bg.input', borderColor: 'green.primary' }}
    />
  );
};
