import * as React from 'react';

/**
 * It takes a function that returns a promise of a module, and a key of that module, and returns a lazy
 * component that resolves to the value of that key
 * Usage: const { Home } = lazyImport(() => import("./Home"), "Home");
 * See more details: named imports for React.lazy: https://github.com/facebook/react/issues/14603#issuecomment-726551598
 * @param factory - () => Promise<I>
 * @param {K} name - The name of the component you want to import.
 * @returns An object with a single property, whose name is the value of the name parameter, and whose
 * value is a React.lazy component.
 */
export function lazyImport<
  T extends React.ComponentType<any>,
  I extends { [K2 in K]: T },
  K extends keyof I,
>(factory: () => Promise<I>, name: K): I {
  return Object.create({
    [name]: React.lazy(() => factory().then((module) => ({ default: module[name] }))),
  });
}
