import { useQuery } from '@tanstack/react-query';
import { axios } from 'utils/axios';

const getSubscriptionPrices = async () => {
  const { data: priceData } = await axios.get('/subscription/prices');

  if (!priceData) {
    throw new Error('No prices found');
  }

  return priceData;
};

export const useSubscriptionPrices = () => {
  return useQuery({
    queryKey: ['prices'],
    queryFn: getSubscriptionPrices,
  });
};
