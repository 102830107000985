import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const SearchIconLarge = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 163 130" {...props}>
      <path
        d="M43.5466 44.8875C49.3229 44.8821 54.9711 46.6435 59.7765 49.949C64.5819 53.2545 68.3287 57.9555 70.5428 63.4574C72.757 68.9592 73.3391 75.0147 72.2154 80.8578C71.0917 86.7008 68.3128 92.069 64.2302 96.283C60.1475 100.497 54.9447 103.368 49.2797 104.532C43.6147 105.696 37.7422 105.101 32.405 102.823C27.0679 100.545 22.5059 96.685 19.2962 91.7325C16.0865 86.7799 14.3732 80.9569 14.3732 75C14.4082 67.0296 17.4923 59.3954 22.955 53.7568C28.4177 48.1183 35.8177 44.9309 43.5466 44.8875ZM43.5466 39.25C36.6901 39.25 29.9877 41.3467 24.2868 45.275C18.5859 49.2032 14.1426 54.7866 11.5187 61.3191C8.8949 67.8515 8.20839 75.0397 9.54601 81.9745C10.8836 88.9093 14.1853 95.2793 19.0335 100.279C23.8817 105.279 30.0588 108.684 36.7834 110.063C43.5081 111.442 50.4784 110.735 56.8129 108.029C63.1474 105.323 68.5616 100.741 72.3708 94.8616C76.1801 88.9826 78.2132 82.0707 78.2132 75C78.2132 65.5185 74.5609 56.4254 68.0596 49.7209C61.5583 43.0165 52.7407 39.25 43.5466 39.25Z"
        fill="#A1D8C3"
      />
      <path
        d="M93.3333 122.547L73.68 102.142L69.8933 106.02L89.5466 126.425C89.7935 126.681 90.087 126.885 90.4102 127.025C90.7335 127.164 91.0802 127.236 91.4305 127.238C91.7809 127.239 92.1281 127.169 92.4523 127.032C92.7765 126.895 93.0713 126.693 93.32 126.439C93.5686 126.184 93.7662 125.881 93.9014 125.548C94.0366 125.215 94.1069 124.857 94.1081 124.496C94.1094 124.135 94.0416 123.777 93.9086 123.442C93.7757 123.108 93.5802 122.804 93.3333 122.547Z"
        fill="#A1D8C3"
      />
      <ellipse opacity="0.3" cx="50.5" cy="68" rx="31.5" ry="31" fill="#43B188" />
      <ellipse opacity="0.3" cx="119.5" cy="10" rx="9.5" ry="10" fill="#91CBB6" />
      <ellipse opacity="0.3" cx="156.5" cy="55" rx="6.5" ry="6" fill="#91CBB6" />
    </Icon>
  );
};
