import { ReactElement, useEffect, useState } from 'react';
import { Button, Text, VStack, Box } from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';
import { css } from '@emotion/react';
import {
  FeedbackMessage,
  FeedbackOption,
  FeedbackType,
  ShortFeedbackMessage,
} from '@novaheal/types';
import { Slider } from 'components/atoms/Slider';
import { colors } from 'styles/theme/colors';
import { Z_INDEX } from 'styles/zIndex';
import { Form, TextField } from '../Form';

type FeedbackInputType = {
  message: string;
};

type Props = {
  onSubmit: ({ message, shortMessages }: FeedbackMessage) => Promise<void>;
  feedbackType: FeedbackType;
};

const options: FeedbackOption[] = [
  {
    value: ShortFeedbackMessage.SUPER,
    label: '👍 Super',
  },
  {
    value: ShortFeedbackMessage.DUMB,
    label: '👎 Blöd',
  },
  {
    value: ShortFeedbackMessage.HELPFUL,
    label: '🤩 Hilfreich',
  },
  {
    value: ShortFeedbackMessage.NOT_ENOUGH,
    label: '❓ Zu oberflächlich',
  },
  {
    value: ShortFeedbackMessage.BORING,
    label: '😴 zu langweilig',
  },
  {
    value: ShortFeedbackMessage.DONT_UNDERSTAND,
    label: '🙈 Unverständlich',
  },

  {
    value: ShortFeedbackMessage.NOT_PRACTICAL_ENOUGH,
    label: '🩹 Zu wenig praktisch',
  },
  {
    value: ShortFeedbackMessage.NOT_RELEVANT_ANYMORE,
    label: '🕰️ Veraltet',
  },
  {
    value: ShortFeedbackMessage.PLEASE_ADD_VIDEO,
    label: '🎥 Erklärvideo gewünscht',
  },
];

export const FeedbackInput = ({ onSubmit, feedbackType }: Props): ReactElement => {
  const [selectedOptions, setSelectedOptions] = useState<FeedbackOption[]>([]);

  useEffect(() => {
    if (feedbackType === FeedbackType.POSITIVE) {
      setSelectedOptions([options[0]]);
    } else {
      setSelectedOptions([options[1]]);
    }
  }, [feedbackType]);

  const handleOptionClick = (label: string, value: ShortFeedbackMessage): void => {
    setSelectedOptions([...selectedOptions, { label, value }]);
  };

  const handleRemoveOptionClick = (value: ShortFeedbackMessage): void => {
    const tmp = selectedOptions;

    const filtered = tmp.filter((option) => option.value !== value);
    setSelectedOptions(filtered);
  };

  const handleSubmit = async (values: { message: string }): Promise<void> => {
    await onSubmit({
      message: values.message,
      shortMessages: selectedOptions.map((option) => option.value),
    });
  };

  const filteredOptions = options.filter((option) =>
    selectedOptions.every((selectedOption) => selectedOption.value !== option.value)
  );

  const emojiSlides = filteredOptions.map((option) => {
    return (
      <Box
        bg="green.click"
        borderRadius="5px"
        textStyle="body.14.reg"
        px="4"
        py="2"
        display="inline-block"
        mr="4"
        cursor="pointer"
        onClick={() => handleOptionClick(option.label, option.value)}
      >
        {option.label}
      </Box>
    );
  });

  const selectedEmojiSlides = selectedOptions.map((option) => (
    <Box
      bg="green.click"
      borderRadius="5px"
      textStyle="body.14.reg"
      display="inline-block"
      px="4"
      py="2"
      mr="4"
      cursor="pointer"
      onClick={() => handleRemoveOptionClick(option.value)}
    >
      {option.label}
    </Box>
  ));

  return (
    <Form<FeedbackInputType> onSubmit={handleSubmit} autoComplete="off">
      {({ register, formState }) => (
        <VStack spacing="14px">
          <Text textStyle="body.16.reg" textAlign="center" color="grey.tertiary">
            Deine Bewertung und dein Feedback helfen uns dabei, Lerninhalte aktuell und leicht
            verständlich zu gestalten!
          </Text>
          <Box h="35px">
            <Box
              whiteSpace="nowrap"
              overflow="auto"
              position="absolute"
              left={{ base: '5', md: '8' }}
              w={{
                base: 'calc(100% - var(--chakra-space-5))',
                md: 'calc(100% - var(--chakra-space-8))',
              }}
              css={css`
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                  display: none;
                }
              `}
            >
              <Box
                w={20}
                h="full"
                pointerEvents="none"
                bg={`linear-gradient(to right, rgba(255,255,255,0), ${colors.bg.light} 50%)`}
                position="absolute"
                zIndex={Z_INDEX.BASE}
                right={0}
              />
              <Slider slides={emojiSlides} containerPadding="0px" />
            </Box>
          </Box>
          <Box w="100%">
            <TextField
              error={formState.errors.message as FieldError}
              registration={register('message')}
              placeholder="Schreib uns dein Feedback hier…"
            />
            <Box
              w="100%"
              h="55px"
              border="1px solid"
              borderColor="grey.20"
              mt="0"
              borderBottomRadius="4px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px="2"
            >
              <Box
                whiteSpace="nowrap"
                overflow="auto"
                position="absolute"
                left={{ base: '6', md: '9' }}
                w={{
                  base: 'calc(100% - var(--chakra-space-5))',
                  md: 'calc(100% - var(--chakra-space-8) - var(--chakra-space-9) - 1px)',
                }}
                css={css`
                  -ms-overflow-style: none;
                  scrollbar-width: none;
                  &::-webkit-scrollbar {
                    display: none;
                  }
                `}
              >
                <Slider slides={selectedEmojiSlides} containerPadding="0px" />
              </Box>
            </Box>
          </Box>
          <Button
            type="submit"
            variant="primary"
            w="full"
            isDisabled={!formState.isValid || selectedOptions.length === 0}
          >
            Senden
          </Button>
        </VStack>
      )}
    </Form>
  );
};
