/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useMemo } from 'react';
import { VStack, Box, TabPanel } from '@chakra-ui/react';
import { FeedbackForm } from 'components/common/FeedbackForm/FeedbackForm';
import { ArticleContentSections } from 'components/Article/ArticleContentSections';
import { useIsLiked } from 'hooks/utils/useIsLiked';
import { TabBar } from 'components/Article/TabBar';
import { ArticleHero } from 'components/Article/ArticleHero';
import { TopInformation } from 'components/Article/TopInformation';
import { ArticleSlider } from 'components/common/ArticleSlider/ArticleSlider';
import { getReadingTime } from 'utils/getReadingTime';
import { MetaSection } from 'components/Article/MetaSection';
import { AppetizerSection } from 'components/Article/AppetizerSection';
import { ArticleQuestionSections } from 'components/Article/ArticleQuestionSections';
import { LiteratureSection } from 'components/Article/LiteratureSection';
import { ProtectedRoutes } from 'types/Routes';
import { useAddLastViewedEntry } from 'hooks/api/backend/user/viewedEntries/addLastViewedEntry';
import { ArticleSliderVariants } from 'types/ArticleSliderVariants';
import { PageContext } from 'components/common/PageContext';
import {
  ContentType,
  LastViewedEntry,
  RawContentfulData,
  SubscriptionStatus,
} from '@novaheal/types';
import { InAppLayoutVariant } from 'types/Layout';
import { TabData } from 'types/tab';
import { AcademicHatIcon, QuestionCircleIcon } from 'assets/icons/Icons';
import { modifyLastViewedEntry } from 'utils/modifyLastViewedEntry';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { Breakpoints } from 'types/breakpoints';
import { ArticleHeroDesktop } from 'components/Article/ArticleHeroDesktop';
import { useBreadcrumbs } from 'hooks/utils/useBreadcrumbs';
import { useArticleApi } from 'hooks/api/backend/useArticleApi';
import { useModalService } from 'hooks/useModalService';
import { ModalTarget, ModalVariant } from 'types/Modal';
import { useUser } from 'utils/auth';
import { isSubscriptionStatus } from 'utils/isSubscriptionStatus';
import { PageSpinner } from 'components/atoms/PageSpinner';

export const Article = (): ReactElement | null => {
  const { data: user, isLoading: isUserLoading } = useUser();
  const { data: article, isError, isLoading } = useArticleApi();
  const isArticleAvailable = !!article;
  const { isLiked, handleLikeChange } = useIsLiked(
    isArticleAvailable ? article.id : '',
    ContentType.ARTICLE
  );
  const addLastViewedEntry = useAddLastViewedEntry();
  const contentSectionId = useMemo(() => window.location.hash?.slice(1), [window.location.hash]);

  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const { addBreadcrumbs, removeBreadcrumbs } = useBreadcrumbs();
  const { show: showModal, activeModal } = useModalService();

  // constants
  const modifiedRecommendations = article?.recommendations?.map(
    (entry: RawContentfulData) => modifyLastViewedEntry(entry) as LastViewedEntry
  );
  const shouldShowRecommendations =
    !!article?.recommendations && article?.recommendations?.length > 3 && !!modifiedRecommendations;

  // lifecycle hooks
  // add a view to the db
  useEffect(() => {
    if (article) {
      addBreadcrumbs([
        {
          path: `${ProtectedRoutes.CATEGORY}/${article.category?.id}`,
          name: article.category.title,
        },
        { path: `${ProtectedRoutes.FOLDER}/${article.folder?.id}`, name: article.folder?.name },
        { path: `${ProtectedRoutes.ARTICLE}/${article.id}`, name: article.title },
      ]);
    }
    return () => {
      removeBreadcrumbs();
    };
  }, [article]);

  useEffect(() => {
    const updateView = async (): Promise<void> => {
      if (article) {
        await addLastViewedEntry.mutateAsync(article.id);
      }
    };

    updateView();
  }, [article]);

  useEffect(() => {
    const shouldShowPaywall =
      isSubscriptionStatus(user, SubscriptionStatus.FREE) && activeModal !== ModalVariant.SUBSCRIBE;
    if (shouldShowPaywall) {
      showModal(ModalVariant.PAYWALL, {
        target: isDesktop ? ModalTarget.PAGE_DESKTOP : ModalTarget.PAGE_MOBILE,
        onUpgradeClick: () => showModal(ModalVariant.SUBSCRIBE),
      });
    }
  }, [user, showModal, isDesktop, activeModal]);

  useEffect(() => {
    if (isLoading || isUserLoading) return;
    if (isSubscriptionStatus(user, SubscriptionStatus.FREE)) return;

    // TODO: move to external fn
    const scrollToAnchorLink = (): void => {
      if (contentSectionId) {
        const element = document.getElementById(contentSectionId);
        if (element) {
          const headerOffset = isDesktop ? 120 : 100;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.scrollY - headerOffset;
          window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
        }
      }
    };
    if (!isLoading) setTimeout(scrollToAnchorLink, 200);
  }, [contentSectionId, isDesktop, isLoading, isUserLoading]);

  // TODO: show proper error page
  if (isError) {
    return <span>Error</span>;
  }

  //  TODO: remove the navigation bar on load as soon as we have the new layout
  if (!article) {
    return <PageSpinner />;
  }

  const readingTime = getReadingTime(article.contentSections);

  const shareData: ShareData = {
    title: article.title || 'Novaheal App',
    text: `https://app.novaheal.de${ProtectedRoutes.ARTICLE}/${article.id}?utm_source=share&utm_medium=share&utm_campaign=share_article\nLernkarte zum Thema: ${article.title}`,
    url: `${window.location.href}?utm_source=share&utm_medium=share&utm_campaign=share_article`,
  };

  const tabs: TabData[] = [
    {
      icon: <AcademicHatIcon />,
      tabName: 'Lernen',
    },
    {
      icon: <QuestionCircleIcon transform="translateY(1.5px)" />,
      tabName: 'Fragen',
    },
  ];

  return (
    <PageContext
      variant={InAppLayoutVariant.SECONDARY}
      handleLikeChange={() => handleLikeChange(article.title)}
      shareData={shareData}
      hasShareButton
      hasLikeButton
      isLiked={isLiked}
      headTitle={article.title}
      title={article.title}
    >
      <Box width="100%" my={{ md: '40px' }}>
        {isDesktop ? (
          <ArticleHeroDesktop
            coverImageUrl={article.coverImageUrl}
            title={article.title}
            readingTime={readingTime}
            ageGroups={article.ageGroups}
            relevantYears={article.relevantYears}
            tags={article.tags}
            handleLikeChange={() => handleLikeChange(article.title)}
            isLiked={isLiked}
          />
        ) : (
          <Box w="100%" h="30vh" pt="env(safe-area-inset-top)" mb="-30px">
            <ArticleHero coverImageUrl={article.coverImageUrl} />
          </Box>
        )}
        <VStack
          w="100%"
          bg="bg.light"
          align="left"
          mt={{ base: '-30px', md: '0' }}
          pt={{ base: '50px', md: '0' }}
          spacing="0"
          borderTopRadius="30px"
          boxShadow={{ base: '0px 0px 15px rgba(0, 0, 0, 0.05)', md: 'none' }}
          position="relative"
        >
          {!isDesktop && (
            <TopInformation
              isLoading={isLoading}
              parentFolder={article.folder}
              category={article.category}
              title={article.title}
              readingTime={readingTime}
              relevantYears={article.relevantYears}
              tags={article.tags}
              ageGroups={article.ageGroups}
            />
          )}
          <Box bg="bg.light" pb="50px" width="100%">
            <TabBar position="sticky" top={{ base: '50px', md: '70px' }} tabs={tabs}>
              <TabPanel p="20px 0 0">
                <Box bg="bg.light">
                  {article.meta && <MetaSection metaData={article.meta} />}
                  <AppetizerSection appetizerData={article.appetizer} />
                  <ArticleContentSections
                    contentSections={article.contentSections}
                    contentSectionId={contentSectionId}
                  />
                  <LiteratureSection literatureData={article.literature} />
                </Box>
              </TabPanel>
              <TabPanel p="0" pt="20px">
                {article.questionSections && (
                  <ArticleQuestionSections questionSections={article.questionSections} />
                )}
              </TabPanel>
            </TabBar>

            <Box m="40px" bg="bg.light">
              <FeedbackForm type={ContentType.ARTICLE} id={article.id} title={article.title} />
            </Box>

            {shouldShowRecommendations && (
              <ArticleSlider
                entries={modifiedRecommendations}
                variant={ArticleSliderVariants.RECOMMENDATION}
              />
            )}
          </Box>
        </VStack>
      </Box>
    </PageContext>
  );
};
