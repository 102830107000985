import { ReactElement } from 'react';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { Breakpoints } from 'types/breakpoints';
import { ChatbotWidget } from 'components/NovahealAI/ChatbotWidget';
import { useUser } from 'utils/auth';
import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';
import { KeeunitBanner } from '../KeeunitBanner';

type Props = {
  children: ReactElement;
};

const SHOULD_SHOW_BANNER = false;

export const ResponsiveLayout = ({ children }: Props): ReactElement | null => {
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const { data: user, isFetched } = useUser();

  // This will prevent going into routes before the user data is fetched, allowing DRY code within the routes & their guards
  // use null instead of page spinner as it would just flash shortly
  if (!isFetched) {
    return null;
  }

  return (
    <>
      {SHOULD_SHOW_BANNER && <KeeunitBanner />}
      {user?.shouldShowAI ? <ChatbotWidget /> : null}
      {isDesktop ? (
        <DesktopLayout>{children}</DesktopLayout>
      ) : (
        <MobileLayout>{children}</MobileLayout>
      )}
    </>
  );
};
