import * as Sentry from '@sentry/react';

type Props = {
  setUser: (userId?: string) => void;
  clearUser: () => void;
};

export const useSentry = (): Props => {
  const setUser = (userId?: string): void => {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.setUser({ id: userId });
    }
  };

  const clearUser = (): void => {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.setUser(null);
    }
  };

  return { setUser, clearUser };
};
