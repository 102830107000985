import { Flex, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import clockImage from 'assets/subscription/benefit-clock.png';
import academicImage from 'assets/subscription/benefit-academic.png';
import listImage from 'assets/subscription/benefit-list.png';
import { SubscriptionBenefit } from './SubscriptionBenefit';

const BENEFIT_DATA = [
  {
    id: 0,
    imageUrl: clockImage,
    title: 'Fertige Zusammenfassungen',
    description: 'Spare Zeit und hab mehr Freizeit',
  },
  {
    id: 1,
    imageUrl: academicImage,
    title: 'Prüfungsrelevantes Wissen',
    description: 'Lerninhalte umfassend und auf den Punkt erklärt',
  },
  {
    id: 2,
    imageUrl: listImage,
    title: 'Detaillierte Anleitungen',
    description: 'Schritt für Schritt Erklärungen für jede Pflegemaßnahme',
  },
];

export const SubscriptionBenefits = (): ReactElement => {
  return (
    <Flex width="100%" flexDirection="column" gap="15px">
      <Text textStyle="h3">Was du bekommst</Text>
      {BENEFIT_DATA.map((benefit) => (
        <SubscriptionBenefit
          key={benefit.id}
          imageUrl={benefit.imageUrl}
          title={benefit.title}
          description={benefit.description}
        />
      ))}
    </Flex>
  );
};
