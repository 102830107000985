import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const GoogleColoredIcon = (props: IconProps): ReactElement => {
  return (
    <Icon width="19" height="20" fill="none" viewBox="0 0 19 20" {...props}>
      <path
        d="M0 9.5C0 4.52944 4.02944 0.5 9 0.5H10C14.9706 0.5 19 4.52944 19 9.5V10.5C19 15.4706 14.9706 19.5 10 19.5H9C4.02944 19.5 0 15.4706 0 10.5V9.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3398 10.1619C16.3398 9.65672 16.2944 9.17092 16.2102 8.70456H9.49976V11.4606H13.3343C13.1691 12.3513 12.6671 13.1059 11.9125 13.6111V15.3988H14.2152C15.5625 14.1584 16.3398 12.3318 16.3398 10.1619Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5007 17.125C11.4245 17.125 13.0373 16.487 14.2162 15.3988L11.9135 13.6111C11.2755 14.0386 10.4593 14.2912 9.5007 14.2912C7.64497 14.2912 6.07423 13.0379 5.51394 11.3538H3.13354V13.1998C4.30593 15.5284 6.71548 17.125 9.5007 17.125Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51299 11.3539C5.37049 10.9264 5.28953 10.4698 5.28953 10.0002C5.28953 9.53056 5.37049 9.07391 5.51299 8.64641V6.80038H3.1326C2.65004 7.76226 2.37476 8.85044 2.37476 10.0002C2.37476 11.1499 2.65004 12.2381 3.1326 13.1999L5.51299 11.3539Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5007 5.70884C10.5468 5.70884 11.486 6.06833 12.2244 6.77435L14.268 4.73077C13.0341 3.58105 11.4212 2.87503 9.5007 2.87503C6.71548 2.87503 4.30593 4.47168 3.13354 6.80026L5.51394 8.64628C6.07423 6.96219 7.64497 5.70884 9.5007 5.70884Z"
        fill="#EA4335"
      />
    </Icon>
  );
};
