import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ModalBody,
  Text,
  Image,
  ModalCloseButton,
  VStack,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import React, { ReactElement, useCallback, useRef } from 'react';
import { Breakpoints } from 'types/breakpoints';
import { ModalImageVariantProps, SharedModalProps } from 'types/Modal';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import QuickPinchZoom, { UpdateAction, make3dTransformValue } from 'react-quick-pinch-zoom';
import { CustomModal } from '../CustomModal';

type Props = SharedModalProps & ModalImageVariantProps;

export const ImageModal = ({
  handleClose,
  isShown,
  description,
  imgUrl,
  title,
}: Props): ReactElement => {
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const imgRef = useRef<HTMLImageElement>(null);

  const onPinchToZoom = useCallback(({ x, y, scale }: UpdateAction) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty('transform', value);
    }
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isDesktop ? (
        <CustomModal isOpen={isShown} onClose={handleClose} isCentered maxWidth="650px">
          <ModalBody
            p="30px 40px"
            css={css`
              -ms-overflow-style: none;
              scrollbar-width: none;
              &::-webkit-scrollbar {
                display: none;
              }
            `}
          >
            <VStack width="100%" spacing="15px">
              <Zoom>
                <Image src={imgUrl} />
              </Zoom>

              <Text>Abb. {title}</Text>
              <Text>{description}</Text>
            </VStack>
          </ModalBody>
        </CustomModal>
      ) : (
        <CustomModal
          isOpen={isShown}
          onClose={handleClose}
          isCentered
          maxWidth="100%"
          size="full"
          borderRadius="0"
          bg="black"
          hideHeader
        >
          <ModalCloseButton color="grey.textinvers" position="fixed" />
          <ModalBody p="0" display="flex" alignItems="center">
            <Box
              m="auto"
              position="fixed"
              width="100%"
              sx={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
              overflow="hidden"
            >
              <QuickPinchZoom onUpdate={onPinchToZoom}>
                <Image src={imgUrl} ref={imgRef} />
              </QuickPinchZoom>
            </Box>
            <Box
              position="fixed"
              width="full"
              bg="bg.fortext"
              px="4"
              py="4"
              sx={{ bottom: '0', left: '50%', transform: 'translate(-50%, 0%)' }}
            >
              <Accordion allowToggle color="grey.textinvers">
                <AccordionItem border="none">
                  <AccordionButton>
                    <Text align="left" py="2">
                      {title}
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <Text>{description}</Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </ModalBody>
        </CustomModal>
      )}
    </>
  );
};
