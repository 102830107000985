import { Box, ModalHeader, ModalHeaderProps, Text } from '@chakra-ui/react';
import { CloseIcon } from 'assets/icons/Icons';
import React, { ReactElement } from 'react';

type Props = ModalHeaderProps & {
  handleClose: () => void;
  title?: string;
  floatingCross?: boolean;
};

export const CustomModalHeader = ({
  title,
  handleClose,
  floatingCross = false,
  ...props
}: Props): ReactElement => {
  return (
    <ModalHeader {...props} p="0">
      <Box
        position="relative"
        p="25px 20px"
        sx={{
          '@supports (-webkit-touch-callout: none)': {
            marginTop: '40px',
          },
        }}
        borderBottom={title ? '1px solid' : undefined}
        borderBottomColor={title ? 'grey.10' : undefined}
        textAlign={{ base: 'start', md: 'center' }}
      >
        <Text as="h2" textStyle="h3">
          {title}
        </Text>

        {!floatingCross && (
          <CloseIcon
            w="20px"
            h="20px"
            onClick={handleClose}
            cursor="pointer"
            color="grey.secondary"
            position="absolute"
            right="20px"
            top="50%"
            transform="translateY(-50%)"
          />
        )}
      </Box>
    </ModalHeader>
  );
};
