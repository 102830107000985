import { useState } from 'react';

export const useLocalStorage = <T>(
  key: string,
  initialValue?: T
): {
  storedValue: T | undefined;
  setValue: (value: T) => void;
  deleteValue: () => void;
} => {
  const getValue = (): T | undefined => {
    if (typeof window === 'undefined') return initialValue;

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch {
      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState(getValue);

  const setValue = (value: T): void => {
    if (typeof window === 'undefined') return;

    window.localStorage.setItem(key, JSON.stringify(value));
    setStoredValue(value);
  };

  const deleteValue = (): void => {
    if (typeof window === 'undefined') return;

    window.localStorage.removeItem(key);
    setStoredValue(undefined);
  };

  return { storedValue, setValue, deleteValue };
};
