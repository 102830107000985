import { ReactElement, useEffect } from 'react';
import { VStack, Text, Button } from '@chakra-ui/react';
import { PageContext } from 'components/common/PageContext';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from 'types/Routes';
import { CheckIconLarge } from 'assets/common/CheckIconLarge';
import { RegisterStepProps } from 'pages/auth/Registration';
import { InAppLayoutVariant } from 'types/Layout';

export const RegisterStep3 = ({ values }: RegisterStepProps): ReactElement => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(PublicRoutes.LOGIN);
    }, 15000);
  });

  return (
    <PageContext
      title="Registrierung"
      customOnBackClick={() => null}
      variant={InAppLayoutVariant.TERTIARY}
    >
      <VStack spacing={6} pt="12" px="20px">
        <CheckIconLarge w="149px" h="96px" mb="10" />
        <Text textStyle="h1" align="center" color="blue.textHeader">
          Du hast Post von uns!
        </Text>
        <Text align="center" textStyle="h4" color="grey.50">
          Du wirst automatisch zum Login geleitet. Dort kannst du dich direkt anmelden und mit
          Novaheal lernen.
        </Text>
        <Text
          align="center"
          textStyle="h3"
          color="green.secondary"
          pb="10"
          maxWidth="100vw"
          paddingX="20px"
        >
          {values.email}
        </Text>
        <Button variant="primary" w="full" onClick={() => navigate(PublicRoutes.LOGIN)}>
          Zum Login
        </Button>
      </VStack>
    </PageContext>
  );
};
