import { useMediaQuery, useTheme } from '@chakra-ui/react';
import { Breakpoints } from 'types/breakpoints';

/**
 * It returns a boolean value that indicates whether the current viewport matches the given breakpoint
 * @param {Breakpoints} breakpoint - Breakpoints
 * @returns A boolean value
 */
export const useBreakpoint = (breakpoint: Breakpoints): boolean => {
  const theme = useTheme();

  if (!theme.breakpoints) {
    throw Error(
      'useTheme: `theme` is undefined. Seems you forgot to wrap your app in `<ChakraProvider />` or `<ThemeProvider />`'
    );
  }

  return useMediaQuery(`(min-width: ${theme.breakpoints[breakpoint]})`)[0];
};
