/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// !! see [here](https://www.contentful.com/blog/2021/04/14/rendering-linked-assets-entries-in-contentful/)
// !! for more info on how to render linked assets and entries in Contentful

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */

import { Text, ListItem, OrderedList, UnorderedList, Divider, HStack, Box } from '@chakra-ui/react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { css } from '@emotion/react';
import { CustomVideo, RichTextInlineTypes, RichTextLearnSection } from '@novaheal/types';
import { ArticleImage } from './ArticleImage';
import { ArticleVideo } from './ArticleVideo';
import { Keyword } from './Keyword';

const marks = {
  [MARKS.BOLD]: (text: any) => (
    <Text as="span" fontWeight="bold">
      {text}
    </Text>
  ),
  [MARKS.ITALIC]: (text: any) => <Text as="em">{text}</Text>,
  [MARKS.UNDERLINE]: (text: any) => (
    <Text as="mark" bgColor="bg.examKnowledge">
      {text}
    </Text>
  ),
  [MARKS.CODE]: (text: any) => (
    <Text as="span" color="grey.40">
      {text}
    </Text>
  ),
};

export function renderOptions(
  links: RichTextLearnSection | any, // any since it is used for different types of rich text mapping
  contentPos: 'main' | 'keyword' | 'appetizer' | 'list' = 'main'
): any {
  const assetMap = new Map();
  const entryMap = new Map();

  if (links) {
    // create an asset map
    for (const asset of links.assets.block) {
      assetMap.set(asset.sys.id, asset);
    }
    // loop through the block entries and add them to the entry map
    for (const entry of links.entries.block) {
      entryMap.set(entry.sys.id, entry);
    }

    // loop through the inline linked entries and add them to the entry map
    for (const entry of links.entries.inline) {
      entryMap.set(entry.sys.id, entry);
    }
  }

  /** 
   NODES___
      DOCUMENT
      PARAGRAPH
      HEADING_1
      HEADING_2
      HEADING_3
      HEADING_4
      HEADING_5
      HEADING_6
      UL_LIST
      OL_LIST
      LIST_ITEM
      QUOTE
      HR
      EMBEDDED_ENTRY
      EMBEDDED_ASSET 
   MARKS___ 
      BOLD
      ITALIC
      UNDERLINE
      CODE  
   */
  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: unknown, children: any) => {
        return (
          <Text
            textStyle={contentPos === 'main' ? ['body.16.reg', 'body.18.reg'] : 'body.14.reg'}
            minH="21px"
            mb={contentPos === 'appetizer' ? '0px' : '1.5em'}
            lineHeight="1.5"
          >
            {children}
          </Text>
        );
      },
      [BLOCKS.HEADING_1]: (node: any, children: any) => {
        return (
          <Box mb="0.75em">
            {node.content.map((childNode: any) => {
              if (childNode.nodeType === INLINES.ENTRY_HYPERLINK) {
                return (
                  <Keyword
                    key={childNode.data.target.sys.id}
                    title={childNode.content[0].value}
                    marks={childNode.content[0].marks.map((mark: { type: MARKS }) => mark.type)}
                    id={childNode.data.target.sys.id}
                    isHeading
                  />
                );
              }
              return (
                <Text as="h1" textStyle="h3" mb="0.75em" display="inline">
                  {childNode.value}
                </Text>
              );
            })}
          </Box>
        );
      },
      [BLOCKS.HEADING_2]: (node: any, children: any) => (
        <Text as="h2" textStyle="h4.med" mb="0.75em" mt="2em">
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_3]: (node: any, children: any) => (
        <Text as="h3" textStyle="h4.reg" mb="0.75em">
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_4]: (node: any, children: any) => (
        <Text as="h4" textStyle="body.16.med" mb="0.75em">
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_5]: (node: any, children: any) => (
        <Text as="h5" textStyle="body.16.med" mb="0.75em">
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_6]: (node: any, children: any) => (
        <Text as="h5" textStyle="body.16.med" mb="0.75em">
          {children}
        </Text>
      ),
      [BLOCKS.UL_LIST]: (node: any, children: any) => (
        <UnorderedList
          mb="1.25rem"
          css={css`
            > li > ul > li {
              list-style: circle;
              margin-bottom: 0px;
            }
            > li > ul {
              margin-bottom: 0px;
            }
          `}
        >
          {children}
        </UnorderedList>
      ),
      [BLOCKS.OL_LIST]: (node: any, children: any) => {
        return (
          <OrderedList
            mb="1.25rem"
            css={css`
              > li > ol > li {
                list-style: lower-alpha;
                margin-bottom: 0px;
              }
              > li > ol {
                margin-bottom: 0px;
              }
            `}
          >
            {children}
          </OrderedList>
        );
      },
      [BLOCKS.LIST_ITEM]: (node: any, children: any) => {
        return (
          <ListItem
            marginTop="0.5rem"
            css={css`
              > p {
                margin-bottom: 0px;
              }
            `}
          >
            {children}
          </ListItem>
        );
      },
      [BLOCKS.HR]: (node: any, children: any) => <Divider />,
      [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
        const asset = assetMap.get(node.data.target.sys.id);
        const videoRegExp = /^https:\/\/videos.ctfassets.net\/.*(.mp4|.wav)$/;
        const isVideo = asset?.contentType === 'video/mp4' || videoRegExp.test(asset?.url);

        if (asset) {
          return isVideo ? (
            <ArticleVideo rawVideoUrl={asset.url} />
          ) : (
            <ArticleImage imgUrl={asset.url} title={asset.title} description={asset.description} />
          );
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: any) => {
        const entry = entryMap.get(node.data.target.sys.id);

        // TODO Export separately with this ticket https://app.asana.com/0/1200905077481922/1202936579925123/f
        if (entry?.__typename === RichTextInlineTypes.VIDEO) {
          const video = entry as CustomVideo;
          return (
            <ArticleVideo
              rawVideoUrl={video.videoUrl}
              thumbnailUrl={video.thumbnail?.url}
              description={video.description}
              isEmbedded
            />
          );
        }
      },
      [INLINES.ENTRY_HYPERLINK]: (node: any, children: any) => {
        return (
          <Keyword
            title={node.content[0].value}
            marks={node.content[0].marks.map((mark: { type: MARKS }) => mark.type)}
            id={node.data.target.sys.id}
          />
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node: any, children: any) => {
        const entry = entryMap.get(node.data.target.sys.id);
        if (entry.__typename === RichTextInlineTypes.VIDEO) {
          const video = entry as CustomVideo;
          return (
            <ArticleVideo
              rawVideoUrl={video.videoUrl}
              thumbnailUrl={video.thumbnail?.url}
              description={video.description}
              isEmbedded
            />
          );
        }
      },
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        const { uri } = node.data;
        return (
          <Text
            as="a"
            href={uri}
            target="_blank"
            rel="noopener noreferrer"
            color="blue.highlighted"
          >
            {children}
          </Text>
        );
      },
    },
    renderMark: marks,
    renderText: (text: any) => {
      return text.split('\n').reduce((children: any, textSegment: any, index: any) => {
        return [...children, index > 0 && <br />, textSegment];
      }, []);
    },
  };
}
