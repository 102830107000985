import { Text, Stack, LayoutProps } from '@chakra-ui/react';
import { SearchIconLarge } from 'assets/common/SearchIconLarge';
import { ReactElement } from 'react';

type NoContentProps = {
  minHeight?: LayoutProps['minH'];
  children: React.ReactNode;
};

export const NoContentTextWrapper = ({ minHeight, children }: NoContentProps): ReactElement => {
  const calculatedMinHeight = minHeight || '70vh';
  return (
    <Stack
      maxW="100%"
      height="100%"
      minH={calculatedMinHeight}
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      spacing="14"
      px="4"
    >
      <SearchIconLarge w="130" h="130" ml="70px" />
      <Text textStyle="h4.reg" color="grey.30">
        {children}
      </Text>
    </Stack>
  );
};
