import { useEffect, useState } from 'react';

const isWindowLoaded = typeof window !== 'undefined';

export const useIsScrolled = (thresholdInPx: number): boolean => {
  const [isScrolled, setIsScrolled] = useState(isWindowLoaded && window.scrollY > thresholdInPx);

  useEffect(() => {
    // SSR Compatibility
    if (!isWindowLoaded) return undefined;
    let requestRunning: number | null = null;

    // Debouncer via requestAnimationFrame
    const handleScroll = (): void => {
      if (requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          updateIsScrolled();
          requestRunning = null;
        });
      }
    };

    const updateIsScrolled = (): void => {
      const { scrollY } = window;
      setIsScrolled(scrollY > thresholdInPx);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [thresholdInPx]);

  return isScrolled;
};
