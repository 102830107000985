import { ReactElement, useState } from 'react';
import { RegisterStep1 } from 'components/Registration/RegisterStep1';
import { RegisterStep2 } from 'components/Registration/RegisterStep2';
import { RegisterStep3 } from 'components/Registration/RegisterStep3';

export type RegistrationFields = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  dataSecurity: boolean;
  agb: boolean;
};

export type RegisterStepProps = {
  nextStep: () => void;
  prevStep: () => void;
  handleChanges: (e: string) => (event: MouseEvent) => void;
  values: RegistrationFields;
};

export const Registration = (): ReactElement => {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    dataSecurity: false,
    agb: false,
  } as RegistrationFields);

  const prevStep = (): void => {
    setStep(step - 1);
  };

  const nextStep = (): void => {
    setStep(step + 1);
  };

  const handleChanges = (input: string) => (e: any) => {
    const isCheckbox = (e.target.type as string) === 'checkbox';

    setValues({ ...values, [input]: isCheckbox ? e.target.checked : e.target.value });
  };

  switch (step) {
    case 1:
      return (
        <RegisterStep1
          nextStep={nextStep}
          prevStep={prevStep}
          handleChanges={handleChanges}
          values={values}
        />
      );
    case 2:
      return (
        <RegisterStep2
          nextStep={nextStep}
          prevStep={prevStep}
          handleChanges={handleChanges}
          values={values}
        />
      );
    case 3:
      return (
        <RegisterStep3
          nextStep={nextStep}
          prevStep={prevStep}
          handleChanges={handleChanges}
          values={values}
        />
      );
    default:
      return (
        <RegisterStep1
          prevStep={prevStep}
          nextStep={nextStep}
          handleChanges={handleChanges}
          values={values}
        />
      );
  }
};
