import { HStack } from '@chakra-ui/react';
import { css } from '@emotion/react';
import React, { ReactElement } from 'react';
import { Tags } from '@novaheal/types';
import { InfoPill } from './InfoPill';

type Props = {
  tags: Tags;
};

export const ArticleTags = ({ tags }: Props): ReactElement => {
  return (
    <HStack maxWidth="100%" mt="12px" flexFlow="wrap">
      {tags.ce.map(({ identifier, description }) => (
        <InfoPill key={identifier} identifier={identifier} description={description} />
      ))}
      {tags.kb.map(({ identifier, description }) => (
        <InfoPill key={identifier} identifier={identifier} description={description} />
      ))}
    </HStack>
  );
};
