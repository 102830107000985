import { useSearchBox } from 'react-instantsearch';
import {
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Button,
  HStack,
  Text,
  forwardRef,
} from '@chakra-ui/react';
import { useAddSearchEvent } from 'hooks/api/backend/search/search';
import { ChangeEvent, FormEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { CloseIcon, LensIcon } from 'assets/icons/Icons';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { ProtectedRoutes } from 'types/Routes';

type SearchInputProps = {
  onFocus: () => void;
  onBlur: () => void;
  onCancelClick: () => void;
  onSubmit: () => void;
  showCancelButton: boolean;
};

export const SearchInput = forwardRef(
  (
    { onFocus, onBlur, onCancelClick, onSubmit, showCancelButton }: SearchInputProps,
    ref
  ): ReactElement => {
    const { pathname } = useLocation();
    const {
      storedValue,
      setValue: saveSearchTerm,
      deleteValue: deleteSearchTerm,
    } = useLocalStorage<string>('searchTerm');
    const { query, refine, clear } = useSearchBox();
    const searchEvent = useAddSearchEvent();
    const [inputValue, setInputValue] = useState<string>(query);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const debounceDelayInMs = 100; // debounce call delay
    const showClearButton = inputValue.length !== 0;

    const handleSubmit = async (
      e: FormEvent<HTMLFormElement> | FormEvent<HTMLButtonElement>
    ): Promise<void> => {
      e.preventDefault();
      // Update search history in backend
      if (query.length > 0) {
        await searchEvent.mutateAsync(query);
      }

      refine(query);
      onSubmit();
    };

    const handleDebouncedChange = (e: ChangeEvent<HTMLInputElement>): void => {
      e.preventDefault();
      const { value } = e.currentTarget;

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => refine(value), debounceDelayInMs);

      setInputValue(value);
    };

    const handleCrossClick = (): void => {
      setInputValue('');
    };

    const handleFocus = (): void => {
      refine(inputValue);
      onFocus();
    };

    const handleCancelClick = (): void => {
      setInputValue('');
      clear();
      onCancelClick();
    };

    // Clear input field when navigating away from search page
    useEffect(() => {
      if (pathname !== ProtectedRoutes.SEARCH) {
        setInputValue('');
      }
    }, [pathname]);

    // save search term in local storage when using refresh
    useEffect(() => {
      const storeSearchTerm = (): void => {
        if (query) saveSearchTerm(query);
      };

      if (pathname === ProtectedRoutes.SEARCH) {
        window.addEventListener('beforeunload', storeSearchTerm);
      }
      return () => {
        window.removeEventListener('beforeunload', storeSearchTerm);
      };
    }, [pathname, query, saveSearchTerm]);

    useEffect(() => {
      const navigationStack = window.performance.getEntriesByType('navigation');
      if (!navigationStack || navigationStack.length === 0) return; // early out for IOS safari browser, no polyfill available

      const navigationType = navigationStack[0].type;

      if (navigationType === 'reload') {
        if (storedValue && pathname === ProtectedRoutes.SEARCH && inputValue === '') {
          setInputValue(storedValue);
          deleteSearchTerm();
          refine(storedValue);
        }
      }
    }, [storedValue, pathname, refine, inputValue, deleteSearchTerm]);

    return (
      <form autoComplete="off" onSubmit={handleSubmit}>
        <HStack spacing="4">
          <InputGroup>
            <InputLeftElement pointerEvents="none" color="grey.40">
              <LensIcon />
            </InputLeftElement>
            <Input
              ref={ref}
              bg="bg.light"
              px="4"
              py="2"
              border="1px solid"
              borderColor="grey.20"
              borderRadius="10px"
              placeholder="Fachbegriff, Schlagwort, Medikament, Pflegemaßnahme"
              value={inputValue}
              onChange={handleDebouncedChange}
              onFocus={handleFocus}
              onBlur={onBlur}
              _placeholder={{ fontSize: '14px', fontWeight: '300px' }}
              _focus={{
                borderColor: 'grey.20',
                borderRadius: { md: '10px 10px 0 0' },
              }}
              // Limit should be below 512 characters as we hit instantsearch API limits otherwise
              maxLength={400}
            />
            {showClearButton && (
              <InputRightElement
                onClick={handleCrossClick}
                onMouseDown={handleCrossClick}
                as={Button}
                bg="none"
                _hover={{
                  background: 'none',
                }}
              >
                <CloseIcon color="grey.40" />
              </InputRightElement>
            )}
          </InputGroup>

          {showCancelButton && (
            <Button
              bg="none"
              _hover={{
                background: 'none',
                cursor: 'pointer',
              }}
              p="0px"
              my="0px"
              color={{ base: 'grey.textinvers', md: 'grey.textPrimary' }}
              onClick={handleCancelClick}
              // only tmp solution, because of mobile touch behavior
              onMouseDown={handleCancelClick}
            >
              <Text textStyle="body.15.med">Abbrechen</Text>
            </Button>
          )}
        </HStack>
      </form>
    );
  }
);
