import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import * as Sentry from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (process.env.NODE_ENV === 'production') {
  if (
    window.location.hostname === 'learn.novaheal.de' ||
    window.location.href.startsWith('http://')
  ) {
    window.location.href = 'https://app.novaheal.de';
  }
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const rootContainer = document.getElementById('root');
if (!rootContainer) throw new Error('Failed to find the root element');
const root = createRoot(rootContainer);

root.render(
  <StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </StrictMode>
);

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
