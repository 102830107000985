import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const JuiceIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M18.2928 22.8914H6.82756C6.76202 22.8915 6.69871 22.8676 6.6496 22.8242C6.60048 22.7808 6.56896 22.7209 6.561 22.6559L4.06879 2.31073C4.0637 2.27288 4.06678 2.23439 4.07783 2.19783C4.08887 2.16128 4.10761 2.12752 4.1328 2.09882C4.15798 2.07012 4.18902 2.04714 4.22383 2.03145C4.25864 2.01575 4.29641 2.00769 4.33459 2.00781H20.7858C20.824 2.00769 20.8617 2.01575 20.8965 2.03145C20.9313 2.04714 20.9624 2.07012 20.9876 2.09882C21.0128 2.12752 21.0315 2.16128 21.0425 2.19783C21.0536 2.23439 21.0567 2.27288 21.0516 2.31073L18.5594 22.6559C18.5514 22.7209 18.5199 22.7808 18.4708 22.8242C18.4217 22.8676 18.3583 22.8915 18.2928 22.8914ZM7.06459 22.3552H18.0558L20.4791 2.54624H4.63826L7.06459 22.3552Z"
        fill="currentColor"
      />
      <path d="M18.4119 20.843H6.69141V22.6241H18.4119V20.843Z" fill="currentColor" />
      <path
        d="M14.0228 19.8881C14.7167 19.581 15.2738 19.03 15.5887 18.3395C15.9035 17.6491 15.9542 16.8672 15.7312 16.1418L10.1016 18.6325C10.4881 19.2856 11.1009 19.7741 11.8236 20.0055C12.5463 20.2369 13.3288 20.1951 14.0228 19.8881Z"
        fill="currentColor"
      />
      <path
        d="M11.5134 14.2184C10.8195 14.5255 10.2623 15.0765 9.94745 15.7669C9.63258 16.4574 9.58188 17.2393 9.80493 17.9646L15.4361 15.4739C15.0496 14.8204 14.4366 14.3316 13.7135 14.1001C12.9904 13.8687 12.2075 13.9108 11.5134 14.2184ZM11.012 16.9325C10.8922 16.9325 10.7751 16.8969 10.6755 16.8304C10.5758 16.7638 10.4982 16.6692 10.4523 16.5585C10.4065 16.4478 10.3945 16.326 10.4179 16.2085C10.4412 16.0909 10.4989 15.983 10.5837 15.8983C10.6684 15.8135 10.7763 15.7558 10.8938 15.7325C11.0114 15.7091 11.1332 15.7211 11.2439 15.7669C11.3546 15.8128 11.4492 15.8904 11.5158 15.9901C11.5823 16.0897 11.6179 16.2068 11.6179 16.3266C11.6179 16.4873 11.554 16.6414 11.4404 16.755C11.3268 16.8686 11.1727 16.9325 11.012 16.9325ZM13.1029 15.0393C13.0413 15.0394 12.981 15.0213 12.9298 14.9872C12.8785 14.9531 12.8385 14.9045 12.8148 14.8477C12.7911 14.7908 12.7849 14.7282 12.7968 14.6678C12.8087 14.6073 12.8383 14.5518 12.8818 14.5082C12.9253 14.4646 12.9807 14.4349 13.0411 14.4228C13.1015 14.4107 13.1641 14.4169 13.2211 14.4404C13.278 14.4639 13.3266 14.5038 13.3609 14.555C13.3951 14.6062 13.4134 14.6664 13.4134 14.728C13.4134 14.8104 13.3807 14.8895 13.3225 14.9478C13.2643 15.0062 13.1853 15.0391 13.1029 15.0393Z"
        fill="currentColor"
      />
      <path
        d="M10.4891 9.8868C11.0072 9.8868 11.4273 9.46672 11.4273 8.94853C11.4273 8.43033 11.0072 8.01025 10.4891 8.01025C9.97086 8.01025 9.55078 8.43033 9.55078 8.94853C9.55078 9.46672 9.97086 9.8868 10.4891 9.8868Z"
        fill="currentColor"
      />
      <path
        d="M14.3539 8.89832C14.735 8.89832 15.0438 8.58945 15.0438 8.20844C15.0438 7.82743 14.735 7.51855 14.3539 7.51855C13.9729 7.51855 13.6641 7.82743 13.6641 8.20844C13.6641 8.58945 13.9729 8.89832 14.3539 8.89832Z"
        fill="currentColor"
      />
      <path
        d="M8.27609 16.0261C8.44798 16.0261 8.58733 15.8867 8.58733 15.7148C8.58733 15.5429 8.44798 15.4036 8.27609 15.4036C8.10419 15.4036 7.96484 15.5429 7.96484 15.7148C7.96484 15.8867 8.10419 16.0261 8.27609 16.0261Z"
        fill="currentColor"
      />
      <path
        d="M16.5206 14.8667C16.7197 14.8667 16.8811 14.7053 16.8811 14.5062C16.8811 14.3071 16.7197 14.1458 16.5206 14.1458C16.3215 14.1458 16.1602 14.3071 16.1602 14.5062C16.1602 14.7053 16.3215 14.8667 16.5206 14.8667Z"
        fill="currentColor"
      />
      <path
        d="M16.1607 11.8472C16.5881 11.8472 16.9346 11.5007 16.9346 11.0733C16.9346 10.6458 16.5881 10.2993 16.1607 10.2993C15.7332 10.2993 15.3867 10.6458 15.3867 11.0733C15.3867 11.5007 15.7332 11.8472 16.1607 11.8472Z"
        fill="currentColor"
      />
      <path
        d="M7.83494 12.4917C8.09759 12.4917 8.31052 12.2788 8.31052 12.0161C8.31052 11.7534 8.09759 11.5405 7.83494 11.5405C7.57229 11.5405 7.35938 11.7534 7.35938 12.0161C7.35938 12.2788 7.57229 12.4917 7.83494 12.4917Z"
        fill="currentColor"
      />
      <path
        d="M20.3076 6.41006H4.81057C4.77817 6.41189 4.74573 6.40708 4.71524 6.39594C4.68476 6.3848 4.65687 6.36755 4.63328 6.34525C4.60969 6.32296 4.5909 6.29608 4.57806 6.26628C4.56522 6.23647 4.55859 6.20435 4.55859 6.17189C4.55859 6.13944 4.56522 6.10732 4.57806 6.07751C4.5909 6.0477 4.60969 6.02083 4.63328 5.99853C4.65687 5.97624 4.68476 5.95899 4.71524 5.94785C4.74573 5.9367 4.77817 5.9319 4.81057 5.93373H20.3076C20.34 5.9319 20.3724 5.9367 20.4029 5.94785C20.4334 5.95899 20.4613 5.97624 20.4849 5.99853C20.5085 6.02083 20.5273 6.0477 20.5401 6.07751C20.5529 6.10732 20.5596 6.13944 20.5596 6.17189C20.5596 6.20435 20.5529 6.23647 20.5401 6.26628C20.5273 6.29608 20.5085 6.32296 20.4849 6.34525C20.4613 6.36755 20.4334 6.3848 20.4029 6.39594C20.3724 6.40708 20.34 6.41189 20.3076 6.41006Z"
        fill="currentColor"
      />
      <path
        d="M4.63672 2.54635L4.81013 5.93367L20.3071 6.41075L20.6714 2.24268L4.63672 2.54635Z"
        fill="currentColor"
      />
    </Icon>
  );
};
