import { Box, IconButton, Link, Text, useTheme } from '@chakra-ui/react';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { useCurrentRoute } from 'hooks/utils/useCurrentRoute';
import React, { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Z_INDEX } from 'styles/zIndex';
import { RouteType } from 'types/Routes';

export const KeeunitBanner = (): ReactElement | null => {
  const theme = useTheme();
  const [shouldShowBanner, setShouldShowBanner] = useState(true);
  const location = useLocation();
  const { routeType } = useCurrentRoute(location);

  const handleClose = (): void => {
    setShouldShowBanner(false);
  };

  if (!shouldShowBanner || routeType !== RouteType.PUBLIC) {
    return null;
  }

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      background="green.primary"
      zIndex={Z_INDEX.BANNER}
      p={{ base: '20px', md: '20px 60px' }}
    >
      <Box
        display="flex"
        flexDirection="row"
        gap={{ base: '0px', md: '30px' }}
        justifyContent="space-between"
        alignItems="center"
        maxW={theme.container.width.md}
        mx="auto"
      >
        <Text color="grey.textinvers" textStyle={{ base: 'body.12.reg', md: 'body.14.med' }}>
          🚀 Willkommen in der neuen Novaheal-App! Wenn du unser altes System genutzt hast,
          registriere dich aus Sicherheitsgründen bitte erneut und gib ggf. deinen Voucher Code im
          Profil ein. Keine Sorge, alle deine Abonnementdetails werden zeitnah übertragen. &nbsp;
          <Link
            href="mailto:support@novaheal.de"
            color="blue.highlighted"
            textStyle={{ base: 'body.12.reg', md: 'body.14.med' }}
          >
            Probleme?
          </Link>
        </Text>
        <IconButton
          aria-label="close"
          icon={<CloseIcon height="24px" width="24px" color="grey.textinvers" />}
          width="30px"
          minWidth="30px"
          background="transparent"
          _active={{ background: 'transparent' }}
          _focus={{ background: 'transparent' }}
          _hover={{ background: 'transparent' }}
          onClick={handleClose}
        />
      </Box>
    </Box>
  );
};
