import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { axios, caughtErrorGlobally } from 'utils/axios';
import { toastContent } from 'config/toast';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { ToastVariant } from 'types/Toast';
import { EmailStatusType, PasswordStatusType } from 'types/StatusTypes';
import { ResetPasswordDto } from './dto/resetPassword.dto';

// Todo: Add return types & cleanup
export const useAuthApi = () => {
  const toast = useNovaToast();
  const navigate = useNavigate();

  const resendVerification = async (): Promise<void> => {
    try {
      const resp = await axios.post('/auth/resend-confirmation-link');
      if (resp.status === 201) {
        toast.show(toastContent.email[EmailStatusType.SEND]);
      }
    } catch (error) {
      if (!caughtErrorGlobally(error as AxiosError)) {
        toast.show({
          variant: ToastVariant.ERROR,
        });
      }
    }
  };

  const sendResetPasswordMail = async (values: { email: string }): Promise<number> => {
    try {
      const response: AxiosResponse = await axios.post('/auth/forgot-password', values);

      return response.status;
    } catch (error) {
      if (!caughtErrorGlobally(error as AxiosError)) {
        if ((error as AxiosError).response?.status === 404) {
          toast.show(toastContent.email[EmailStatusType.NOT_FOUND]);
        } else if ((error as AxiosError).response?.status === 409) {
          toast.show(toastContent.email[EmailStatusType.ALREADY_SEND]);
        } else {
          toast.show({
            variant: ToastVariant.ERROR,
          });
        }
      }
    }
    return 500;
  };

  const resetPassword = async (values: ResetPasswordDto): Promise<void> => {
    try {
      if (!values.token) {
        toast.show(toastContent.password[PasswordStatusType.NO_RESET_TOKEN]);
      }
      const response = await axios.post('/auth/reset-password', {
        password: values.password,
        token: values.token,
      });

      if (response.status === 201) {
        setTimeout(() => {
          navigate('/auth/login');
        }, 2000);
        toast.show(toastContent.password[PasswordStatusType.CHANGED]);
      }
    } catch (error) {
      if (!caughtErrorGlobally(error as AxiosError)) {
        toast.show({
          variant: ToastVariant.ERROR,
        });
      }
    }
  };

  return { resendVerification, sendResetPasswordMail, resetPassword };
};
