import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { ReactElement, ReactNode } from 'react';
import { useForm, UseFormReturn, SubmitHandler, UseFormProps, Resolver } from 'react-hook-form';
import { Stack } from '@chakra-ui/react';

// TODO: readd types - TFormValues
type FormProps<TFormValues, Schema> = {
  className?: string;
  onSubmit: SubmitHandler<any>;
  children: (methods: UseFormReturn<any>) => ReactNode;
  options?: UseFormProps<any>;
  id?: string;
  schema?: Schema;
  autoComplete?: 'off' | 'on';
  spacing?: number;
};

export const Form = <
  TFormValues extends Record<string, any> = Record<string, any>,
  Schema extends Joi.ObjectSchema = Joi.ObjectSchema,
>({
  onSubmit,
  children,
  className,
  options,
  id,
  schema,
  autoComplete = 'off',
  spacing = 3,
}: FormProps<TFormValues, Schema>): ReactElement => {
  const methods = useForm<TFormValues>({
    ...options,
    mode: 'onChange',
    resolver: (schema && joiResolver(schema)) as Resolver<TFormValues, any> | undefined,
  });

  return (
    <form
      className={className}
      onSubmit={methods.handleSubmit(onSubmit)}
      id={id}
      autoComplete={autoComplete}
    >
      <Stack spacing={spacing}>{children(methods)}</Stack>
    </form>
  );
};
