import { HStack, Image } from '@chakra-ui/react';
import { useModalService } from 'hooks/useModalService';
import { ReactElement } from 'react';
import { ModalVariant } from 'types/Modal';

type ArticleImageProps = {
  imgUrl: string;
  boxSize?: string;
  align?: string;
  title?: string;
  description?: string;
};

export const ArticleImage = ({
  imgUrl,
  boxSize = '175px',
  align = 'center',
  title,
  description,
}: ArticleImageProps): ReactElement => {
  const modalService = useModalService();

  const handleImageClick = (): void => {
    modalService.show(ModalVariant.IMAGE, {
      imgUrl,
      title,
      description,
    });
  };

  return (
    <HStack justify={align} my="30px">
      <Image
        borderRadius="10%"
        border="1px"
        borderColor="grey.10"
        onClick={handleImageClick}
        boxSize={boxSize}
        objectFit="cover"
        src={imgUrl}
        alt={title || 'Novaheal Abbildung'}
      />
      {/* TODO add image description */}
    </HStack>
  );
};
