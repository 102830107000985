import { ReactElement } from 'react';
import { Circle, HStack } from '@chakra-ui/react';

type PaginationProps = {
  activeIndex: number;
  totalSlideCount: number;
  setActiveIndex: (index: number) => void;
};

export const Pagination = ({
  activeIndex,
  totalSlideCount,
  setActiveIndex,
}: PaginationProps): ReactElement => {
  return (
    <HStack justify="center">
      {Array.from({ length: totalSlideCount }).map((_, index) => (
        // use index as key to keep the ref and therefore make use of state changes (no unmounting)
        <Circle
          size={activeIndex === index ? '10px' : '8px'}
          bg={activeIndex === index ? 'green.primary' : ''}
          border="1px"
          borderColor={activeIndex === index ? 'green.primary' : 'grey.tertiary'}
          boxShadow={activeIndex === index ? 'sm' : ''}
          onClick={() => setActiveIndex(index)}
          cursor="pointer"
          // eslint-disable-next-line react/no-array-index-key
          key={index.toString()}
        />
      ))}
    </HStack>
  );
};
