import { GoogleLoginCredentials, User, GoogleRegisterCredentials } from '@novaheal/types';
import { axios } from 'utils/axios';
import storage from 'utils/storage';
import { logFirebaseUserFlow } from 'utils/firebaseLogs';

export async function loginWithGoogle(credentials: GoogleLoginCredentials): Promise<User | null> {
  const response = await axios.post('google-authentication/login', {
    token: credentials.token,
  });
  const { user, accessToken } = response.data;
  storage.setToken(accessToken);
  logFirebaseUserFlow('login', { ourUserId: user.id, loginMethod: 'google' }, user); // named ourUserId, as userId is blocked by ga
  return user;
}

export async function registerWithGoogle(credentials: GoogleRegisterCredentials): Promise<User> {
  const response = await axios.post('google-authentication/register', {
    token: credentials.token,
  });
  const { user, accessToken } = response.data;
  storage.setToken(accessToken);
  logFirebaseUserFlow('register', { ourUserId: user.id, registerMethod: 'google' }, user); // named ourUserId, as userId is blocked by ga
  return user;
}
