/* eslint-disable no-console */
import { HtmlTagNames, HtmlTagOptions } from '@novaheal/types';
import { HTMLReactParserOptions, Element, domToReact, DOMNode } from 'html-react-parser';
import { Divider, ListItem, OrderedList, Text, TextProps, UnorderedList } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { ArticleImage } from 'components/Article/ArticleImage';
import { ArticleVideo } from 'components/Article/ArticleVideo';
import { Keyword } from 'components/Article/Keyword';
import { ArticleEmbeddedEntry } from 'components/Article/ArticleEmbeddedEntry';

// TODO: streamline font sizes and line heights with textStyles (part of mobile theme optimization)
const basicTextStyle: TextProps = {
  textStyle: 'body.16.reg',
  minHeight: '1em', // prevent empty paragraphs from collapsing
  mt: '10px',
  lineHeight: '130%',
  whiteSpace: 'pre-line', // prevent line breaks from collapsing, see here: https://github.com/contentful/rich-text/issues/96#issuecomment-953729068
};
const textStyles: Partial<Record<HtmlTagNames, TextProps>> = {
  [HtmlTagNames.PARAGRAPH]: {
    ...basicTextStyle,
  },
  [HtmlTagNames.HEADING_1]: {
    as: 'h1',
    fontSize: { base: '18px', md: '20px' },
    mt: '30px',
    lineHeight: '130%',
    fontWeight: '500',
  },
  [HtmlTagNames.HEADING_2]: {
    as: 'h2',
    fontSize: { base: '16px', md: '18px' },
    mt: '15px',
    lineHeight: '130%',
    fontWeight: 500,
  },
  [HtmlTagNames.BOLD]: { ...basicTextStyle, as: 'span', fontWeight: 'bold' },
  [HtmlTagNames.SUBSCRIPT]: { ...basicTextStyle, as: 'sub' },
  [HtmlTagNames.CODE]: { ...basicTextStyle, as: 'span', color: 'grey.40' },
  [HtmlTagNames.ITALIC]: { ...basicTextStyle, as: 'em' },
  [HtmlTagNames.UNDERLINE]: {
    ...basicTextStyle,
    as: 'mark',
    bgColor: 'bg.examKnowledge',
    fontWeight: 'inherit', // keep explicit styling if multiple marks apply
  },

  // Other headings are currnetly not available within the ctf wysiwyg-editor
  // [HtmlTagNames.HEADING_3]: { as: 'h3', textStyle: 'h4.reg', mt: '16px' },
  // [HtmlTagNames.HEADING_4]: { as: 'h4', textStyle: 'body.16.med' },
  // [HtmlTagNames.HEADING_5]: { as: 'h5', textStyle: 'body.16.med' },
  // [HtmlTagNames.HEADING_6]: { as: 'h6', textStyle: 'body.16.med' },
  // Other HtmlTagNames like UL_LIST, OL_LIST, LIST_ITEM, HR, VIDEO, IMAGE, EMBEDDED_ENTRY, and KEYWORD
  // have unique component requirements and aren't directly mappable to a Text component's style props.
  // [HtmlTagNames.HYPERLINK]: { as: 'a', color: 'blue.highlighted' },
  // [HtmlTagNames.UL_LIST]: {},
  // [HtmlTagNames.OL_LIST]: {},
  // [HtmlTagNames.LIST_ITEM]: {},
  // [HtmlTagNames.HR]: {},
  // [HtmlTagNames.VIDEO]: {},
  // [HtmlTagNames.IMAGE]: {},
  // [HtmlTagNames.EMBEDDED_ENTRY]: {},
  // [HtmlTagNames.KEYWORD]: {},
};

export const htmlToReactOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element) {
      const styles = textStyles[domNode.tagName as HtmlTagNames];
      const isTextNode = (Object.keys(textStyles) as HtmlTagNames[]).includes(
        domNode.tagName as HtmlTagNames
      );

      if (isTextNode) {
        return (
          <Text {...styles}>{domToReact(domNode.children as DOMNode[], htmlToReactOptions)}</Text>
        );
      }
      if (domNode.tagName === HtmlTagNames.UL_LIST) {
        return (
          <UnorderedList
            css={css`
              > li > ul > li {
                list-style: circle;
                margin-bottom: 0px;
              }
              > li > ul {
                margin-top: 0px;
              }
            `}
            mt="10px"
          >
            {domToReact(domNode.children as DOMNode[], htmlToReactOptions)}
          </UnorderedList>
        );
      }
      if (domNode.tagName === HtmlTagNames.OL_LIST) {
        return (
          <OrderedList
            css={css`
              > li > ol > li {
                list-style: lower-alpha;
                margin-bottom: 0px;
              }
              > li > ol {
                margin-bottom: 0px;
              }
            `}
          >
            {domToReact(domNode.children as DOMNode[], htmlToReactOptions)}
          </OrderedList>
        );
      }
      if (domNode.tagName === HtmlTagNames.LIST_ITEM) {
        return (
          <ListItem
            css={css`
              > p {
                margin-top: 0px;
              }
            `}
          >
            {domToReact(domNode.children as DOMNode[], htmlToReactOptions)}
          </ListItem>
        );
      }
      if (domNode.tagName === HtmlTagNames.HR) {
        return <Divider my="10px" />;
      }
      if (domNode.tagName === HtmlTagNames.HYPERLINK) {
        const uri = domNode.attribs['data-url'];
        return (
          <Text
            as="a"
            href={uri}
            target="_blank"
            rel="noopener noreferrer"
            color="blue.highlighted"
          >
            {domToReact(domNode.children as DOMNode[], htmlToReactOptions)}
          </Text>
        );
      }
      if (domNode.tagName === HtmlTagNames.EMBEDDED_ENTRY) {
        const entryOptions: HtmlTagOptions[HtmlTagNames.EMBEDDED_ENTRY] = {
          id: domNode.attribs['data-id'],
        };

        return <ArticleEmbeddedEntry id={entryOptions.id} />;
      }
      if (domNode.tagName === HtmlTagNames.IMAGE || domNode.tagName === 'img') {
        const imageOptions: HtmlTagOptions[HtmlTagNames.IMAGE] = {
          url: domNode.attribs['data-url'],
          title: domNode.attribs['data-title'],
          description: domNode.attribs['data-description'],
        };

        const shouldShowImage =
          imageOptions.title !== 'undefined' &&
          imageOptions.description !== 'undefined' &&
          !!imageOptions.title &&
          !!imageOptions.description;

        return shouldShowImage ? (
          <ArticleImage
            imgUrl={imageOptions.url}
            title={imageOptions.title}
            description={imageOptions.description}
          />
        ) : null;
      }
      if (domNode.tagName === HtmlTagNames.VIDEO) {
        const videoOptions: HtmlTagOptions[HtmlTagNames.VIDEO] = {
          title: domNode.attribs['data-title'],
          description: domNode.attribs['data-description'],
          url: domNode.attribs['data-url'],
        };
        return (
          <ArticleVideo
            rawVideoUrl={videoOptions.url}
            // thumbnailUrl={videoOptions.thumbnail?.url}
            description={videoOptions.description}
            // isEmbedded
          />
        );
      }
      if (domNode.tagName === HtmlTagNames.KEYWORD) {
        const options: HtmlTagOptions[HtmlTagNames.KEYWORD] = {
          id: domNode.attribs['data-id'],
        };

        return (
          <Keyword
            title={domToReact(domNode.children as DOMNode[], htmlToReactOptions) as string}
            // marks={node.content[0].marks.map((mark: { type: MARKS }) => mark.type)}
            marks={[]}
            id={options.id}
          />
        );
      }
      if (domNode.tagName === 'div') {
        return <>{domToReact(domNode.childNodes as DOMNode[], htmlToReactOptions)}</>;
      }
    }
    if (domNode.type !== 'text') {
      console.warn(`Missing implementation for type "${domNode.type}"`, domNode);
    }
  },
};
