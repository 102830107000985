import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const GiftIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8704 3.49929C14.6185 3.4186 14.5008 3.47763 14.4811 3.49037C13.9007 3.86647 13.4237 4.70926 13.0977 5.73917L13.0902 5.76298C13.9843 5.67206 14.987 5.485 15.8148 5.13196C15.8235 5.1056 15.8335 5.06182 15.8397 4.9935C15.8623 4.74328 15.7883 4.38241 15.5819 4.06388C15.391 3.76926 15.1178 3.57855 14.8704 3.49929ZM15.7982 5.16597C15.7976 5.16517 15.8002 5.15941 15.8076 5.15104C15.8025 5.16257 15.7988 5.16676 15.7982 5.16597ZM16.8408 3.24823C17.4062 4.1209 17.5059 5.24063 17.0833 5.94083H21.1614C21.5361 5.94083 21.8398 6.24459 21.8398 6.6193V10.6902C21.8398 11.0649 21.5361 11.3686 21.1614 11.3686H19.7077V11.3759V21.5899C19.7077 21.8397 19.5052 22.0422 19.2554 22.0422H12.8398H12.5624H11.3398H11.0624H4.1945C3.9447 22.0422 3.74219 21.8397 3.74219 21.5899V11.3759V11.3686H2.84254C2.46783 11.3686 2.16406 11.0649 2.16406 10.6902V6.6193C2.16406 6.24459 2.46783 5.94083 2.84254 5.94083H6.92454C6.50192 5.24063 6.60162 4.1209 7.16705 3.24823C7.92223 2.08271 9.38751 1.61284 10.3424 2.23152C11.1049 2.72562 11.6365 3.54574 12.0039 4.38545C12.3713 3.54574 12.9029 2.72562 13.6655 2.23152C14.6203 1.61284 16.0856 2.08271 16.8408 3.24823ZM3.66406 7.44083V9.86863H20.3398V7.44083H3.66406ZM11.0624 11.3759H5.24219V20.5422H11.0624V11.3759ZM12.8398 11.3759H18.2077V20.5422H12.8398V11.3759ZM9.5267 3.49037C9.50703 3.47763 9.38933 3.4186 9.13744 3.49929C8.89003 3.57855 8.6168 3.76926 8.42591 4.06388C8.21952 4.38241 8.1455 4.74328 8.16811 4.9935C8.17429 5.06182 8.18431 5.1056 8.19296 5.13196C9.02078 5.485 10.0235 5.67206 10.9177 5.76298L10.9102 5.73917C10.5841 4.70926 10.1072 3.86647 9.5267 3.49037ZM8.20959 5.16597C8.21017 5.16517 8.20761 5.15941 8.20018 5.15104C8.2053 5.16257 8.20901 5.16676 8.20959 5.16597Z"
        fill="currentColor"
      />
    </Icon>
  );
};
