import { Box, VStack, Text, Button, Image } from '@chakra-ui/react';
import NotFoundImage from 'assets/common/error404.png';
import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IndexRoutes, PublicRoutes } from 'types/Routes';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { Breakpoints } from 'types/breakpoints';
import { useCurrentRoute } from 'hooks/utils/useCurrentRoute';

type NotFoundProps = {
  title?: string;
  buttonText?: string;
  buttonLink?: string;
};

export const NotFound = ({
  title = 'Diese Seite wurde nicht gefunden.',
  buttonText = 'Zum Login',
  buttonLink = PublicRoutes.LOGIN,
}: NotFoundProps): ReactElement => {
  const navigate = useNavigate();
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const buttonWidth = isDesktop ? '40%' : 'full';
  const location = useLocation();
  const { currentRoute } = useCurrentRoute(location);
  useEffect(() => {
    if (currentRoute === IndexRoutes.FIRST_TIME) navigate(PublicRoutes.BASE);
  }, [currentRoute, navigate]);
  return (
    <VStack
      mt="20"
      display="flex"
      alignItems="center"
      px="8"
      textAlign="center"
      spacing="50"
      mx="auto"
    >
      <Image src={NotFoundImage} />
      <Box>
        <Text as="h2" textStyle="h1" color="blue.textHeader">
          {title}
        </Text>
        <Text as="h3" textStyle="h4.reg" color="grey.textPrimary" mt="4">
          Sorry! Wir arbeiten an dem Problem. Über den Button kommst du wieder zur App.
        </Text>
      </Box>
      <Button variant="primary" w={buttonWidth} onClick={() => navigate(buttonLink)}>
        {buttonText}
      </Button>
    </VStack>
  );
};
