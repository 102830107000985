import { ReactElement } from 'react';
import { Flex, Box, useTheme, Image } from '@chakra-ui/react';
import { useLocation, Link } from 'react-router-dom';
import { RouteType } from 'types/Routes';
import { useCurrentRoute } from 'hooks/utils/useCurrentRoute';
import { ExtendedLogo } from 'assets/common/ExtendedLogo';
import Background from 'assets/common/background.png';
import { ErrorBoundary } from 'react-error-boundary';
import { ModalTarget } from 'types/Modal';
import { Z_INDEX } from 'styles/zIndex';
import { renderError } from 'components/common/ErrorFallback';
import { NavigationBarDesktop } from '../NavigationBar/NavigationBarDesktop/NavigationBarDesktop';
import { SearchBar } from '../../Search/SearchBar';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { useUser } from '../../../utils/auth';

type Props = {
  children: ReactElement;
};

export const DesktopLayout = ({ children }: Props): ReactElement => {
  const theme = useTheme();
  const location = useLocation();
  const user = useUser();
  const { routeType } = useCurrentRoute(location);
  const isInApp = routeType === RouteType.PROTECTED;
  const isNotDefinedRoute = routeType === RouteType.NOT_DEFINED; // 404 page
  const containerPaddingX = isInApp || isNotDefinedRoute ? theme.container.paddingX.md : '0px';

  const renderNavigationBarDesktop = (): ReactElement | null => {
    if (isInApp || (isNotDefinedRoute && user.data)) {
      return <NavigationBarDesktop />;
    }
    return null;
  };

  const renderSearchBar = (): ReactElement => (
    <Box
      w="100%"
      bg="bg.light"
      h="70px"
      position="sticky"
      top="0"
      borderBottom="1px solid"
      borderBottomColor="grey.10"
      zIndex={Z_INDEX.SEARCH_BAR}
    >
      <SearchBar />
    </Box>
  );

  const renderMainContent = (): ReactElement => (
    <Flex height="100%" flexDirection="column">
      <Breadcrumbs />
      {!isInApp && !isNotDefinedRoute && (
        // TODO: move Logo back to top (with pt of 50px). Changes are tmp based on this design: https://www.figma.com/file/TBnYXwD9V0ez21mCHRchGl/NOVAHEAL-desktop?type=design&node-id=10525%3A5291&mode=design&t=aK3iiEoSw5B7rmSq-1
        <Box pt="80px">
          <Link to="/">
            <ExtendedLogo w="124px" h="70px" opacity="1" />
          </Link>
        </Box>
      )}
      <Box flexGrow={1} placeItems={isInApp ? undefined : 'center'} display="grid">
        <ErrorBoundary fallbackRender={renderError}>{children}</ErrorBoundary>
      </Box>
    </Flex>
  );

  // Render the image only if the user is not logged in and make use of the grid layout
  const renderLoginImage = (): ReactElement => (
    <Box bg="#EAFDF5" h="100%" display="flex" justifyContent="center">
      <Image src={Background} alt="background" my="auto" />
    </Box>
  );

  return (
    <Flex minH="100vh" background="bg.darken" position="relative">
      {renderNavigationBarDesktop()}
      <Flex flexGrow="1" position="relative" id={ModalTarget.PAGE_DESKTOP} flexDirection="column">
        {isInApp && renderSearchBar()}
        <Box
          maxW={theme.container.width.md}
          width="100%"
          margin="0 auto"
          padding={
            isInApp
              ? `${theme.container.paddingTop.md} ${containerPaddingX} ${theme.container.paddingBottom.md}`
              : `0 ${containerPaddingX}`
          }
          display={isInApp || isNotDefinedRoute ? 'flex' : 'grid'}
          flexDirection={isInApp ? 'column' : undefined}
          flexGrow={isInApp ? 1 : undefined}
          gridTemplateColumns={isInApp ? undefined : '1fr 1fr'}
          minH={isInApp ? undefined : '100vh'}
          position={isInApp ? undefined : 'relative'}
          alignItems={isInApp ? undefined : 'center'}
          gridGap={isInApp ? undefined : '20px'}
        >
          {renderMainContent()}
          {!isInApp && !isNotDefinedRoute && renderLoginImage()}
        </Box>
      </Flex>
    </Flex>
  );
};
