import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const AppleIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M12.2363 6.98601C13.016 6.98601 13.9932 6.45894 14.5752 5.75617C15.1023 5.11929 15.4866 4.22985 15.4866 3.3404C15.4866 3.21962 15.4756 3.09883 15.4537 3C14.5862 3.03294 13.543 3.58198 12.9171 4.31769C12.423 4.87771 11.9728 5.75617 11.9728 6.65659C11.9728 6.78836 11.9947 6.92013 12.0057 6.96405C12.0606 6.97503 12.1485 6.98601 12.2363 6.98601ZM9.49113 20.2727C10.5563 20.2727 11.0284 19.559 12.3571 19.559C13.7077 19.559 14.0042 20.2508 15.1901 20.2508C16.3541 20.2508 17.1337 19.1747 17.8694 18.1205C18.693 16.9126 19.0334 15.7267 19.0554 15.6718C18.9785 15.6498 16.7494 14.7384 16.7494 12.1799C16.7494 9.9618 18.5063 8.96255 18.6052 8.88569C17.4412 7.21661 15.6733 7.17269 15.1901 7.17269C13.8834 7.17269 12.8183 7.9633 12.1485 7.9633C11.4237 7.9633 10.4684 7.21661 9.3374 7.21661C7.18517 7.21661 5 8.99549 5 12.3556C5 14.4419 5.81258 16.6491 6.81182 18.0766C7.66832 19.2845 8.41502 20.2727 9.49113 20.2727Z"
        fill="currentColor"
      />
    </Icon>
  );
};
