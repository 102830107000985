import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const CapsuleIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <rect
        x="0.69534"
        y="0.0321218"
        width="17.8051"
        height="8.42953"
        rx="4.21477"
        transform="matrix(0.728472 -0.685076 0.662209 0.74932 2.16753 15.4187)"
        stroke="currentColor"
        fill="none"
      />
      <path
        d="M18.3337 12.0334L15.1738 15.0051L9.39299 8.46389L12.5529 5.49218C14.309 3.84074 17.0266 3.96629 18.6229 5.77259C20.2192 7.5789 20.0898 10.382 18.3337 12.0334Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.7"
      />
    </Icon>
  );
};
