import React, { createContext, ReactElement, useMemo, useState } from 'react';
import { MetaContextProps, MetaProps } from 'types/Layout';

const defaultMetaDataValues: MetaProps = {
  headTitle: '',
};

export const MetaContext = createContext<MetaContextProps>({
  currentMetaDataValues: { headTitle: '' },
  setCurrentMetaDataValues: () => null,
  defaultMetaDataValues,
});

type Props = {
  children: ReactElement;
};

export const MetaProvider = ({ children }: Props): ReactElement => {
  const [currentMetaDataValues, setCurrentMetaDataValues] = useState<MetaProps>({ headTitle: '' });

  const contextValue = useMemo(
    () => ({
      currentMetaDataValues,
      setCurrentMetaDataValues,
      defaultMetaDataValues,
    }),
    [currentMetaDataValues, setCurrentMetaDataValues]
  );

  return <MetaContext.Provider value={contextValue}> {children} </MetaContext.Provider>;
};
