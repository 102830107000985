import log from 'loglevel';

// Define default log level
const level = process.env.NODE_ENV === 'development' ? 'debug' : 'warn'; //  'trace' | 'debug' | 'info' | 'warn' | 'error' | 'silent'.

// Set default log level
log.setDefaultLevel(level);

// Set current log level
if (process.env.REACT_APP_LOG_LEVEL) {
  log.setLevel(process.env.REACT_APP_LOG_LEVEL as log.LogLevelDesc);
} else {
  log.setLevel(level);
}

export default log;
