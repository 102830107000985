import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, List, ListItem, SlideFade, useBoolean } from '@chakra-ui/react';
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { AutocompleteItem } from 'types/autocompleteItem';
import { FieldWrapperPassThroughProps } from 'components/common/Form/FieldWrapper';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { Breakpoints } from 'types/breakpoints';
import { Z_INDEX } from 'styles/zIndex';
import { InputField } from './InputField';

type AutocompleteFieldProps = FieldWrapperPassThroughProps & {
  zIndex?: number;
  placeholder?: string;
  getAutocompleteItems: (searchTerm: string) => Promise<AutocompleteItem[]>;
  registrationName: string;
  idName: string;
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
  getValues: UseFormGetValues<any>;
  initalName?: string;
  initalId?: string;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  isDisabled?: boolean;
};

export const AutocompleteField = (props: AutocompleteFieldProps): ReactElement => {
  const {
    placeholder,
    label,
    getAutocompleteItems,
    registrationName,
    idName,
    register,
    setValue,
    getValues,
    initalId,
    initalName,
    leftElement,
    rightElement,
    isDisabled,
    zIndex,
  } = props;
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<AutocompleteItem[]>([]);
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(-1);

  const autoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initalId && initalName) {
      setValue(idName, initalId);
      setValue(registrationName, initalName);
    }
  }, [initalId, initalName, setValue, idName, registrationName]);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    setValue(idName, null);

    const userInput: string = e.target.value;
    const suggestions = await getAutocompleteItems(userInput);

    setFilteredSuggestions(suggestions);
    setShowSuggestions(true);
  };

  const handleClick = (
    e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
    item?: AutocompleteItem
  ): void => {
    setSelectedSuggestionIndex(-1);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    if (item) {
      setValue(registrationName, item?.name, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue(idName, item?.id, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      setValue(registrationName, getValues(registrationName), {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    const suggestions = filteredSuggestions;
    if (e.key === 'ArrowDown' && selectedSuggestionIndex < suggestions.length - 1) {
      setSelectedSuggestionIndex(selectedSuggestionIndex + 1);
      if (selectedSuggestionIndex > 2) autoRef.current?.scrollBy(0, 55);
    } else if (e.key === 'ArrowUp' && selectedSuggestionIndex > -1) {
      setSelectedSuggestionIndex(selectedSuggestionIndex - 1);
      autoRef.current?.scrollBy(0, -50);
    } else if (e.key === 'Enter' && selectedSuggestionIndex > -1) {
      const selectedItem = suggestions[selectedSuggestionIndex];
      handleClick(e, selectedItem);
    }
  };

  const boxPos = isDesktop ? 'absolute' : 'relative';
  const dropdownLeft = isDesktop ? '-2px' : '0';
  const dropdownTop = '0';
  const dropdownPos = isDesktop ? 'relative' : 'absolute';
  const dropdownWidth = isDesktop ? '510px' : undefined;

  return (
    <Box width="100%" zIndex={zIndex}>
      <InputField
        isDisabled={isDisabled}
        type="text"
        label={label}
        placeholder={placeholder}
        registration={{
          ...register(registrationName, {
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              e.preventDefault();
              handleChange(e);
            },
          }),
        }}
        leftElement={leftElement}
        onKeyDown={handleKeyDown}
        rightElement={rightElement}
      />
      {showSuggestions && getValues(registrationName) && (
        <Box position={boxPos}>
          <SlideFade in={showSuggestions && getValues(registrationName) !== ''} offsetY="-0px">
            <Box
              ml={1}
              boxShadow="md"
              py="4"
              px="2"
              rounded="md"
              bg="white"
              zIndex={Z_INDEX.SEARCH_BAR_SUGGESTIONS}
              maxHeight="200"
              overflowY="auto"
              ref={autoRef}
              position={dropdownPos}
              left={dropdownLeft}
              top={dropdownTop}
              width={dropdownWidth}
              minWidth={dropdownWidth}
            >
              <List spacing="2">
                {filteredSuggestions.map((item, index) => (
                  <ListItem
                    whiteSpace="normal"
                    wordBreak="break-word"
                    key={item.id}
                    onClick={(e) => {
                      handleClick(e, item);
                    }}
                    cursor="pointer"
                    py="2"
                    px="3"
                    textStyle="body.14.reg"
                    borderBottom="0.5px solid #E5E5E5;"
                    color={selectedSuggestionIndex === index ? 'green.primary' : 'grey.secondary'} // Highlight selected suggestion
                  >
                    {item.name}
                  </ListItem>
                ))}
                <ListItem onClick={handleClick} p="2" whiteSpace="normal" wordBreak="break-word">
                  Neu anlegen: {getValues(registrationName)}
                </ListItem>
              </List>
            </Box>
          </SlideFade>
        </Box>
      )}
    </Box>
  );
};
