import { createContext, ReactElement, useEffect, useMemo, useState } from 'react';
import { axios } from 'utils/axios';
import { NOVAHEAL_API_KEY } from 'config/env';
import { FeatureFlag } from 'types/FeatureFlag';
import { AxiosResponse } from 'axios';

export const FeatureFlags = createContext<FeatureFlagMap>(new Map());

const axiosOptions = {
  headers: {
    'x-api-key': NOVAHEAL_API_KEY,
  },
};

type Props = {
  children: ReactElement;
};

type FeatureFlagMap = Map<string, boolean>;

export const FeatureFlagsProvider = ({ children }: Props): ReactElement => {
  const [features, setFeatures] = useState<FeatureFlagMap>(new Map());

  const contextValue = useMemo(() => features, [features]);

  useEffect(() => {
    const fetchFeatures = async (): Promise<void> => {
      try {
        const data: AxiosResponse<FeatureFlag[]> = await axios.get('/feature-flags', axiosOptions);
        if (!data) return;

        // using a Map because they are more efficient for the use case
        const parsedFeatureFlags: FeatureFlagMap = new Map(
          data.data.map((flag: FeatureFlag) => [flag.name, flag.isEnabled])
        );

        setFeatures(parsedFeatureFlags);
      } catch (err) {
        // Do not notify users because it should in the best case not affect them
        throw Error('Feature Flags could not be fetched!');
      }
    };
    fetchFeatures();
  }, []);

  return <FeatureFlags.Provider value={contextValue}>{children}</FeatureFlags.Provider>;
};
