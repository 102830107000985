import { VStack, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { Appetizer } from '@novaheal/types';

type Props = {
  appetizerData: Appetizer[];
};

export const AppetizerSection = ({ appetizerData }: Props): ReactElement => {
  const appetizerDataText = appetizerData.map((appetizer) => appetizer.value).join(', ');

  return (
    <VStack align="left" spacing="30px" mb="50px" px={{ base: '20px', md: '40px' }}>
      <Text textStyle="body.16.ita" color="green.secondary">
        {appetizerDataText}
      </Text>
    </VStack>
  );
};
