import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const LockIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M18.4629 10.3407V10.0089V7.61472C18.4629 4.15407 15.615 1.33325 12.121 1.33325H11.8816C8.3877 1.33325 5.53973 4.15403 5.53973 7.61472V10.0088C5.53973 10.1273 5.53973 10.2459 5.56361 10.3406L5.53973 10.3408C3.9602 10.3408 2.66797 11.6207 2.66797 13.1852V19.8222C2.66797 21.3867 3.9602 22.6666 5.53973 22.6666H18.4629C20.0424 22.6666 21.3346 21.3867 21.3346 19.8222V13.1852C21.3346 11.6207 20.0424 10.3408 18.4629 10.3408L18.4629 10.3407ZM6.97571 7.61472C6.97571 4.93633 9.17756 2.75546 11.8817 2.75546H12.1211C14.8253 2.75546 17.0271 4.9362 17.0271 7.61472V10.0087C17.0271 10.1273 17.0271 10.2458 17.0032 10.3406L6.99977 10.3408C6.97573 10.2222 6.97573 10.1036 6.97573 10.0089L6.97571 7.61472ZM19.8988 19.8221C19.8988 20.6043 19.2526 21.2444 18.4629 21.2444L5.53972 21.2442C4.74997 21.2442 4.10373 20.6042 4.10373 19.8219V13.1849C4.10373 12.4027 4.74993 11.7626 5.53972 11.7626H18.4629C19.2526 11.7626 19.8988 12.4026 19.8988 13.1849V19.8221ZM12.0014 14.1332C11.068 14.1332 10.3262 14.868 10.3262 15.7924C10.3262 16.551 10.8288 17.1673 11.5228 17.3806V18.3999C11.5228 18.6607 11.7381 18.874 12.0014 18.874C12.2646 18.874 12.48 18.6607 12.48 18.3999V17.3808C13.1739 17.1675 13.6765 16.551 13.6765 15.7926C13.6765 14.8682 12.9347 14.1332 12.0014 14.1332ZM12.0014 16.5036C11.5946 16.5036 11.2834 16.1718 11.2834 15.7924C11.2834 15.3895 11.5945 15.0813 12.0014 15.0813C12.4082 15.0813 12.7194 15.3895 12.7194 15.7924C12.7194 16.1717 12.4082 16.5036 12.0014 16.5036Z"
        fill="currentColor"
      />
    </Icon>
  );
};
