import { Button, Grid } from '@chakra-ui/react';
import { ArrowIcon } from 'assets/icons/ArrowIcon';
import { LogoIcon } from 'assets/icons/LogoIcon';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  onBackClick?: () => void;
};

export const TopBarTertiary = ({ onBackClick }: Props): ReactElement => {
  const navigate = useNavigate();
  return (
    <Grid
      placeItems="center"
      position="relative"
      width="100%"
      bg="bg.light"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.1)"
      height="60px"
    >
      <Button
        position="absolute"
        left="0"
        top="50%"
        transform="translateY(-50%)"
        variant="base"
        verticalAlign="middle"
        fontWeight="300"
        color="grey.secondary"
        px="0"
        ml="10px"
        onClick={() => (onBackClick === undefined ? navigate(-1) : onBackClick())}
      >
        <ArrowIcon w="8" h="8" />
      </Button>
      <LogoIcon w="33px" h="33px" />
    </Grid>
  );
};
