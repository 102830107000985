import { VStack, Text, Button, Box, Link } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Background from 'assets/common/background.png';
import { ExternalRoutes, PublicRoutes } from 'types/Routes';
import { ReactElement } from 'react';
import { PageContext } from 'components/common/PageContext';
import { useBreakpoint } from '../../hooks/utils/layout/useBreakpoint';
import { Breakpoints } from '../../types/breakpoints';

export const Landing = (): ReactElement => {
  const navigate = useNavigate();
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  return (
    <PageContext headTitle="Die #1 Lernapp für Pflege-Azubis">
      <>
        {!isDesktop && (
          <Box
            bgImage={Background}
            w="full"
            h="50vh"
            bgSize="contain"
            bgPos="center"
            bgRepeat="no-repeat"
            mt="2vh"
          />
        )}
        <VStack align="center" textAlign="center" pt="5" px="3" spacing="8">
          <VStack spacing="4">
            <Text as="h1" textStyle="h1" color="blue.textHeader">
              Willkommen bei Novaheal
            </Text>
            <Text textStyle="h4.reg" color="grey.textPrimary" pt="5">
              Sicher durch die Ausbildung, mehr Freizeit
              <br />
              und bessere Noten!
            </Text>
          </VStack>
          <VStack w="full" spacing="4" pb="4">
            <Button variant="primary" w="full" onClick={() => navigate(PublicRoutes.REGISTER)}>
              Registrieren
            </Button>
            <Button variant="secondary" w="full" onClick={() => navigate(PublicRoutes.LOGIN)}>
              Login
            </Button>
          </VStack>
          <Text marginY="15px !important" textStyle="body.14.reg">
            Mit dem Nutzen der App bestätigst du, dass du unsere{' '}
            <Link
              color="blue.highlighted"
              href={ExternalRoutes.PRIVACY}
              display="inline"
              target="_blank"
            >
              Datenschutzerklärung{' '}
            </Link>
            und{' '}
            <Link href={ExternalRoutes.TERMS} color="blue.highlighted" target="_blank">
              AGBs{' '}
            </Link>
            gelesen und akzeptiert hast.
          </Text>
        </VStack>
      </>
    </PageContext>
  );
};
