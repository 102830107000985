// This creates a client. It will be used to fetch data.
// TODO: only create one client for the whole app
// TODO: move config to config folder

import { AxiosError } from 'axios';
import {
  QueryClient,
  UseQueryOptions,
  UseMutationOptions,
  DefaultOptions,
} from '@tanstack/react-query';
import { refreshUserToken } from './auth';

const queryConfig: DefaultOptions = {
  queries: {
    throwOnError: true,
    refetchOnWindowFocus: false,
    retry: true,
    // This most certainly won't be needed anymore, as 401s are handled globally by axios. I will leave it here anyway, just in case as it needs a rework with react-query v5 anyway.
    // https://tanstack.com/query/latest/docs/framework/react/guides/migrating-to-v5#callbacks-on-usequery-and-queryobserver-have-been-removed
    // onError: async (error: any) => {
    //   if ((error as AxiosError).response?.status === 401) {
    //     await refreshUserToken();
    //   }
    // },
  },
  mutations: {
    onError: async (error) => {
      if ((error as AxiosError).response?.status === 401) {
        await refreshUserToken();
      }
    },
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type QueryConfig<FetcherFnType extends (...args: any) => any> = UseQueryOptions<
  Awaited<ReturnType<FetcherFnType>>
>;

export type MutationConfig<FetcherFnType extends (...args: any) => any> = UseMutationOptions<
  Awaited<ReturnType<FetcherFnType>>,
  AxiosError,
  Parameters<FetcherFnType>
>;
