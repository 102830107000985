import { ReactElement } from 'react';
import { Text } from '@chakra-ui/react';
import { useKeyword } from 'hooks/api/ctf/keyword/getKeywordById';
import { MARKS } from '@contentful/rich-text-types';
import { useModalService } from 'hooks/useModalService';
import { ModalVariant } from 'types/Modal';

type KeywordProps = {
  id: string;
  title: string;
  marks: MARKS[];
  isHeading?: boolean;
};

export const Keyword = ({ id, title, marks, isHeading = false }: KeywordProps): ReactElement => {
  const { data: keyword, isError } = useKeyword(id);
  const modalService = useModalService();

  const handleKeywordClick = (): void => {
    modalService.show(ModalVariant.KEYWORD, { data: keyword });
  };

  const getHTMLTagType = (): 'h1' | 'em' | 'span' => {
    if (isHeading) {
      return 'h1';
    }
    if (marks.includes(MARKS.ITALIC)) {
      return 'em';
    }
    return 'span';
  };

  if (!keyword || isError)
    return (
      <Text
        as={getHTMLTagType()}
        color="inherit"
        bgColor="inherit"
        textStyle="inherit"
        fontWeight="inherit"
      >
        {title}
      </Text>
    );

  return (
    <Text
      as={getHTMLTagType()}
      decoration="underline"
      textDecorationStyle="dotted"
      textDecorationColor="blue.highlighted"
      cursor="pointer"
      color="inherit"
      bgColor="inherit"
      textStyle="inherit"
      fontWeight="inherit"
      onClick={handleKeywordClick}
    >
      {title}
    </Text>
  );
};
