import { User } from '@novaheal/types';
import { AppleRegisterCredentials } from '@novaheal/types/lib/auth/registerCredentials/appleRegisterCredentials';
import { axios } from 'utils/axios';
import storage from 'utils/storage';
import { logFirebaseUserFlow } from 'utils/firebaseLogs';
import { appleAuthHelpers, AppleAuthResponse } from 'react-apple-signin-auth';
import {
  APPLE_LOGIN_BUNDLE_ID,
  APPLE_LOGIN_POP_UP,
  APPLE_LOGIN_REDIRECT_URL,
  APPLE_LOGIN_SCOPE,
} from 'config/env';

export async function registerWithApple(credentials: AppleRegisterCredentials): Promise<User> {
  const response = await axios.post('apple-authentication/register', {
    ...credentials.appleUser,
  });
  const { user, accessToken } = response.data;
  storage.setToken(accessToken);
  logFirebaseUserFlow('register', { ourUserId: user.id, registerMethod: 'apple' }, user); // named ourUserId, as userId is blocked by ga
  return user;
}
export async function authWithApple(): Promise<AppleAuthResponse | undefined> {
  try {
    const response = await appleAuthHelpers.signIn({
      authOptions: {
        clientId: APPLE_LOGIN_BUNDLE_ID,
        scope: APPLE_LOGIN_SCOPE ?? 'email name',
        redirectURI: APPLE_LOGIN_REDIRECT_URL as string,
        state: '',
        nonce: 'nonce',
        usePopup: APPLE_LOGIN_POP_UP,
      },
      onError: (error: any) => {
        if (error.error !== 'popup_closed_by_user') {
          throw new Error(error.error);
        }
      },
    });

    if (!response) return;
    return response;
  } catch (error) {
    console.error(error);
  }
}
