import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const InjectionIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_10416_122024)">
        <path
          d="M10.5628 20.6112L6.16016 17.6027L13.8509 6.35164C14.1509 5.91301 14.7738 5.81518 15.2369 6.13396L17.9485 7.98713C18.4149 8.30591 18.5502 8.92146 18.251 9.36009L10.5628 20.6112ZM6.74962 17.5131L10.4348 20.0307L17.8947 9.11387C17.9354 9.05064 17.9631 8.98001 17.9764 8.90603C17.9896 8.83206 17.9882 8.75618 17.972 8.68278C17.9559 8.60937 17.9254 8.53988 17.8824 8.47828C17.8393 8.41669 17.7845 8.3642 17.7211 8.32385L15.0094 6.47068C14.9487 6.4263 14.8799 6.39435 14.8069 6.37664C14.7339 6.35893 14.6581 6.35582 14.5838 6.36749C14.5096 6.37915 14.4384 6.40536 14.3743 6.44462C14.3102 6.48387 14.2545 6.5354 14.2104 6.59623L6.74962 17.5131Z"
          fill="currentColor"
        />
        <path
          d="M10.5899 20.7278L6.04297 17.6207L13.7801 6.29781C14.1063 5.82249 14.7813 5.7165 15.2876 6.06219L17.9993 7.91536C18.5056 8.26104 18.6515 8.92877 18.3254 9.40491L10.5899 20.7278ZM6.27941 17.584L10.5385 20.4946L18.1827 9.30707C18.4575 8.90513 18.3336 8.34176 17.9064 8.0507L15.1947 6.19753C14.7683 5.90565 14.1984 5.99533 13.9245 6.39646L6.27941 17.584ZM10.4611 20.1448L6.63324 17.5285L14.1397 6.54321C14.2421 6.40315 14.3958 6.30928 14.5672 6.28209C14.7385 6.25491 14.9137 6.2966 15.0545 6.39809L17.7645 8.25289C17.9108 8.34702 18.0138 8.49524 18.0511 8.66514C18.0884 8.83503 18.0568 9.01278 17.9634 9.1595L10.4611 20.1448ZM6.87376 17.491L10.4138 19.9108L17.8281 9.06167C17.8973 8.95257 17.9205 8.82053 17.8927 8.69436C17.8649 8.56818 17.7884 8.45814 17.6797 8.38823L14.968 6.53506C14.8634 6.45944 14.7332 6.42824 14.6056 6.44825C14.4781 6.46825 14.3637 6.53785 14.2873 6.64186L6.87376 17.491Z"
          fill="currentColor"
        />
        <path
          d="M11.1019 20.5029L6.06232 17.0587C6.02143 17.0307 5.96563 17.0412 5.93768 17.0821L5.55815 17.6374C5.53021 17.6783 5.5407 17.7341 5.58159 17.7621L10.6212 21.2063C10.6621 21.2342 10.7179 21.2238 10.7458 21.1829L11.1254 20.6275C11.1533 20.5866 11.1428 20.5308 11.1019 20.5029Z"
          fill="currentColor"
        />
        <path
          d="M8.75039 20.3771L7.03125 19.2021L5.2445 21.8165L6.96364 22.9914L8.75039 20.3771Z"
          fill="currentColor"
        />
        <path
          d="M7.82184 23.5788L4.38627 21.2308C4.33906 21.1985 4.27462 21.2106 4.24236 21.2579L3.80395 21.8993C3.77168 21.9465 3.7838 22.011 3.83101 22.0432L7.26659 24.3912C7.3138 24.4235 7.37823 24.4114 7.4105 24.3642L7.84891 23.7227C7.88117 23.6755 7.86906 23.611 7.82184 23.5788Z"
          fill="currentColor"
        />
        <path
          d="M18.0337 6.47782L16.6094 5.50439L16.0192 6.368L17.4435 7.34142L18.0337 6.47782Z"
          fill="currentColor"
        />
        <path
          d="M16.6094 5.50781L17.326 5.56325L17.7304 5.83964L18.0337 6.48128"
          fill="currentColor"
        />
        <path
          d="M20.7675 0.572002L21.3708 0.449707L17.8773 5.56162L17.5234 5.31948L20.7675 0.572002Z"
          fill="currentColor"
        />
        <path
          d="M9.96312 16.3784L9.84766 16.5474L11.7687 17.8603L11.8842 17.6913L9.96312 16.3784Z"
          fill="currentColor"
        />
        <path
          d="M11.7995 17.9754L9.73438 16.5641L9.94227 16.26L12.0074 17.6713L11.7995 17.9754ZM9.97081 16.5274L11.7482 17.7414L11.771 17.708L9.99364 16.4932L9.97081 16.5274Z"
          fill="currentColor"
        />
        <path
          d="M11.6389 15.6455L11.5234 15.8145L12.6442 16.5804L12.7596 16.4114L11.6389 15.6455Z"
          fill="currentColor"
        />
        <path
          d="M12.6755 16.6979L11.4102 15.8336L11.6189 15.5295L12.8834 16.3938L12.6755 16.6979ZM11.649 15.797L12.6274 16.4647L12.6502 16.4313L11.6719 15.7635L11.649 15.797Z"
          fill="currentColor"
        />
        <path
          d="M11.76 13.7493L11.6445 13.9182L13.5656 15.2311L13.6811 15.0622L11.76 13.7493Z"
          fill="currentColor"
        />
        <path
          d="M13.5964 15.3484L11.5312 13.9372L11.7391 13.6331L13.8043 15.0443L13.5964 15.3484ZM11.7677 13.8988L13.545 15.1136L13.5679 15.0794L11.7905 13.8654L11.7677 13.8988Z"
          fill="currentColor"
        />
        <path
          d="M13.7865 13.2291L13.6719 13.3989L14.7986 14.1591L14.9132 13.9893L13.7865 13.2291Z"
          fill="currentColor"
        />
        <path
          d="M14.4716 14.0677L13.207 13.2035L13.4149 12.8994L14.6795 13.7636L14.4716 14.0677ZM13.4418 13.1652L14.4202 13.8329L14.443 13.7995L13.4647 13.1318L13.4418 13.1652Z"
          fill="currentColor"
        />
        <path
          d="M13.7483 10.8411L13.6328 11.01L15.5539 12.3229L15.6693 12.154L13.7483 10.8411Z"
          fill="currentColor"
        />
        <path
          d="M15.5847 12.441L13.5195 11.0297L13.7274 10.7256L15.7926 12.1369L15.5847 12.441ZM13.756 10.993L15.5333 12.2078L15.5561 12.1744L13.7788 10.9596L13.756 10.993Z"
          fill="currentColor"
        />
        <path
          d="M15.7513 10.3262L15.6367 10.4961L16.7634 11.2563L16.878 11.0864L15.7513 10.3262Z"
          fill="currentColor"
        />
        <path
          d="M16.4598 11.1611L15.1953 10.2969L15.4032 9.99194L16.6677 10.8562L16.4598 11.1611ZM15.4318 10.2594L16.4101 10.9271L16.4329 10.8937L15.4546 10.2259L15.4318 10.2594Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10416_122024">
          <rect width="24" height="24" fill="white" transform="translate(0.558594 0.449707)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
