import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

type Props = IconProps & {
  isCollapsed?: boolean;
};

export const CollapseIcon = ({ isCollapsed, ...props }: Props): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <mask id="path-1-inside-1_8597_151389" fill="currentColor">
        <rect x="2" y="2" width="20" height="20" rx="1" />
      </mask>
      <rect
        x="2"
        y="2"
        width="20"
        height="20"
        rx="1"
        fill="transparent"
        stroke="currentColor"
        strokeWidth="3"
        mask="url(#path-1-inside-1_8597_151389)"
      />
      <mask id="path-2-inside-2_8597_151389" fill="currentColor">
        <rect x="2" y="2" width="7.77778" height="20" rx="1" />
      </mask>
      <rect
        x="2"
        y="2"
        width="7.77778"
        height="20"
        rx="1"
        fill={isCollapsed ? 'transparent' : 'currentColor'}
        stroke="currentColor"
        strokeWidth="3.4"
        mask="url(#path-2-inside-2_8597_151389)"
      />
    </Icon>
  );
};
