import React, { MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import {
  ModalProps,
  Modal,
  ModalOverlay,
  LayoutProps,
  BorderProps,
  BackgroundProps,
  Box,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { CloseIcon } from 'assets/icons/Icons';
import { Z_INDEX } from 'styles/zIndex';
import { ModalTarget } from 'types/Modal';
import { CustomModalContent } from './CustomModalContent';
import { CustomModalHeader } from './CustomModalHeader';

type Props = ModalProps & {
  maxWidth?: LayoutProps['maxWidth'];
  borderRadius?: BorderProps['borderRadius'];
  bg?: BackgroundProps['bg'];
  title?: string;
  onClose: () => void;
  hideHeader?: boolean;
  isFullscreen?: boolean;
  floatingCross?: boolean;
  target?: ModalTarget;
};

export const CustomModal = ({
  borderRadius,
  maxWidth,
  isCentered, // Modal is set to bottom if not centered
  bg,
  title,
  onClose,
  hideHeader = false,
  floatingCross = false,
  isFullscreen,
  target = ModalTarget.APP,
  ...props
}: Props): ReactElement => {
  const calculatedMaxWidth = isFullscreen ? undefined : maxWidth || '80%';
  const calculatedBorderRadius = isFullscreen ? '0px' : borderRadius || '10px';
  const isPageTarget = target === ModalTarget.PAGE_DESKTOP || target === ModalTarget.PAGE_MOBILE;

  const targetRef = useRef<HTMLElement | null>(document.body);
  const [targetElement, setTargetElement] =
    useState<MutableRefObject<HTMLElement | null>>(targetRef);

  useEffect(() => {
    if (target !== ModalTarget.APP) {
      targetRef.current = document.getElementById(target);
    }
  }, [target]);
  useEffect(() => {
    setTargetElement(targetRef);
  }, [targetRef]);

  const overlayZIndex = isPageTarget ? Z_INDEX.NAVBAR - 1 : Z_INDEX.MODAL_OVERLAY;

  return (
    <Modal
      isCentered
      onClose={onClose}
      {...props}
      scrollBehavior="inside"
      useInert={!isPageTarget}
      trapFocus={!isPageTarget}
      portalProps={{ containerRef: targetElement }}
    >
      <Box
        css={
          isPageTarget
            ? css`
                .chakra-modal__content-container {
                  pointer-events: none;
                }
              `
            : undefined
        }
      >
        <ModalOverlay
          position={isPageTarget ? 'absolute' : undefined}
          zIndex={overlayZIndex}
          backdropFilter="blur(10px)"
        />
        <CustomModalContent
          borderRadius={calculatedBorderRadius}
          maxWidth={calculatedMaxWidth}
          overflow="hidden"
          mx="20px"
          m="0"
          bottom="0"
          isCentered={isCentered}
          bg={bg}
          isFullscreen={isFullscreen}
          pointerEvents="all"
          containerProps={{ onClick: onClose }}
        >
          {!hideHeader && (
            <CustomModalHeader title={title} handleClose={onClose} floatingCross={floatingCross} />
          )}
          {floatingCross && (
            <Box position="relative">
              <CloseIcon
                zIndex={Z_INDEX.MODAL_OVERLAY}
                w="20px"
                h="20px"
                onClick={onClose}
                cursor="pointer"
                color="grey.secondary"
                position="absolute"
                right="30px"
                top="30px"
                transform="translateY(-50%)"
              />
            </Box>
          )}
          {props.children}
        </CustomModalContent>
      </Box>
    </Modal>
  );
};
