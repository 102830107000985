import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const LeaveIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M17.7227 9.01318L20.2113 12L17.7227 9.01318ZM17.7227 14.9868L20.2113 12L17.7227 14.9868Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.7192 12H10.9535M14.5376 3.63696H3.78516V20.363H14.5376V15.5842M14.5376 3.71994V8.41584"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        fill="none"
      />
    </Icon>
  );
};
