import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const KeyIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M13.3192 10.0862C13.5552 9.45033 13.873 8.7834 13.873 8.06541C13.873 4.8256 11.2466 2.19922 8.00682 2.19922C4.76701 2.19922 2.14062 4.8256 2.14062 8.06541C2.14062 11.3052 4.76701 13.9316 8.00682 13.9316C8.75462 13.9316 9.42228 13.6872 10.08 13.4321"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M10.0938 13.4322L11.4348 15.0146C11.4728 15.0594 11.5286 15.0853 11.5873 15.0853H13.4581C13.5686 15.0853 13.6581 15.1748 13.6581 15.2853V16.9999C13.6581 17.1168 13.758 17.2088 13.8745 17.1993L15.3536 17.078C15.4801 17.0676 15.5842 17.1762 15.5684 17.3021L15.3725 18.8693C15.3576 18.9887 15.4507 19.0941 15.571 19.0941H17.2761C17.3866 19.0941 17.4761 19.1837 17.4761 19.2941V21.0656C17.4761 21.1266 17.504 21.1843 17.5517 21.2223L18.5033 21.9779C18.5386 22.006 18.5825 22.0212 18.6276 22.0212H21.6668C21.7773 22.0212 21.8668 21.9317 21.8668 21.8212V18.5103C21.8668 18.4564 21.845 18.4048 21.8065 18.3671L13.349 10.1147"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <circle cx="6.91979" cy="6.91197" r="1.11432" stroke="currentColor" strokeWidth="0.9" />
    </Icon>
  );
};
