import { Box, Circle, Flex, VStack } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  HeartIcon,
  HouseIcon,
  HumanIcon,
  LeaveIcon,
  QuestionCircleIcon,
  CollapseIcon,
  LensIcon,
} from 'assets/icons/Icons';
import React, { ReactElement } from 'react';
import { ExternalRoutes, ProtectedRoutes } from 'types/Routes';
import { ExtendedLogo } from 'assets/common/ExtendedLogo';
import { useLogout, useUser } from 'utils/auth';
import { motion } from 'framer-motion';
import { useIsDesktopNavOpen } from 'hooks/utils/layout/useIsDesktopNavOpen';
import { Z_INDEX } from 'styles/zIndex';
import { isSubscriptionStatus } from 'utils/isSubscriptionStatus';
import { SubscriptionStatus } from '@novaheal/types';
import { css } from '@emotion/react';
import { NavigationLink } from './NavigationLink';
import { NavigationLinkWithDropdown } from './NavigationLinkWithDropdown';

export const closedWidth = 72;

type PanelLinks = {
  to: ProtectedRoutes | ExternalRoutes;
  title: string;
  isExternal?: boolean;
};

export const NavigationBarDesktop = (): ReactElement => {
  const { data: user } = useUser();
  const isPaidUser = isSubscriptionStatus(user, SubscriptionStatus.PAID);
  const location = useLocation();
  const { isOpen, toggle } = useIsDesktopNavOpen();
  const logout = useLogout();
  const navigate = useNavigate();

  const width = isOpen ? '200px' : `${closedWidth}px`;
  const logoTransform = isOpen ? 'scale(1.75)' : undefined;
  const transition = '0.4s ease-in-out';

  const isProfilePage =
    (location.pathname.startsWith(ProtectedRoutes.SETTINGS) &&
      location.pathname !== ProtectedRoutes.SUPPORT) ||
    location.pathname === ProtectedRoutes.PAYMENT_MANAGEMENT;

  const profilePanelLinks: PanelLinks[] = [
    { to: ProtectedRoutes.PROFILE, title: 'Persönliche Daten' },
    { to: ProtectedRoutes.PASSWORD, title: 'Passwort ändern' },
    { to: ProtectedRoutes.VOUCHER, title: 'Code eingeben' },
    { to: ProtectedRoutes.PAYMENT_MANAGEMENT, title: 'Premium Management' },
    { to: ProtectedRoutes.LEGAL, title: 'Rechtliches' },
  ];
  const supportPanelLinks: PanelLinks[] = [
    {
      to: ExternalRoutes.NOVAHEAL,
      title: 'Novaheal Infos',
      isExternal: true,
    },
    {
      to: ExternalRoutes.HELP,
      title: 'Hilfe & Kontakt',
      isExternal: true,
    },
  ];

  // returns the filtered PanelLinks based on the premium status of the user (Premium User can see Premium management)
  const getModifiedProfilePanelLinks = (): PanelLinks[] => {
    if (!isPaidUser) {
      return profilePanelLinks.filter((link) => link.to !== ProtectedRoutes.PAYMENT_MANAGEMENT);
    }
    return profilePanelLinks;
  };

  return (
    <Box
      h="100vh"
      maxH="100vh"
      bg="bg.desktopNav"
      position="sticky"
      top="0"
      flexShrink="0"
      w={width}
      pt="20px"
      color="grey.20"
      as={motion.div}
      transition={transition}
      zIndex={Z_INDEX.NAVBAR}
      // used for the modal centering
      data-id="navigation-bar-desktop"
    >
      <Circle
        size="40px"
        padding="5px"
        position="absolute"
        top="14px"
        right="0"
        transform="translateX(40%)"
        cursor="pointer"
        onClick={toggle}
        backgroundColor="bg.desktopNav"
        zIndex={1}
      >
        <Circle size="100%" bg="#ffffff4c;">
          <CollapseIcon w="20px" h="20px" color="bg.light" isCollapsed={!isOpen} />
        </Circle>
      </Circle>
      <Flex flexDirection="column" h="100%" justifyContent="space-between" paddingTop="35px">
        <Flex flexShrink={0} justifyContent="center">
          <Box h="70px" onClick={() => navigate(ProtectedRoutes.HOME)}>
            <ExtendedLogo w="70px" h="40px" transform={logoTransform} transition={transition} />
          </Box>
        </Flex>

        <Flex
          direction="column"
          alignItems="flex-start"
          flex={1}
          overflowY="scroll"
          paddingTop="60px"
          css={css`
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }
          `}
        >
          <VStack align="left" w="100%" spacing="10">
            <NavigationLink
              to={ProtectedRoutes.HOME}
              Icon={<HouseIcon />}
              isActive={
                location.pathname === ProtectedRoutes.HOME ||
                location.pathname.startsWith(ProtectedRoutes.NEWS) ||
                location.pathname.startsWith(ProtectedRoutes.CURATED_LIST)
              }
              title="Home"
              hasTitle={isOpen}
            />
            <NavigationLink
              to={ProtectedRoutes.KNOWLEDGE}
              Icon={<LensIcon />}
              isActive={
                location.pathname.startsWith(ProtectedRoutes.KNOWLEDGE) ||
                location.pathname.startsWith(ProtectedRoutes.SEARCH)
              }
              title="Suche"
              hasTitle={isOpen}
            />
            <NavigationLink
              to={ProtectedRoutes.FAVORITES}
              Icon={<HeartIcon />}
              isActive={location.pathname.startsWith(ProtectedRoutes.FAVORITES)}
              title="Favoriten"
              hasTitle={isOpen}
            />
            <NavigationLinkWithDropdown
              to={ProtectedRoutes.SETTINGS}
              Icon={<HumanIcon />}
              hasTitle={isOpen}
              isActive={isProfilePage}
              title="Profil"
              panelLinks={getModifiedProfilePanelLinks()}
            />
            <Box
              h="2px"
              w={`calc(100% - ${closedWidth / 2}px)`}
              bg="grey.20"
              pos="relative"
              left="50%"
              transform="translateX(-50%)"
            />
          </VStack>

          <VStack align="left" spacing="10" mt="30px" paddingBottom="40px">
            <NavigationLinkWithDropdown
              to={ProtectedRoutes.SUPPORT}
              Icon={<QuestionCircleIcon />}
              isActive={location.pathname === ProtectedRoutes.SUPPORT}
              title="Support"
              hasTitle={isOpen}
              panelLinks={supportPanelLinks}
            />
            <NavigationLink
              to={ProtectedRoutes.SUPPORT}
              Icon={<LeaveIcon />}
              isActive={false}
              title="Logout"
              isOnClickLink
              isInternalLink={false}
              onClick={() => logout.mutate({})}
              hasTitle={isOpen}
            />
          </VStack>
        </Flex>
      </Flex>
    </Box>
  );
};
