export enum ProtectedRoutes {
  BASE = '/app',
  HOME = '/app',

  PAYMENT_MANAGEMENT = '/app/subscription/management',
  PAYMENT_REDIRECT = '/app/subscription/redirect',

  CURATED_LIST = '/app/curated-list',
  NEWS = '/app/news',
  KNOWLEDGE = '/app/knowledge',
  FOLDER = '/app/knowledge/folder',
  CATEGORY = '/app/knowledge/category',
  SEARCH = '/app/knowledge/search',
  ARTICLE = '/app/knowledge/article',
  MEDICATION = '/app/knowledge/medication',
  FAVORITES = '/app/favorites',

  NOT_FOUND_FALLBACK = '*',

  SETTINGS = '/app/settings',
  PROFILE = '/app/settings/profile',
  PASSWORD = '/app/settings/password',
  VOUCHER = '/app/settings/voucher',
  LEGAL = '/app/settings/legal',
  SUPPORT = '/app/settings/support',
  DELETE_ACCOUNT = '/app/settings/delete-account',
  CANCEL_SUBSCRIPTION = '/app/settings/cancel-subscription',
  SURVEY = '/app/settings/survey',
}

export enum ExternalRoutes {
  NOVAHEAL = 'https://novaheal.de',
  HELP = 'https://novaheal-25216114.hubspotpagebuilder.eu/de/contact',
  TERMS = 'https://novaheal.de/nutzungsbedingungen',
  PRIVACY = 'https://novaheal.de/datenschutzerklarung',
  IMPRINT = 'https://novaheal.de/impressum',
  DISCLAIMER = 'https://novaheal.de/haftungsbeschrankung',
}

export enum PublicRoutes {
  BASE = '/',
  AUTH = '/auth/*',
  LOGIN = '/auth/login',
  REGISTER = '/auth/register',
  FORGOT_PASSWORD = '/auth/forgot-password',

  // !! only accessible via email link and requires token
  RESET_PASSWORD = '/auth/reset-password',

  // !! Auth routes are separated to work with the current Route layout. This is a temporary solution. Check routes>public.tsx
  // !! Gets used, as we are defining the routes in the auth stack with this entries and therefore need to get rid of auth prefix
  AUTH_LOGIN = 'login',
  AUTH_REGISTER = 'register',
  AUTH_FORGOT_PASSWORD = 'forgot-password',
  AUTH_RESET_PASSWORD = 'reset-password',
}

export enum IndexRoutes {
  BASE = '/app',
  FIRST_TIME = '/app/welcome',
  NOT_FIRST_TIME = '*',
}

export const AllRoutes = { ...ProtectedRoutes, ...PublicRoutes, ...IndexRoutes };
export type AllRoutes = typeof AllRoutes;

export enum RouteType {
  PROTECTED = 'protected',
  PUBLIC = 'public',
  NOT_DEFINED = 'not-defined',
}

export enum NativeAppRoutes {
  LOGIN = 'novaheal://app/login',
}
