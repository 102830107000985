import { TopBarContext } from 'components/providers/TopBarProvider';
import { useContext } from 'react';
import { TopBarContextProps } from 'types/Layout';

export const useTopBarContext = (): TopBarContextProps => {
  const context = useContext(TopBarContext);

  if (!context) {
    throw new Error('useTopBarContext must be used within a TopBarProvider');
  }
  return context;
};
