import { useToast } from '@chakra-ui/react';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { toastContent, toastDuration } from 'config/toast';
import { renderNovaToast } from 'components/common/overlays/NovaToast';
import { ShowToastProps, ToastVariant, UseNovaToast } from 'types/Toast';
import { ErrorStatusType, SuccessStatusType } from 'types/StatusTypes';

type BaseToastContent = {
  title: string;
  description?: string;
};

export const useNovaToast = (): UseNovaToast => {
  const toast = useToast();

  const getDefaultToast = (variant: ToastVariant): BaseToastContent => {
    switch (variant) {
      case ToastVariant.ERROR:
        return toastContent.error[ErrorStatusType.DEFAULT];
      case ToastVariant.SUCCESS:
        return toastContent.success[SuccessStatusType.DEFAULT];
      default:
        return toastContent.error[ErrorStatusType.DEFAULT];
    }
  };

  const show = useMemo(
    () =>
      ({ id = uuidv4(), title, description, variant }: ShowToastProps) => {
        const { title: defaultTitle, description: defaultDescription } = getDefaultToast(variant);
        // the descriptionText would otherwise be the default description - if you only put in a title
        const descriptionText = title ? description : description ?? defaultDescription;

        if (!toast.isActive(id)) {
          toast({
            id,
            position: 'top',
            duration: toastDuration,
            render: () =>
              renderNovaToast({
                title: title ?? defaultTitle,
                description: descriptionText,
                onClose: () => toast.close(id),
                variant,
              }),
          });
        }
      },
    [toast]
  );

  return { show };
};
