import { Flex, Image, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

type Props = {
  imageUrl: string;
  heading: string;
  copy: string;
};

export const SubscriptionSlide = ({ imageUrl, heading, copy }: Props): ReactElement => {
  return (
    <Flex width="100%" flexDirection="column" gap="15px" textAlign="center" alignItems="center">
      <Image src={imageUrl} maxWidth={{ base: '280px', md: undefined }} />
      <Text textStyle="h2" color="blue.textHeader">
        {heading}
      </Text>
      <Text>{copy}</Text>
    </Flex>
  );
};
