import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import Placeholder from 'assets/common/placeholder.png';

type ArticleHeroProps = {
  coverImageUrl: string;
};

export const ArticleHero = ({ coverImageUrl }: ArticleHeroProps): ReactElement => {
  return (
    <Box overflow="hidden">
      <Box
        w="100vw"
        h="30vh"
        backgroundImage={coverImageUrl || Placeholder}
        bgSize="cover"
        bgPos="center"
        bgRepeat="no-repeat"
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
      />
    </Box>
  );
};
