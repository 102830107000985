import { ModalContext } from 'components/providers/ModalContextProvider';
import { useContext } from 'react';
import { ModalContextProps } from 'types/Modal';

export const useModalContext = (): ModalContextProps => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
};
