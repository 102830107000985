import { AnalyticsCallOptions, EventParams, logEvent, setUserProperties } from 'firebase/analytics';
import log from 'utils/logger';
import { keysToSnakeCase } from 'utils/keysToSnakeCase';
import {
  FirebaseEvent,
  FirebaseFeedbackParams,
  FirebaseVideoInteractionType,
  Subscription,
  User,
} from '@novaheal/types';
import { firebaseAnalytics } from 'config/firebaseConfig';

function extractProperties(user: User | null | undefined): Record<string, any> {
  if (!user) return {};
  const { id, voucherId, educationStatus, subscription, isFirstTimeUse, b2bSchoolId, b2cSchoolId } =
    user;
  const { status } = subscription as Subscription;
  const iftu = isFirstTimeUse?.toString();
  return {
    user_voucher: voucherId,
    user_education_status: educationStatus,
    user_subscription: status,
    user_first_time_use: iftu,
    user_school: b2bSchoolId ?? b2cSchoolId,
    our_user_id: id,
  };
}

const setUserPropertiesBeforeLog = (
  user: User | null | undefined
): Record<string, any> | null | undefined => {
  if (!user) return;
  const extractedUserProps = extractProperties(user);
  if (user) setUserProperties(firebaseAnalytics, extractedUserProps);
  return extractedUserProps;
};

const logTheEvent = (
  eventName: FirebaseEvent | string,
  eventParams?: EventParams,
  options?: AnalyticsCallOptions,
  user?: User | null | undefined
): void => {
  const snakeCaseParams = eventParams ? keysToSnakeCase(eventParams) : eventParams;
  try {
    setUserPropertiesBeforeLog(user);
    logEvent(firebaseAnalytics, eventName, snakeCaseParams, options);
  } catch (error) {
    log.debug('Failed to log event:', error);
  }
};

export const logFirebaseEvent = (
  eventName: FirebaseEvent | string,
  eventParams?: EventParams,
  options?: AnalyticsCallOptions,
  user?: User | null | undefined
): void => {
  const snakeCaseParams = eventParams ? keysToSnakeCase(eventParams) : eventParams;
  try {
    logTheEvent(eventName, snakeCaseParams, options, user);
  } catch (error) {
    log.debug('Failed to log event:', error);
  }
};

export const logFirebaseVideo = (
  interactionType: FirebaseVideoInteractionType,
  videoName: string,
  additionalParams?: EventParams,
  user?: User | null | undefined
): void => {
  const eventParams: EventParams = {
    interactionType,
    videoName,
    ...additionalParams,
  };

  const snakeCaseParams = keysToSnakeCase(eventParams);

  try {
    logTheEvent(FirebaseEvent.VIDEO_EVENT, snakeCaseParams, undefined, user);
  } catch (error) {
    log.debug('Failed to log event:', error);
  }
};

export const logFirebaseClick = (
  elementType: string,
  elementName: string,
  additionalParams?: EventParams,
  user?: User | null | undefined
): void => {
  const eventParams: EventParams = {
    elementType,
    elementName,
    ...additionalParams,
  };

  const snakeCaseParams = keysToSnakeCase(eventParams);

  try {
    logTheEvent(FirebaseEvent.CLICK_EVENT, snakeCaseParams, undefined, user);
  } catch (error) {
    log.debug('Failed to log event:', error);
  }
};

export const logFirebaseUserFlow = (
  userEvent: string,
  additionalParams?: EventParams,
  user?: User | null | undefined
): void => {
  const eventParams: EventParams = {
    userEvent,
    ...additionalParams,
  };

  const snakeCaseParams = keysToSnakeCase(eventParams);

  try {
    logTheEvent(FirebaseEvent.USER_FLOW_EVENT, snakeCaseParams, undefined, user);
  } catch (error) {
    log.debug('Failed to log event:', error);
  }
};

export const logFirebaseUserData = (
  additionalParams: EventParams,
  user?: User | null | undefined
): void => {
  const eventParams: EventParams = {
    ...additionalParams,
  };

  const snakeCaseParams = keysToSnakeCase(eventParams);

  try {
    logTheEvent(FirebaseEvent.USER_DATA_EVENT, snakeCaseParams, undefined, user);
  } catch (error) {
    log.debug('Failed to log event:', error);
  }
};

export const logFirebaseFeedback = (
  contentName: string,
  feedback: FirebaseFeedbackParams,
  additionalParams?: EventParams,
  user?: User | null | undefined
): void => {
  const eventParams: EventParams = {
    contentName,
    ...feedback,
    ...additionalParams,
  };

  const snakeCaseParams = keysToSnakeCase(eventParams);

  try {
    logTheEvent(FirebaseEvent.FEEDBACK_EVENT, snakeCaseParams, undefined, user);
  } catch (error) {
    log.debug('Failed to log event:', error);
  }
};

export const logFirebaseFeedbackVote = (
  contentName: string,
  feedback: FirebaseFeedbackParams,
  additionalParams?: EventParams,
  user?: User | null | undefined
): void => {
  const eventParams: EventParams = {
    contentName,
    ...feedback,
    ...additionalParams,
  };

  const snakeCaseParams = keysToSnakeCase(eventParams);

  try {
    logTheEvent(FirebaseEvent.FEEDBACK_EVENT, snakeCaseParams, undefined, user);
  } catch (error) {
    log.debug('Failed to log event:', error);
  }
};
