import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { contentfulClient } from 'utils/contentful';
import { gql } from 'graphql-request';
import { CONTENTFUL_IS_PREVIEW } from 'config/env';
import { Keyword, RawContentfulData } from '@novaheal/types';
import { parseKeyword } from '@novaheal/parsers';

const query = gql`
  query KeywordQuery($id: String!, $preview: Boolean!) {
    schlagwort(id: $id, preview: $preview) {
      sys {
        id
      }
      title
      metaInformation
      content {
        json
      }
      illustration {
        url
        description
      }
      article {
        sys {
          id
        }
        title
      }
    }
  }
`;

export const getKeyword = async (id: string | undefined): Promise<Keyword | null> => {
  const data = await contentfulClient.request<RawContentfulData>(query, {
    id,
    preview: CONTENTFUL_IS_PREVIEW,
  });

  const parsedKeyword = parseKeyword(data.schlagwort);
  return parsedKeyword;
};

export const useKeyword = (id: string | undefined): UseQueryResult<Keyword, unknown> => {
  return useQuery({
    queryKey: [`keyword-${id}`],
    queryFn: () => getKeyword(id),
    enabled: !!id,
  });
};
