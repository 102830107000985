import { Button, Center } from '@chakra-ui/react';
import { CloseIcon, EnvelopeIcon } from 'assets/icons/Icons';
import React, { ReactElement, useMemo } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { InputField } from './InputField';

type Props = {
  placeholder: string;
  label?: string;
  error: FieldError;
  value: string;
  register: () => UseFormRegisterReturn<string>;
  reset: () => void;
};

export const EmailInputField = ({
  label,
  placeholder,
  error,
  value,
  register,
  reset,
}: Props): ReactElement => {
  const InputIcons = useMemo((): ReactElement => {
    return (
      <Button
        bg="none"
        _focus={{ outline: 'none' }}
        onClick={() => reset()}
        pos="relative"
        right="-20px"
        _hover={{ bg: 'none' }}
        _active={{ bg: 'none' }}
      >
        <Center bg="grey.40" rounded="full" boxSize="16px">
          <CloseIcon color="bg.light" w="10px" m={0} />
        </Center>
      </Button>
    );
  }, [reset]);

  return (
    <InputField
      type="email"
      label={label}
      error={error}
      registration={register()}
      placeholder={placeholder}
      leftElement={<EnvelopeIcon color={value ? 'green.primary' : 'grey.40'} />}
      rightElement={value && InputIcons}
    />
  );
};
