import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { css } from '@emotion/react';
import Flickity from 'react-flickity-component';
import { AnimatePresence, motion } from 'framer-motion';
import { Pagination } from './Pagination';
import { SliderButton } from './SliderButton';

type Props = {
  slides: ReactElement[];

  // optional modifiers
  groupCellCount?: number;
  autoGroupCells?: boolean;
  showPagination?: boolean;
  autoplay?: boolean;
  freescroll?: boolean;
  showArrows?: boolean;

  // styling modifiers
  bgColor?: string;
  containerPadding?: string;
};

export const Slider = ({
  slides,
  groupCellCount = 1,
  autoGroupCells = false,
  showPagination = false,
  autoplay = false,
  freescroll = false,
  showArrows = false,
  bgColor = '',
  containerPadding = '10px 20px 10px',
}: Props): ReactElement => {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [flktyRef, setFlktyRef] = useState<Flickity | null>(null);
  const [totalSlideCount, setTotalSlideCount] = useState<number>(slides.length);
  const flktyHtmlRef = useRef<HTMLDivElement>(null);

  const showNextArrow = activeSlideIndex < totalSlideCount - 1 && showArrows;
  const showPreviousArrow = activeSlideIndex > 0 && showArrows;

  const onPreviousClick = (): void => {
    if (flktyRef) {
      flktyRef.previous();
    }
  };

  const onNextClick = (): void => {
    if (flktyRef) {
      flktyRef.next();
    }
  };

  useEffect(() => {
    if (!flktyRef) return undefined;
    const onDragStart = (): void => {
      if (!flktyHtmlRef.current) return undefined;
      flktyHtmlRef.current.style.pointerEvents = 'none';
    };

    const onDragEnd = (): void => {
      if (!flktyHtmlRef.current) return undefined;
      flktyHtmlRef.current.style.pointerEvents = 'all';
    };

    flktyRef.on('select', () => {
      setActiveSlideIndex(flktyRef.selectedIndex);
    });

    flktyRef.on('dragStart', onDragStart);

    flktyRef.on('dragEnd', onDragEnd);

    setTotalSlideCount(flktyRef.slides.length);

    return () => {
      flktyRef.off('select', () => {
        setActiveSlideIndex(flktyRef.selectedIndex);
      });

      flktyRef.off('dragStart', onDragStart);
      flktyRef.off('dragEnd', onDragEnd);
    };
  }, [flktyRef]);

  const selectByIndex = (index: number): void => {
    if (flktyRef) {
      flktyRef.select(index);
    }
  };

  return (
    <VStack padding="0" align="left" spacing="10px" bg={bgColor} width="100%" position="relative">
      <Box
        w="100%"
        overflowX="hidden"
        padding={containerPadding}
        css={css`
          div {
            outline: none;
          }
        `}
      >
        <Box
          position="relative"
          width="100%"
          overflowX="clip"
          overflowY="visible"
          ref={flktyHtmlRef}
        >
          <Flickity
            flickityRef={(flickity) => setFlktyRef(flickity)}
            /* 
            Force re-render when autoGroupCells changes.
            This works because react will look at the key prop to determine if it should re-render
            Keys are only compared to equal hierarchy levels within a component so multiple sliders don't interfere with each other. 
          */
            key={autoGroupCells.toString()}
            options={{
              groupCells: autoGroupCells ? '120%' : groupCellCount,
              pageDots: false,
              cellAlign: 'left',
              prevNextButtons: false,
              autoPlay: autoplay,
              freeScroll: freescroll,
              contain: true,
              draggable: true,
            }}
          >
            {slides.map((slide) => slide)}
          </Flickity>
          <AnimatePresence initial={false}>
            {showNextArrow && (
              <Box
                height="100%"
                position="absolute"
                right="0"
                top="0"
                as={motion.div}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                key={`next-${slides[0].key}`}
              >
                <SliderButton onClick={onNextClick} />
              </Box>
            )}
            {showPreviousArrow && (
              <Box
                height="100%"
                position="absolute"
                left="0"
                top="0"
                as={motion.div}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                key={`previous-${slides[0].key}`}
              >
                <SliderButton variant="previous" onClick={onPreviousClick} />
              </Box>
            )}
          </AnimatePresence>
        </Box>
      </Box>
      {showPagination && (
        <Pagination
          activeIndex={activeSlideIndex}
          totalSlideCount={totalSlideCount}
          setActiveIndex={selectByIndex}
        />
      )}
    </VStack>
  );
};
