import { InstantSearch } from 'react-instantsearch';
import { searchClient } from 'utils/algoliaSearchClient';
import { ALGOLIA_INDEX_NAME } from 'config/env';
import { ReactElement } from 'react';

type Props = {
  children: ReactElement | ReactElement[];
};

export const AlgoliaInstantSearchProvider = ({ children }: Props): ReactElement => {
  return (
    <InstantSearch indexName={ALGOLIA_INDEX_NAME} searchClient={searchClient}>
      {children}
    </InstantSearch>
  );
};
