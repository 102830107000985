import { ReactElement } from 'react';
import { VStack } from '@chakra-ui/react';
import { ProtectedRoutes } from 'types/Routes';
import { LastViewedEntry } from '@novaheal/types';
import { ArticleSlideItem } from './ArticleSlideItem';

type ArticleSlideProps = {
  items: LastViewedEntry[];
};

export const ArticleSlide = ({ items }: ArticleSlideProps): ReactElement => {
  return (
    <VStack marginRight={{ base: '20px', md: '35px' }} spacing={{ base: '15px', md: '20px' }}>
      {items.map((entry: LastViewedEntry) => (
        <ArticleSlideItem
          title={entry.title}
          image={entry.image}
          categoryTitle={entry.category?.title}
          link={`${ProtectedRoutes.ARTICLE}/${entry.id}`}
          key={entry.id}
        />
      ))}
    </VStack>
  );
};
