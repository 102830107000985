import React, { ReactElement } from 'react';
import parse from 'html-react-parser';
import { ContentSection, HtmlTagNames } from '@novaheal/types';
import { htmlToReactOptions } from 'utils/htmlToReactOptions';
import { ArticleSection } from './ArticleSection';

type Props = {
  contentSections: ContentSection[];
  contentSectionId: string;
};

export const ArticleContentSections = ({
  contentSections,
  contentSectionId,
}: Props): ReactElement => {
  return (
    <>
      {contentSections.map((section: ContentSection) => {
        return (
          <ArticleSection
            title={section.title}
            key={section.id}
            hasVideo={section.hasVideo}
            id={section.id}
            isPreopened={section.id === contentSectionId}
          >
            {parse(section.content, htmlToReactOptions)}
          </ArticleSection>
        );
      })}
    </>
  );
};
