import { Icon, IconProps, ResponsiveValue } from '@chakra-ui/react';
import { ReactElement } from 'react';

type ExtendedLogoProps = IconProps & {
  opacity?: string;
};

export const ExtendedLogo = ({ opacity = '0.6', ...rest }: ExtendedLogoProps): ReactElement => {
  return (
    <Icon viewBox="0 0 335 187" opacity={opacity} fill="none" {...rest}>
      <path
        d="M64.7657 124.009V152.485C64.7647 152.848 64.6191 153.196 64.3607 153.453C64.1023 153.709 63.7523 153.853 63.3874 153.853H58.1127C57.9108 153.853 57.7114 153.808 57.5288 153.722C57.3463 153.636 57.1849 153.511 57.0563 153.356L43.6273 137.222V152.485C43.6273 152.665 43.5917 152.842 43.5226 153.009C43.4536 153.175 43.3523 153.325 43.2246 153.452C43.097 153.58 42.9454 153.68 42.7786 153.749C42.6119 153.818 42.4331 153.853 42.2526 153.853H36.3122C36.1317 153.853 35.9529 153.818 35.7862 153.749C35.6194 153.68 35.4678 153.58 35.3402 153.452C35.2125 153.325 35.1112 153.175 35.0421 153.009C34.9731 152.842 34.9375 152.665 34.9375 152.485V124.009C34.9375 123.646 35.0823 123.298 35.3402 123.041C35.598 122.785 35.9476 122.641 36.3122 122.641H41.5905C41.7931 122.641 41.9931 122.685 42.1764 122.771C42.3596 122.857 42.5215 122.982 42.6505 123.138L56.0759 139.272V124.009C56.0759 123.829 56.1115 123.651 56.1806 123.485C56.2496 123.319 56.3509 123.169 56.4786 123.041C56.6062 122.914 56.7578 122.814 56.9246 122.745C57.0914 122.676 57.2701 122.641 57.4507 122.641H63.391C63.7556 122.641 64.1052 122.785 64.3631 123.041C64.6209 123.298 64.7657 123.646 64.7657 124.009Z"
        fill="#43B188"
      />
      <path
        d="M77.913 152.38C75.3225 151.029 73.1529 148.997 71.6392 146.506C70.1254 144.015 69.3252 141.159 69.3252 138.247C69.3252 135.336 70.1254 132.479 71.6392 129.988C73.1529 127.497 75.3225 125.465 77.913 124.114C80.6882 122.735 83.7473 122.018 86.8488 122.018C89.9503 122.018 93.0094 122.735 95.7846 124.114C98.3751 125.465 100.545 127.497 102.058 129.988C103.572 132.479 104.372 135.336 104.372 138.247C104.372 141.159 103.572 144.015 102.058 146.506C100.545 148.997 98.3751 151.029 95.7846 152.38C93.0094 153.759 89.9503 154.477 86.8488 154.477C83.7473 154.477 80.6882 153.759 77.913 152.38ZM91.1503 146.049C92.4384 145.302 93.493 144.214 94.1964 142.905C94.9313 141.462 95.3142 139.866 95.3142 138.247C95.3142 136.628 94.9313 135.033 94.1964 133.589C93.492 132.281 92.4377 131.193 91.1503 130.445C89.8366 129.713 88.3562 129.328 86.8506 129.328C85.345 129.328 83.8646 129.713 82.5509 130.445C81.2619 131.191 80.2072 132.28 79.5048 133.589C78.7677 135.032 78.3835 136.628 78.3835 138.247C78.3835 139.866 78.7677 141.462 79.5048 142.905C80.2072 144.215 81.2619 145.303 82.5509 146.049C83.8646 146.781 85.345 147.166 86.8506 147.166C88.3562 147.166 89.8366 146.781 91.1503 146.049Z"
        fill="#43B188"
      />
      <path
        d="M138.839 124.549L126.578 153.021C126.473 153.268 126.297 153.478 126.073 153.626C125.848 153.774 125.585 153.853 125.316 153.853H118.395C118.125 153.853 117.862 153.774 117.637 153.626C117.412 153.479 117.235 153.268 117.129 153.021L104.912 124.546C104.823 124.338 104.787 124.111 104.807 123.886C104.828 123.661 104.904 123.444 105.029 123.256C105.154 123.067 105.324 122.912 105.524 122.805C105.724 122.697 105.947 122.641 106.174 122.641H112.762C113.034 122.64 113.3 122.72 113.526 122.87C113.752 123.02 113.928 123.233 114.032 123.483L122.19 142.974L130.511 123.473C130.617 123.226 130.794 123.015 131.019 122.868C131.244 122.72 131.507 122.641 131.777 122.641H137.565C137.794 122.64 138.018 122.695 138.22 122.802C138.421 122.909 138.592 123.064 138.719 123.254C138.845 123.443 138.922 123.66 138.943 123.886C138.964 124.113 138.928 124.34 138.839 124.549Z"
        fill="#43B188"
      />
      <path
        d="M134.599 151.945L146.856 123.469C146.962 123.223 147.139 123.014 147.363 122.867C147.587 122.72 147.85 122.641 148.118 122.641H155.039C155.309 122.641 155.572 122.72 155.798 122.868C156.023 123.015 156.199 123.226 156.305 123.473L168.522 151.945C168.611 152.153 168.647 152.38 168.627 152.605C168.606 152.83 168.53 153.047 168.405 153.236C168.28 153.425 168.11 153.58 167.91 153.688C167.711 153.795 167.487 153.852 167.26 153.853H160.672C160.4 153.853 160.135 153.773 159.909 153.623C159.683 153.473 159.507 153.26 159.402 153.011L151.244 133.52L142.923 153.018C142.818 153.266 142.642 153.477 142.417 153.625C142.192 153.774 141.927 153.853 141.657 153.853H135.869C135.641 153.854 135.416 153.799 135.214 153.692C135.013 153.585 134.842 153.43 134.716 153.24C134.59 153.051 134.513 152.833 134.493 152.607C134.472 152.381 134.509 152.153 134.599 151.945Z"
        fill="#43B188"
      />
      <path
        d="M204.346 124.009V152.485C204.346 152.848 204.201 153.196 203.943 153.452C203.685 153.709 203.336 153.853 202.971 153.853H196.853C196.673 153.853 196.494 153.818 196.327 153.749C196.161 153.68 196.009 153.58 195.881 153.452C195.754 153.325 195.652 153.175 195.583 153.009C195.514 152.842 195.479 152.665 195.479 152.485V141.681H183.385V152.485C183.385 152.665 183.349 152.842 183.28 153.009C183.211 153.175 183.11 153.325 182.982 153.452C182.854 153.58 182.703 153.68 182.536 153.749C182.369 153.818 182.19 153.853 182.01 153.853H175.892C175.712 153.853 175.533 153.818 175.366 153.749C175.199 153.68 175.048 153.58 174.92 153.452C174.793 153.325 174.691 153.175 174.622 153.009C174.553 152.842 174.518 152.665 174.518 152.485V124.009C174.518 123.646 174.662 123.298 174.92 123.041C175.178 122.785 175.528 122.641 175.892 122.641H182.01C182.375 122.641 182.724 122.785 182.982 123.041C183.24 123.298 183.385 123.646 183.385 124.009V134.367H195.479V124.009C195.479 123.646 195.624 123.298 195.881 123.041C196.139 122.785 196.489 122.641 196.853 122.641H202.971C203.336 122.641 203.685 122.785 203.943 123.041C204.201 123.298 204.346 123.646 204.346 124.009Z"
        fill="#43B188"
      />
      <path
        d="M235.791 148.397V152.485C235.791 152.848 235.646 153.196 235.388 153.452C235.13 153.709 234.781 153.853 234.416 153.853H211.986C211.621 153.853 211.272 153.709 211.014 153.452C210.756 153.196 210.611 152.848 210.611 152.485V124.009C210.611 123.646 210.756 123.298 211.014 123.041C211.272 122.785 211.621 122.641 211.986 122.641H233.826C234.191 122.641 234.541 122.785 234.798 123.041C235.056 123.298 235.201 123.646 235.201 124.009V128.093C235.201 128.456 235.056 128.804 234.798 129.061C234.541 129.317 234.191 129.462 233.826 129.462H219.41V134.723H231.963C232.328 134.723 232.677 134.867 232.935 135.124C233.193 135.381 233.338 135.729 233.338 136.092V139.956C233.338 140.319 233.193 140.667 232.935 140.923C232.677 141.18 232.328 141.324 231.963 141.324H219.41V147.029H234.427C234.79 147.032 235.136 147.177 235.392 147.433C235.647 147.69 235.791 148.036 235.791 148.397Z"
        fill="#43B188"
      />
      <path
        d="M262.823 147.789H249.575L247.462 152.997C247.361 153.254 247.185 153.474 246.957 153.63C246.728 153.786 246.458 153.871 246.181 153.872H240.172C239.943 153.872 239.718 153.815 239.517 153.706C239.316 153.597 239.145 153.44 239.021 153.249C238.896 153.058 238.821 152.839 238.803 152.612C238.785 152.385 238.824 152.157 238.917 151.949L251.543 123.477C251.65 123.233 251.826 123.027 252.05 122.881C252.274 122.736 252.535 122.659 252.802 122.659H259.744C260.01 122.66 260.271 122.737 260.494 122.881C260.718 123.026 260.894 123.231 261.003 123.473L273.665 151.949C273.758 152.157 273.797 152.385 273.779 152.612C273.761 152.839 273.686 153.058 273.561 153.249C273.436 153.44 273.266 153.597 273.065 153.706C272.864 153.815 272.639 153.872 272.41 153.872H266.223C265.949 153.872 265.682 153.79 265.455 153.638C265.228 153.485 265.052 153.268 264.95 153.015L262.823 147.789ZM260.236 141.267L256.206 131.292L252.172 141.267H260.236Z"
        fill="#43B188"
      />
      <path
        d="M278.408 122.641H284.529C284.894 122.641 285.243 122.785 285.501 123.041C285.759 123.298 285.904 123.646 285.904 124.009V146.852H299.488C299.853 146.852 300.203 146.997 300.461 147.253C300.718 147.51 300.863 147.858 300.863 148.221V152.485C300.863 152.665 300.828 152.842 300.759 153.009C300.689 153.175 300.588 153.325 300.461 153.452C300.333 153.58 300.181 153.68 300.015 153.749C299.848 153.818 299.669 153.853 299.488 153.853H278.408C278.227 153.853 278.049 153.818 277.882 153.749C277.715 153.68 277.564 153.58 277.436 153.452C277.308 153.325 277.207 153.175 277.138 153.009C277.069 152.842 277.033 152.665 277.033 152.485V124.009C277.033 123.646 277.178 123.298 277.436 123.041C277.694 122.785 278.043 122.641 278.408 122.641Z"
        fill="#43B188"
      />
      <path
        d="M146.737 85.6625C145.535 87.2811 143.969 88.5966 142.164 89.5035C140.359 90.4104 138.365 90.8833 136.343 90.8844C125.733 90.8844 119.637 78.856 125.946 70.3569L140.797 50.3623C141.038 54.5773 142.585 58.6145 145.225 61.919C146.18 63.2082 150.42 68.9235 154.797 74.8117L146.737 85.6625ZM206.43 58.9515C206.213 59.2648 186.261 86.091 186.018 86.3828C185.116 87.7536 183.884 88.8793 182.436 89.6583C180.987 90.4374 179.366 90.8453 177.719 90.8453C176.073 90.8453 174.452 90.4374 173.003 89.6583C171.554 88.8793 170.323 87.7536 169.42 86.3828C169.174 86.091 149.226 59.2648 149.009 58.9515C147.143 56.6007 145.982 53.7732 145.659 50.7944C145.336 47.8156 145.864 44.8065 147.184 42.1136C148.503 39.4206 150.559 37.1531 153.116 35.5721C155.672 33.9911 158.625 33.1608 161.635 33.1768C164.051 33.174 166.436 33.7133 168.614 34.7545C170.792 35.7957 172.706 37.3121 174.214 39.191C175.722 40.6927 182.788 50.8773 183.482 51.7956C183.865 52.2726 184.419 52.583 185.027 52.6613C185.636 52.7396 186.251 52.5798 186.743 52.2155C187.235 51.8512 187.566 51.311 187.665 50.7086C187.765 50.1062 187.626 49.4889 187.277 48.9866C186.456 47.9062 181.851 41.5391 180.65 39.9149C180.65 39.9149 180.668 39.8897 180.671 39.8897C182.646 37.1305 185.448 35.0657 188.675 33.9919C191.903 32.9181 195.389 32.8907 198.633 33.9137C201.877 34.9366 204.712 36.9572 206.73 39.685C208.748 42.4128 209.845 45.7072 209.863 49.0946C209.87 52.6732 208.657 56.1481 206.422 58.9515H206.43Z"
        fill="#43B188"
      />
    </Icon>
  );
};
