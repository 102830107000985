import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const FilesIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.399 7.00817V6.0001V2.93343C18.399 2.19705 17.802 1.6001 17.0656 1.6001H13.999H5.59896C4.86258 1.6001 4.26562 2.19705 4.26562 2.93343V19.7334C4.26562 20.4698 4.86258 21.0668 5.59896 21.0668H7.68646V20.0001H5.59896C5.45168 20.0001 5.33229 19.8807 5.33229 19.7334V2.93343C5.33229 2.78616 5.45168 2.66676 5.59896 2.66676H13.999H17.0656C17.2129 2.66676 17.3323 2.78616 17.3323 2.93343V5.9415L17.6865 6.29567L18.399 7.00817Z"
        fill="currentColor"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M19.1542 8.51665V21.7624H8.22083V5.49574H16.1333L17.3104 6.67286L19.1542 8.51665Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.06667"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M15.4258 5.6123L15.4243 8.97961C15.4242 9.12673 15.5433 9.24611 15.6905 9.24639L19.0052 9.25265"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.06667"
      />
    </Icon>
  );
};
