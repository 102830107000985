import { ReactElement } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { Breakpoints } from 'types/breakpoints';
import { PaymentInterval } from '@novaheal/types';
import { PromoCode } from 'types/PromoCode';
import { SubscriptionButton } from './SubscriptionButton';

type Props = {
  activeButton: PaymentInterval;
  onClick: (subscription: PaymentInterval) => void;
  onSubscribe: () => void;
  isLoading: boolean;
  subscriptionPrices: any[];
  promoCode?: PromoCode;
};

const PaymentIntervalToPackageType = (paymentInterval: PaymentInterval): 'yearly' | 'monthly' => {
  switch (paymentInterval) {
    case PaymentInterval.YEARLY:
      return 'yearly';
    case PaymentInterval.MONTHLY:
      return 'monthly';
    default:
      return 'monthly';
  }
};

const calculatePriceWithDiscount = (
  basePrice: number,
  discount: PromoCode['value'] | undefined,
  packageType: PaymentInterval,
  promoCode: PromoCode | undefined
): number => {
  // early out, if a pkg limitation is set and the current pkg does not match
  if (promoCode?.package && promoCode.package !== PaymentIntervalToPackageType(packageType))
    return basePrice;
  if (!discount) return basePrice;

  if (discount.unit === 'percent') {
    return Math.max(basePrice - (basePrice * discount.amount) / 100, 0);
  }

  return Math.max(basePrice - discount.amount, 0);
};

export const SubscriptionButtons = ({
  activeButton,
  onClick,
  onSubscribe,
  isLoading,
  subscriptionPrices,
  promoCode,
}: Props): ReactElement => {
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);

  const getSubscriptionButtonConfig = (
    subscriptionPrice: any
  ): {
    interval: PaymentInterval;
    duration: string;
    additionalInfo: string | undefined;
    pricePerMonth: string;
    price: string | undefined;
    oldMonthlyPricing: string | undefined;
    savedPercentage: number | undefined;
    hasPromoCode: boolean;
  } => {
    const variant = subscriptionPrice.interval;

    let duration;
    let additionalInfo;
    let pricePerMonth;
    let price;
    let oldMonthlyPricing;
    let savedPercentage;
    const monthlyBasePrice = 14.99;
    const monthlyPrice = Number(Number(subscriptionPrice.price).toFixed(2));
    const monthlyPriceWithDiscount = calculatePriceWithDiscount(
      monthlyPrice,
      promoCode?.value,
      variant,
      promoCode
    );
    const monthlySavedPercentage = Math.floor(
      ((monthlyBasePrice - monthlyPriceWithDiscount) / monthlyBasePrice) * 100
    );

    const yearlyBasePricePerMonth = 10.99;
    const yearlyPriceWithDiscount = calculatePriceWithDiscount(
      subscriptionPrice.price,
      promoCode?.value,
      variant,
      promoCode
    );
    const yearlyPricePerMonth = Math.floor((yearlyPriceWithDiscount / 12) * 100) / 100;
    const yearlySavedPercentage = Math.floor(
      ((yearlyBasePricePerMonth - yearlyPricePerMonth) / yearlyBasePricePerMonth) * 100
    );

    // Fall back to compare yearly sub price vs monthly sub
    const yearlyFallbackSavedPercentage = Math.floor(
      ((monthlyBasePrice - yearlyPricePerMonth) / monthlyBasePrice) * 100
    );

    let hasPromoCode = false;

    switch (variant) {
      case PaymentInterval.MONTHLY:
        duration = '1 Monat';
        pricePerMonth = `${monthlyPriceWithDiscount.toFixed(2)} €/Monat`;
        oldMonthlyPricing = `${monthlyBasePrice} €/Monat`;
        savedPercentage = monthlySavedPercentage || undefined;
        hasPromoCode = promoCode?.package === 'monthly';
        break;
      case PaymentInterval.YEARLY:
        duration = '12 Monate';
        additionalInfo = 'Zahlung jährlich. Jederzeit kündbar.';
        pricePerMonth = `${yearlyPricePerMonth.toFixed(2)} €/Monat`;
        price = `${yearlyPriceWithDiscount.toFixed(2)} €`;
        oldMonthlyPricing = `${yearlyBasePricePerMonth} €/Monat`;

        savedPercentage =
          promoCode?.package === 'yearly'
            ? yearlySavedPercentage || undefined
            : yearlyFallbackSavedPercentage;
        hasPromoCode = promoCode?.package === 'yearly';
        break;
      case PaymentInterval.BIWEEKLY:
        duration = '2 Tage';
        additionalInfo = 'Dies ist ein Testprodukt. Nur zum internen Gebrauch.';
        pricePerMonth = `${Number(2 * subscriptionPrice.price).toFixed(2)} €/Monat`;
        price = `${subscriptionPrice.price} €`;
        break;
      default:
        duration = 'Unknown duration';
        pricePerMonth = '';
    }

    return {
      interval: variant,
      duration,
      additionalInfo,
      pricePerMonth,
      price,
      oldMonthlyPricing,
      savedPercentage,
      hasPromoCode,
    };
  };

  const subscriptionConfigs = subscriptionPrices
    ? subscriptionPrices.map(getSubscriptionButtonConfig)
    : [];

  return (
    <Flex gap="25px" width="100%" flexDirection="column">
      <Flex gap="15px" width="100%" flexDirection="column">
        {isDesktop && <Text color="grey.textPrimary">Wähle deinen Plan:</Text>}
        {subscriptionConfigs.map((config) => (
          <SubscriptionButton
            key={config.interval}
            isActive={config.interval === activeButton}
            onClick={() => onClick(config.interval)}
            duration={config.duration}
            pricePerMonth={config.pricePerMonth}
            oldMonthlyPricing={config.oldMonthlyPricing}
            additionalInfo={config.additionalInfo}
            savedPercentage={config.savedPercentage}
            hasPromoCode={config.hasPromoCode}
          />
        ))}
      </Flex>
      {isDesktop && (
        <Button
          variant="primary"
          width="220px"
          alignSelf="center"
          onClick={onSubscribe}
          isLoading={isLoading}
        >
          Abonnieren
        </Button>
      )}
    </Flex>
  );
};
