import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const AgegroupChildrenIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <rect
        x="2.08438"
        y="1.15762"
        width="19.8312"
        height="21.6847"
        rx="3.4"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M8.76 17C8.64267 17 8.552 16.968 8.488 16.904C8.424 16.8293 8.392 16.7387 8.392 16.632V6.184C8.392 6.06667 8.424 5.976 8.488 5.912C8.552 5.83733 8.64267 5.8 8.76 5.8H9.56C9.67733 5.8 9.768 5.83733 9.832 5.912C9.90667 5.976 9.944 6.06667 9.944 6.184V10.152L14.216 6.056C14.2587 6.01333 14.328 5.96 14.424 5.896C14.5307 5.832 14.6693 5.8 14.84 5.8H15.704C15.7893 5.8 15.864 5.832 15.928 5.896C15.992 5.96 16.024 6.03467 16.024 6.12C16.024 6.20533 16.0027 6.27467 15.96 6.328L11.016 11.16L16.28 16.456C16.3333 16.52 16.36 16.5947 16.36 16.68C16.36 16.7653 16.328 16.84 16.264 16.904C16.2 16.968 16.1253 17 16.04 17H15.144C14.9627 17 14.824 16.968 14.728 16.904C14.632 16.8293 14.5627 16.776 14.52 16.744L9.944 12.232V16.632C9.944 16.7387 9.90667 16.8293 9.832 16.904C9.768 16.968 9.67733 17 9.56 17H8.76Z"
        fill="currentColor"
      />
    </Icon>
  );
};
