import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const CloseIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M19.4717 7.59031C19.9699 7.09203 19.9699 6.28415 19.4717 5.78587C18.9734 5.28759 18.1655 5.28759 17.6672 5.78587L12.2539 11.1992L6.84059 5.78587C6.34231 5.28759 5.53443 5.28759 5.03615 5.78587C4.53787 6.28415 4.53787 7.09203 5.03615 7.59031L10.4495 13.0036L5.03615 18.4169C4.53787 18.9152 4.53787 19.7231 5.03615 20.2214C5.53443 20.7197 6.34231 20.7197 6.84059 20.2214L12.2539 14.8081L17.6672 20.2214C18.1655 20.7197 18.9734 20.7197 19.4717 20.2214C19.9699 19.7231 19.9699 18.9152 19.4717 18.4169L14.0583 13.0036L19.4717 7.59031Z"
        fill="currentColor"
      />
    </Icon>
  );
};
