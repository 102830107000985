import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const ExclamationTriangleIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M11.7118 3.09926C11.7844 2.98008 11.9571 2.97915 12.031 3.09754L22.057 19.1634C22.1349 19.2883 22.0451 19.4502 21.8979 19.4502H12.0009H2.08508C1.93877 19.4502 1.84884 19.2901 1.92495 19.1651L11.7118 3.09926Z"
        stroke="currentColor"
        strokeWidth="1.125"
        fill="transparent"
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="12"
        fontWeight="bold"
        fill="currentColor"
      >
        !
      </text>
    </Icon>
  );
};
