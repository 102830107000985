import { ContentSection, SearchResultContentSection } from '@novaheal/types';

/**
 * It takes an array of ContentSection objects, and returns the sum of the readingTime property of each
 * object. The initial value is used to add 30 seconds extra per section, as the user needs to get comfortable with the
 * content.
 * @param {ContentSection[] | SearchResultContentSection[]} sections - ContentSection[] |
 * SearchResultContentSection[]
 * @returns The reading time of the content sections.
 */
export const getReadingTime = (
  sections: ContentSection[] | SearchResultContentSection[]
): number => {
  // some sections have a readingTime which is undefined - this would lead to the whole func returning undefined
  return sections
    .map((section: ContentSection | SearchResultContentSection) => section.readingTime)
    .reduce(
      (a: number | undefined, b: number | undefined) => (a ?? 0) + (b ?? 0),
      Math.round(sections.length * 0.5)
    );
};
