import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const TickIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M9.51925 15.7978L3.59713 10.1999L1.30078 12.5013L9.51997 20.3999L23.9972 6.13138L21.6981 3.59985L9.51925 15.7978Z"
        fill="currentColor"
      />
    </Icon>
  );
};
