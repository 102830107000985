import { Button, Image, Text, VStack } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import ErrorImage from 'assets/subscription/error.webp';

export const renderError = ({ error, resetErrorBoundary }: FallbackProps): ReactElement => (
  <Error error={error} resetErrorBoundary={resetErrorBoundary} />
);

const Error = ({ error, resetErrorBoundary }: FallbackProps): ReactElement => {
  const navigate = useNavigate();
  const [pathname, setPathname] = useState(window.location.pathname);

  const handleReset = (): void => {
    resetErrorBoundary();
  };

  const handleNavigate = (): void => {
    navigate(-1);
    resetErrorBoundary();
  };

  useEffect(() => {
    if (window.location.pathname !== pathname) {
      resetErrorBoundary();
    }
    setPathname(window.location.pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetErrorBoundary, pathname, window.location.pathname]);

  return (
    <VStack spacing={5} mt="10" px="20px">
      <Image src={ErrorImage} />
      <Text textStyle="h1" align="center" color="blue.textHeade">
        Oops!
      </Text>
      <Text align="center" textStyle="h4.reg" color="grey.30">
        Etwas ist schiefgelaufen. Bitte überprüfen Sie die Fehlermeldung in Rot unten:
      </Text>
      <Text align="center" textStyle="h5" color="system.error">
        {error.message}
      </Text>
      <Button onClick={handleReset} type="button" variant="primary" w="full" maxWidth="350px">
        Versuchs noch Einmal
      </Button>
      <Button onClick={handleNavigate} type="button" variant="secondary" w="full" maxWidth="350px">
        Zurück
      </Button>
    </VStack>
  );
};
