import { extendTheme } from '@chakra-ui/react';

// Global Styles
import { colors } from './colors';
import { textStyles } from './textStyles';
import { breakpoints } from './breakpoints';

// Component Styles
import { ButtonStyles as Button } from '../components/buttonStyles';
import { ToastStyles as Toast } from '../components/toastStyles';
import { CheckboxStyles as Checkbox } from '../components/checkboxStyles';
import { InputStyles as Input } from '../components/inputStyles';
import { SelectStyles as Select } from '../components/selectStyles';
import { AccordionStyles as Accordion } from '../components/accordionstyles';
import { container } from './container';

export const theme = extendTheme({
  colors,
  textStyles,
  breakpoints,
  container,
  components: {
    Button,
    Toast,
    Checkbox,
    Input,
    Select,
    Accordion,
  },
  fonts: {
    heading: 'Montserrat, sans-serif',
    body: 'Montserrat, sans-serif',
  },
});
