import { Stack, Spinner } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const PageSpinner = (): ReactElement => {
  return (
    <Stack align="center" height="100vh" display="flex" justifyContent="center" alignItems="center">
      <Spinner
        m="10%"
        thickness="7px"
        speed="1s"
        emptyColor="grey.10"
        color="green.primary"
        size="xl"
      />
    </Stack>
  );
};
