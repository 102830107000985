import React, { ReactElement } from 'react';

export const ChatbotWidget = (): ReactElement => {
  return (
    <chatbot-toggle-widget
      position="fixed"
      right="10"
      bottom="60"
      widgetHeight="60"
      widgetWidth="60"
      chatbotHeight="600"
      chatbotWidth="400"
      baseUrl="https://app.dialogbits.cloud/main/virtual-agent/chatbot/app/novaheal/novaheal-ai/production/public"
    />
  );
};
