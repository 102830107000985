import { Box, Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

type AccordionArrowIconProps = IconProps & {
  isExpanded?: boolean;
};

export const AccordionArrowIcon = ({
  isExpanded,
  ...props
}: AccordionArrowIconProps): ReactElement => {
  const rotateDegree = isExpanded ? '180deg' : '0deg';

  return (
    <Box display="inline-flex" alignItems="center" justifyContent="center">
      <Box
        transform={`rotate(${rotateDegree})`}
        transition="transform 0.2s"
        transformOrigin="center"
      >
        <Icon viewBox="0 0 24 24" {...props}>
          <path
            d="M9.00073 10.1338C9.34795 10.1338 9.64565 10.0031 9.90363 9.72147L17.4642 1.89748C17.6825 1.67625 17.8015 1.39469 17.8015 1.06284C17.8015 0.399061 17.2263 0.181289 16.6961 0.202435C16.3379 0.216716 16.0056 0.53084 15.7775 0.752072L9.00073 7.8017L2.22396 0.752072C1.99575 0.53084 1.68331 0.294879 1.3658 0.294879C0.70103 0.294879 0.283727 0.538818 0.199864 1.06284C0.144309 1.40999 0.318929 1.67625 0.537214 1.89748L8.08787 9.72147C8.35576 10.0031 8.65342 10.1338 9.00073 10.1338Z"
            fill="currentColor"
          />
        </Icon>
      </Box>
    </Box>
  );
};
