import { ReactElement } from 'react';
import { Layout } from 'components/layouts/Layout';
import { AppRoutes } from './routes/AppRoutes';

const App = (): ReactElement => {
  return (
    <Layout>
      <AppRoutes />
    </Layout>
  );
};

export default App;
