import * as Sentry from '@sentry/react';
import { GraphQLClient, Variables } from 'graphql-request';
import {
  CONTENTFUL_URL,
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_IS_PREVIEW,
} from 'config/env';
import { AxiosError } from 'axios';

function isAxiosError(error: unknown): error is AxiosError {
  return (
    error !== null &&
    typeof error === 'object' &&
    'isAxiosError' in error &&
    error.isAxiosError === true
  );
}

const gqlUrl = `${CONTENTFUL_URL}/environments/${CONTENTFUL_ENVIRONMENT}`;

/**
 * Represents a client to interact with Contentful using GraphQL queries. It expands the GraphQLClient with retry policy and enables customized logging
 */
class ContentfulClient {
  /**
   * @private
   * @type {GraphQLClient} The internal GraphQL client used for making requests.
   */
  private client: GraphQLClient;

  /**
   * Creates a new instance of the ContentfulClient.
   * @param {string} baseURL - The base URL of the GraphQL endpoint.
   * @param {Record<string, string>} headers - Any headers to be added to each request.
   */
  constructor(baseURL: string, headers: Record<string, string>) {
    this.client = new GraphQLClient(baseURL, { headers });
  }

  /**
   * @private
   * Delays the execution for a specific number of milliseconds.
   * @param {number} ms - The number of milliseconds to delay.
   * @returns {Promise<void>} A promise that resolves after the delay.
   */
  private static async delay(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  /**
   * @private
   * Sends a GraphQL request and retries if it fails.
   * @template T - The type of the expected response data.
   * @param {Promise<T>} requestPromise - The promise representing the GraphQL request.
   * @param {Object} options - Configuration options for retrying the request.
   * @param {number} [options.retries=3] - Number of times to retry the request if it fails.
   * @param {number} [options.everyMs=1000] - Delay between retries in milliseconds.
   * @param {number} [retriesCount=0] - The current retry count.
   * @returns {Promise<T | null>} A promise resolving with the response data or null in case of failure after all retries.
   */
  private async requestWithRetry<T>(
    requestPromise: Promise<T>,
    { retries = 3, sleepTime = 500 } = {},
    retriesCount = 0
  ): Promise<T | null> {
    try {
      return await requestPromise;
    } catch (e) {
      // If we get a 429 error, we want to log it as a warning, as it will cause a retry an didn't break the app yet
      if (isAxiosError(e) && e.response?.status === 429) {
        Sentry.captureException(e, {
          level: 'warning',
        });
      }

      const updatedCount = retriesCount + 1;
      if (updatedCount > retries) {
        // If we run out of retries, we want to log all errors as an actual error, as it broke the app
        Sentry.captureException(e, {
          level: 'error',
        });
        return null;
      }
      await ContentfulClient.delay(sleepTime);
      return this.requestWithRetry(requestPromise, { retries, sleepTime }, updatedCount);
    }
  }

  /**
   * Send a GraphQL document to the server.
   * @template T - The type of the expected response data.
   * @param {string} query - The GraphQL query or mutation.
   * @param {Variables} [variables] - The variables to be used in the query or mutation.
   * @returns {Promise<T | null>} A promise resolving with the response data or null in case of failure after all retries.
   */
  async request<T>(query: string, variables?: Variables | undefined): Promise<T | null> {
    return this.requestWithRetry(this.client.request<T>(query, variables));
  }
}

export const contentfulClient = new ContentfulClient(gqlUrl, {
  authorization: `Bearer ${
    CONTENTFUL_IS_PREVIEW ? CONTENTFUL_PREVIEW_ACCESS_TOKEN : CONTENTFUL_ACCESS_TOKEN
  }`,
});
