import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const InfusionIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_10416_122091)">
        <rect
          x="14.6172"
          y="23.3547"
          width="3.44617"
          height="0.622917"
          rx="0.243457"
          fill="currentColor"
        />
        <rect x="15.5938" y="20.7207" width="1.54839" height="2.33149" fill="currentColor" />
        <rect
          x="13.7617"
          y="19.4216"
          width="5.12571"
          height="1.03226"
          rx="0.516131"
          fill="currentColor"
        />
        <path
          d="M15.3578 9.88188C15.3578 9.60458 15.5826 9.37979 15.8599 9.37979H16.8123C17.0896 9.37979 17.3144 9.60458 17.3144 9.88189V18.7914H15.3578V9.88188Z"
          stroke="currentColor"
          fill="none"
          strokeWidth="0.7"
        />
        <path
          d="M15.918 8.24907C15.918 8.11461 16.027 8.00562 16.1614 8.00562H16.5394C16.6739 8.00562 16.7829 8.11461 16.7829 8.24907V8.72297H15.918V8.24907Z"
          fill="currentColor"
        />
        <path
          d="M16.0977 1.13661C16.0977 1.00588 16.2036 0.899902 16.3344 0.899902C16.4651 0.899902 16.5711 1.00588 16.5711 1.13661V4.29926V7.69862H16.0977V1.13661Z"
          fill="currentColor"
        />
        <rect x="16.1523" y="11.0378" width="1.26367" height="0.5" fill="currentColor" />
        <rect x="16.1523" y="12.8379" width="1.26367" height="0.5" fill="currentColor" />
        <rect x="16.1523" y="14.6379" width="1.26367" height="0.5" fill="currentColor" />
        <rect x="16.1523" y="16.4377" width="1.26367" height="0.5" fill="currentColor" />
        <path
          d="M7.99667 1.9021C7.37246 3.51539 7.47843 6.99072 7.23659 9.53743C7.19117 10.0157 7.85847 10.2837 7.99667 10.5048C8.13486 10.7259 7.8604 11.1178 7.47956 11.2605C7.45492 11.2697 7.41287 11.27 7.41287 11.27"
          stroke="currentColor"
          strokeWidth="0.7"
        />
        <path
          d="M10.2288 11.2649C10.2288 11.2649 10.2144 11.2651 10.2052 11.2649C9.84598 11.2574 9.54873 10.7259 9.68693 10.5048C9.82512 10.2837 10.4924 10.0157 10.447 9.53743C10.2052 6.99072 10.2052 3.65173 9.68693 1.9021C9.422 1.15963 8.70223 0.125939 7.99621 1.90786"
          stroke="currentColor"
          fill="none"
          strokeWidth="0.7"
        />
        <path
          d="M7.42448 11.2703V11.2703C6.76825 11.2523 6.22656 11.7794 6.22656 12.4359V23.0023C6.22656 23.541 6.6633 23.9777 7.20205 23.9777H10.4796C11.0184 23.9777 11.4551 23.541 11.4551 23.0023V12.49C11.4551 11.8028 10.89 11.2501 10.2029 11.2646V11.2646"
          stroke="currentColor"
          fill="none"
          strokeWidth="0.7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1262 13.0012H6.57031V16.0562H11.1262V13.0012ZM11.1262 19.404H6.57031V23.0024C6.57031 23.5411 7.00705 23.9779 7.5458 23.9779H10.1507C10.6895 23.9779 11.1262 23.5411 11.1262 23.0024V19.404Z"
          fill="currentColor"
        />
        <path
          d="M8.97618 16.8269H8.72104C8.6014 16.8269 8.50441 16.923 8.50441 17.0416V17.3888H8.15413C8.03449 17.3888 7.9375 17.485 7.9375 17.6035V17.8564C7.9375 17.975 8.03449 18.0712 8.15413 18.0712H8.50441V18.4184C8.50441 18.5369 8.6014 18.6331 8.72104 18.6331H8.97618C9.09581 18.6331 9.1928 18.5369 9.1928 18.4184V18.0712H9.54308C9.66272 18.0712 9.75971 17.975 9.75971 17.8564V17.6035C9.75971 17.485 9.66272 17.3888 9.54308 17.3888H9.1928V17.0416C9.1928 16.923 9.09581 16.8269 8.97618 16.8269Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10416_122091">
          <rect width="24" height="24" fill="white" transform="translate(0.558594 0.449707)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
