import { ReactElement } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { useIsScrolled } from 'hooks/utils/useIsScrolled';
import { AnimatedHeartIcon, ArrowIcon, ShareIOSIcon } from 'assets/icons/Icons';
import { Z_INDEX } from 'styles/zIndex';
import { useCurrentRoute } from 'hooks/utils/useCurrentRoute';
import { useLocation } from 'react-router-dom';
import { ProtectedRoutes } from 'types/Routes';
import { useFirebase } from 'hooks/useFirebase';

type TopBarSecondaryProps = {
  title?: string;
  hasShareButton?: boolean;
  hasLikeButton?: boolean;
  isLiked?: boolean | null;
  handleLikeChange?: () => void;
  shareData?: ShareData;
  onBackClick: () => void;
};

export const TopBarSecondary = ({
  title,
  onBackClick,
  hasLikeButton = false,
  handleLikeChange,
  isLiked = false,
  shareData,
  hasShareButton = false,
}: TopBarSecondaryProps): ReactElement => {
  const location = useLocation();
  const { currentRoute } = useCurrentRoute(location);
  const { logFirebaseClick } = useFirebase();
  const scrollThresholdInPx = 100;
  const isFloating = useIsScrolled(scrollThresholdInPx);
  const share = (): void => {
    if (navigator.share && shareData) {
      const isMedication = currentRoute === ProtectedRoutes.MEDICATION;
      logFirebaseClick('share', shareData.title || 'missing title', {
        contentType: isMedication ? 'medication' : 'article',
      });
      navigator.share(shareData);
    }
  };

  const isLikeLoaded = isLiked !== null;
  const shouldShowLikeButton = isLikeLoaded && hasLikeButton;

  return (
    <Box
      top="0"
      w="full"
      display="flex"
      justifyContent="space-between"
      h="calc(env(safe-area-inset-top) + 50px)"
      pt="env(safe-area-inset-top)"
      zIndex={Z_INDEX.NAVBAR}
      bg={isFloating ? '#fff' : 'none'}
      px="3"
      boxShadow={isFloating ? '0px 0px 4px rgba(0, 0, 0, 0.1)' : 'none'}
    >
      <Box pos="relative" w="28px" onClick={onBackClick} cursor="pointer">
        <Box
          bg="bg.light"
          w="28px"
          h="28px"
          borderRadius="100%"
          background="bg.light"
          pos="relative"
          top="50%"
          filter={isFloating ? '' : 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.13))'}
          transform="translate(0%, -50%)"
          _hover={{
            cursor: 'pointer',
          }}
        />
        <ArrowIcon
          w="18px"
          h="18px"
          color="grey.secondary"
          opacity="1"
          pos="absolute"
          top="50%"
          left="calc(50% - 1px)"
          transform="translate(-50%, -50%)"
        />
      </Box>
      {isFloating && (
        <Box display="flex" justifyContent="center" w="100%" alignItems="center" mx="2">
          <Text as="h3" textStyle="h3" color="grey.secondary" noOfLines={1}>
            {title}
          </Text>
        </Box>
      )}
      <HStack spacing="5">
        {hasShareButton && (
          <Box
            pos="relative"
            w="28px"
            onClick={share}
            visibility={['visible', 'hidden']}
            _hover={{
              cursor: 'pointer',
            }}
          >
            <Box
              w="28px"
              bg="bg.light"
              h="28px"
              borderRadius="100%"
              background="bg.light"
              filter={isFloating ? '' : 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.13))'}
            />
            <ShareIOSIcon
              color="grey.secondary"
              opacity="1"
              pos="absolute"
              top="50%"
              left="calc(50% - 1px)"
              transform="translate(-50%, -50%)"
            />
          </Box>
        )}
        {shouldShowLikeButton && (
          <Box
            pos="relative"
            w="28px"
            onClick={handleLikeChange}
            _hover={{
              cursor: 'pointer',
            }}
          >
            <Box
              bg="bg.light"
              w="28px"
              h="28px"
              borderRadius="100%"
              background="bg.light"
              filter={isFloating ? '' : 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.13));'}
            />

            <AnimatedHeartIcon
              checked={isLiked}
              color="grey.secondary"
              pos="absolute"
              top="calc(50% + 1px)"
              left="50%"
              transform="translate(-50%, -50%)"
            />
          </Box>
        )}
      </HStack>
    </Box>
  );
};
