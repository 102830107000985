import { axios } from 'utils/axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const addLikedEntry = async (articleId: string): Promise<void> => {
  await axios.patch(`user/entry-view`, {
    ctfId: articleId,
  });
};

export const useAddLastViewedEntry = (): UseMutationResult<void, unknown, string, unknown> => {
  return useMutation({
    mutationFn: addLikedEntry,
  });
};
