import { Box, VStack, HStack, Text, Image, Center } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import Placeholder from 'assets/common/placeholder.png';
import { ReactElement } from 'react';
import { CtfImage } from '@novaheal/types';
import { ArrowIcon } from 'assets/icons/Icons';
import { useCurrentRoute } from 'hooks/utils/useCurrentRoute';
import { ProtectedRoutes } from 'types/Routes';
import { useFirebase } from 'hooks/useFirebase';

export type ArticleSlideItemProps = {
  title: string;
  image: CtfImage;
  categoryTitle?: string | null;
  link: string;
};

export const ArticleSlideItem = ({
  title,
  image,
  categoryTitle,
  link,
}: ArticleSlideItemProps): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentRoute } = useCurrentRoute(location);
  const { logFirebaseClick } = useFirebase();
  const handleClick = (): void => {
    if (currentRoute === ProtectedRoutes.ARTICLE) {
      logFirebaseClick('article-slider', `article-${title}`);
    }
    if (currentRoute === ProtectedRoutes.HOME) {
      logFirebaseClick('article-slider', `article-${title}`);
    }
    navigate(link);
  };

  return (
    <HStack
      w={{ base: '300px', md: '370px' }}
      height="70px"
      gap="20px"
      onClick={handleClick}
      cursor="pointer"
      position="relative"
    >
      <Box minW="60px" w="60px" h="60px" borderRadius="5px" overflow="hidden">
        <Center h="100%">
          <Image
            src={image.url || Placeholder}
            maxH="100%"
            minH="100%"
            maxW="none"
            minW="100%"
            objectFit="cover"
            alt={image.description}
          />
        </Center>
      </Box>
      <VStack
        align="left"
        flexGrow="1"
        h="100%"
        position="relative"
        overflow="hidden"
        paddingRight="30px"
      >
        <Text textStyle="body.14.lig" color="grey.textPrimary">
          {categoryTitle}
        </Text>
        <Text textStyle="body.16.reg" color="grey.secondary" noOfLines={2}>
          {title}
        </Text>
        <Box h="1px" w="90vw" bg="grey.20" position="absolute" bottom="1px" />
      </VStack>
      <ArrowIcon
        h="22px"
        w="22px"
        position="absolute"
        right="0"
        color="grey.secondary"
        top="50%"
        transform="translateY(-50%) rotate(180deg)"
      />
    </HStack>
  );
};
