import {
  FeedbackType,
  FirebaseEvent,
  FirebaseFeedbackParams,
  FirebaseVideoInteractionType,
} from '@novaheal/types';
import {
  logFirebaseClick,
  logFirebaseEvent,
  logFirebaseFeedback,
  logFirebaseFeedbackVote,
  logFirebaseUserData,
  logFirebaseUserFlow,
  logFirebaseVideo,
} from 'utils/firebaseLogs';
import { useUser } from 'utils/auth';
import { AnalyticsCallOptions } from 'firebase/analytics';

type EventParams = Record<string, unknown>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFirebase = () => {
  const { data: user } = useUser();

  const useLogFirebaseClick = async (
    elementType: string,
    elementName: string,
    additionalParams?: EventParams
  ): Promise<void> => {
    try {
      logFirebaseClick(elementType, elementName, additionalParams, user);
    } catch (error) {
      console.debug('Failed to log event:', error);
    }
  };

  const useLogFirebaseEvent = async (
    eventName: FirebaseEvent | string,
    eventParams?: EventParams,
    options?: AnalyticsCallOptions
  ): Promise<void> => {
    try {
      logFirebaseEvent(eventName, eventParams, options, user);
    } catch (error) {
      console.debug('Failed to log event:', error);
    }
  };

  const useLogFirebaseVideo = async (
    interactionType: FirebaseVideoInteractionType,
    videoName: string,
    additionalParams?: EventParams
  ): Promise<void> => {
    try {
      logFirebaseVideo(interactionType, videoName, additionalParams, user);
    } catch (error) {
      console.debug('Failed to log event:', error);
    }
  };

  const useLogFirebaseUserFlow = async (
    userEvent: string,
    additionalParams?: EventParams
  ): Promise<void> => {
    try {
      logFirebaseUserFlow(userEvent, additionalParams, user);
    } catch (error) {
      console.debug('Failed to log event:', error);
    }
  };

  const useLogFirebaseUserData = async (additionalParams: EventParams): Promise<void> => {
    try {
      logFirebaseUserData(additionalParams, user);
    } catch (error) {
      console.debug('Failed to log event:', error);
    }
  };

  const useLogFirebaseFeedback = async (
    contentName: string,
    feedback: FirebaseFeedbackParams,
    additionalParams?: EventParams
  ): Promise<void> => {
    // Fix streamline
    try {
      logFirebaseFeedback(contentName, feedback, additionalParams, user);
    } catch (error) {
      console.debug('Failed to log event:', error);
    }
  };

  const useLogFirebaseFeedbackVote = async (
    contentName: string,
    feedback: FirebaseFeedbackParams,
    additionalParams?: EventParams
  ): Promise<void> => {
    // Fix streamline
    const sendFeedback: FirebaseFeedbackParams = {
      ...feedback,
      feedbackSentiment: feedback.feedbackSentiment === FeedbackType.POSITIVE ? 1 : 0,
    };
    try {
      logFirebaseFeedbackVote(contentName, sendFeedback, additionalParams, user);
    } catch (error) {
      console.debug('Failed to log event:', error);
    }
  };

  return {
    logFirebaseClick: useLogFirebaseClick,
    logFirebaseEvent: useLogFirebaseEvent,
    logFirebaseVideo: useLogFirebaseVideo,
    logFirebaseUserFlow: useLogFirebaseUserFlow,
    logFirebaseUserData: useLogFirebaseUserData,
    logFirebaseFeedback: useLogFirebaseFeedback,
    logFirebaseFeedbackVote: useLogFirebaseFeedbackVote,
  };
};
