import { Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { ReactElement } from 'react';
import { ProtectedRoutes } from 'types/Routes';
import { HeartIcon, HouseIcon, HumanIcon, LensIcon } from 'assets/icons/Icons';
import { Z_INDEX } from 'styles/zIndex';
import { NavigationLink } from './NavigationLink';

export const NavigationBar = (): ReactElement => {
  const location = useLocation();
  return (
    <Flex
      fontSize={14}
      textAlign="center"
      w="100%"
      bg="bg.light"
      alignItems="center"
      justifyContent="space-around"
      filter="drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.06))"
      zIndex={Z_INDEX.NAVBAR}
      minHeight="60px"
      position="sticky"
      bottom="0"
    >
      <NavigationLink
        to={ProtectedRoutes.HOME}
        isActive={
          location.pathname === ProtectedRoutes.HOME ||
          location.pathname.startsWith(ProtectedRoutes.NEWS) ||
          location.pathname.startsWith(ProtectedRoutes.CURATED_LIST)
        }
        Icon={<HouseIcon />}
        hasTitle
        title="Home"
      />
      <NavigationLink
        to={ProtectedRoutes.KNOWLEDGE}
        isActive={
          location.pathname.startsWith(ProtectedRoutes.KNOWLEDGE) ||
          location.pathname.startsWith(ProtectedRoutes.SEARCH)
        }
        Icon={<LensIcon />}
        title="Suche"
        hasTitle
      />
      <NavigationLink
        to={ProtectedRoutes.FAVORITES}
        isActive={location.pathname.startsWith(ProtectedRoutes.FAVORITES)}
        Icon={<HeartIcon />}
        title="Favoriten"
        hasTitle
      />
      <NavigationLink
        to={ProtectedRoutes.SETTINGS}
        isActive={location.pathname.startsWith(ProtectedRoutes.SETTINGS)}
        Icon={<HumanIcon />}
        title="Profil"
        hasTitle
      />
    </Flex>
  );
};
