import { ModalContent, ModalContentProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

type Props = ModalContentProps & {
  isCentered?: boolean;
  isFullscreen?: boolean;
};

export const CustomModalContent = ({ isCentered, isFullscreen, ...props }: Props): ReactElement => {
  const navigationBarDesktop = document.querySelector('[data-id="navigation-bar-desktop"]');
  const navigationBarWidth = navigationBarDesktop?.clientWidth;
  const offsetLeft = navigationBarWidth ? `${navigationBarWidth / 2}px` : undefined;

  return (
    <ModalContent
      {...props}
      left={offsetLeft}
      maxHeight={isFullscreen ? '100vh' : undefined}
      margin={isCentered ? '0' : 'auto 0 0'}
    >
      {props.children}
    </ModalContent>
  );
};
