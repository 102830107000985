import React, { ReactElement } from 'react';
import { Providers } from 'components/providers/Providers';
import { BrowserRouter as Router } from 'react-router-dom';
import { ScrollToTop } from 'utils/ScrollTop';
import { EnvironmentTag } from './EnvironmentTag';
import { MetaData } from './MetaData';
import { ResponsiveLayout } from './ResponsiveLayout/ResponsiveLayout';

type Props = {
  children: ReactElement;
};

export const Layout = ({ children }: Props): ReactElement => {
  return (
    <Router>
      <ScrollToTop />
      <Providers>
        <MetaData />
        <ResponsiveLayout>{children}</ResponsiveLayout>
        <EnvironmentTag />
      </Providers>
    </Router>
  );
};
