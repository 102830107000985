import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const PlasterIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <rect
        x="2.04722"
        y="17.6108"
        width="22.1329"
        height="7.64769"
        rx="3.82385"
        transform="rotate(-45.2167 2.04722 17.6108)"
        stroke="currentColor"
        fill="none"
        strokeWidth="0.64683"
      />
      <circle cx="14.3298" cy="8.80705" r="0.693033" fill="currentColor" />
      <circle cx="16.2477" cy="10.7228" r="0.693033" fill="currentColor" />
      <circle cx="14.5133" cy="12.454" r="0.693033" fill="currentColor" />
      <circle cx="12.5993" cy="10.538" r="0.693033" fill="currentColor" />
      <circle cx="12.5993" cy="14.1798" r="0.693033" fill="currentColor" />
      <circle cx="10.6813" cy="12.2638" r="0.693033" fill="currentColor" />
      <circle cx="10.6813" cy="16.0956" r="0.693033" fill="currentColor" />
      <circle cx="8.76334" cy="14.1798" r="0.693033" fill="currentColor" />
      <path
        d="M11.4956 5.48492L10.4066 4.38525C8.79484 2.75779 6.16897 2.74503 4.54151 4.35675C2.91405 5.96847 2.9013 8.59434 4.51302 10.2218L5.60205 11.3215L6.10297 11.8273L11.9965 5.99073L11.4956 5.48492Z"
        fill="currentColor"
      />
      <path
        d="M20.5993 14.6775L19.5102 13.5778L19.0093 13.072L13.1158 18.9085L13.6167 19.4144L14.7057 20.514C16.3174 22.1415 18.9433 22.1542 20.5708 20.5425C22.1982 18.9308 22.211 16.3049 20.5993 14.6775Z"
        fill="currentColor"
      />
    </Icon>
  );
};
