import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const FlaskIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M3.89737 18.4486L10.1201 11.3427V4.60655C9.76701 4.60655 9.63593 4.40118 9.62167 4.19581C9.59885 3.86722 9.62167 3.33325 9.62167 3.33325H14.445V4.23689C14.445 4.56548 14.0037 4.62024 13.783 4.60655L13.9154 11.3427C15.6072 13.3143 19.2466 17.5614 20.2705 18.7772C21.2944 19.993 20.1056 20.6666 19.2113 20.6666H3.89737C2.87349 20.1737 3.47075 18.9825 3.89737 18.4486Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        fill="none"
      />
    </Icon>
  );
};
