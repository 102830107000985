import { AxiosResponse } from 'axios';
import { axios } from 'utils/axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { Feedback, ContentType } from '@novaheal/types';

export const addFeedbackVoting = async (data: {
  id: string;
  title: string;
  votingNumber: number;
  type: ContentType;
}): Promise<AxiosResponse<Feedback>> => {
  return axios.post(`feedback`, data);
};

export const useFeedback = (): UseMutationResult<
  AxiosResponse<Feedback, unknown>,
  unknown,
  {
    id: string;
    title: string;
    votingNumber: number;
    type: ContentType;
  },
  unknown
> => {
  return useMutation({
    onSuccess: (data) => {
      window.localStorage.setItem('feedback', data.data.id);
    },
    mutationFn: addFeedbackVoting,
  });
};
