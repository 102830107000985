import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const LogoIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 33 22" {...props}>
      <path
        d="M9.08596 19.7071C8.64417 20.3062 8.06808 20.7931 7.40404 21.1287C6.74001 21.4642 6.00657 21.639 5.26276 21.639C1.35838 21.639 -0.882574 17.1891 1.43823 14.0449L6.90089 6.64802C6.98865 8.20735 7.55726 9.70107 8.52838 10.9233C8.87969 11.4003 10.4393 13.5147 12.0495 15.6929L9.08596 19.7071ZM31.0431 9.82554C30.9646 9.94145 23.6256 19.8657 23.5364 19.9736C23.2042 20.4807 22.7511 20.8972 22.2181 21.1853C21.6851 21.4735 21.0888 21.6244 20.483 21.6244C19.8773 21.6244 19.281 21.4735 18.748 21.1853C18.2149 20.8972 17.7619 20.4807 17.4297 19.9736C17.3405 19.8657 10.0015 9.94145 9.92299 9.82554C9.10101 8.78997 8.65437 7.50579 8.65613 6.18305C8.66242 4.95824 9.04771 3.76549 9.75898 2.76894C10.4702 1.77239 11.4725 1.02103 12.6279 0.618273C13.7832 0.215513 15.0349 0.181149 16.2106 0.519908C17.3862 0.858668 18.4281 1.5539 19.1929 2.50992C19.7491 3.06549 22.3481 6.83321 22.6036 7.17294C22.6845 7.2813 22.7895 7.36927 22.9103 7.42986C23.0311 7.49045 23.1644 7.52201 23.2995 7.52201C23.4347 7.52201 23.5679 7.49045 23.6887 7.42986C23.8096 7.36927 23.9146 7.2813 23.9955 7.17294C24.1078 7.02255 24.1685 6.8398 24.1685 6.65202C24.1685 6.46423 24.1078 6.28149 23.9955 6.13109C23.6934 5.72474 21.9994 3.37591 21.5589 2.77505V2.76572C22.2854 1.74529 23.3163 0.981687 24.5035 0.584634C25.6906 0.187582 26.973 0.177519 28.1662 0.555893C29.3594 0.934267 30.4022 1.6816 31.1445 2.69051C31.8869 3.69941 32.2907 4.91796 32.298 6.17106C32.3051 7.49679 31.8616 8.78552 31.0404 9.82554H31.0431Z"
        fill="#43B188"
      />
    </Icon>
  );
};
