import { ReactElement, useEffect, useRef } from 'react';
import { Box, HStack, Link, ModalBody, Text } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ArrowIcon } from 'assets/icons/Icons';
import { ArticleImage } from 'components/Article/ArticleImage';
import { renderOptions } from 'components/Article/Options';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { Breakpoints } from 'types/breakpoints';
import { ModalKeywordVariantProps, SharedModalProps } from 'types/Modal';
import { ProtectedRoutes } from 'types/Routes';
import { css } from '@emotion/react';
import { Document } from '@contentful/rich-text-types';
import { CustomModal } from '../CustomModal';

type Props = SharedModalProps & ModalKeywordVariantProps;

export const KeywordModal = ({ handleClose, isShown, data: keyword }: Props): ReactElement => {
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, keyword]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!keyword) return <></>;

  return (
    <CustomModal
      isOpen={isShown}
      onClose={handleClose}
      maxWidth="650px"
      isCentered={isDesktop}
      borderRadius={isDesktop ? undefined : '10px 10px 0 0'}
      title={keyword.title}
    >
      <ModalBody
        ref={ref}
        p={{ base: '15px 20px 40px', md: '25px 20px' }}
        css={css`
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        `}
      >
        {keyword.metaInformation && (
          <Box position="relative" mb="8px" mt="6" w="100%">
            <Box
              h="100%"
              w="4px"
              borderRadius="full"
              bg="blue.tertiary"
              position="absolute"
              left="0"
            />
            <Text textStyle="body.14.reg" position="relative" left="12px">
              {keyword.metaInformation}
            </Text>
          </Box>
        )}

        {documentToReactComponents(
          keyword.content.json as Document,
          renderOptions(keyword.content.links, 'keyword')
        )}

        {keyword.image && (
          <ArticleImage
            boxSize="65px"
            imgUrl={keyword.image.url}
            align="left"
            description={keyword.image.description}
          />
        )}

        {keyword.relatedArticle && (
          <Link href={`${ProtectedRoutes.ARTICLE}/${keyword.relatedArticle.id}`}>
            <HStack
              border="1px solid"
              borderColor="green.secondary"
              borderRadius="10px"
              p="4"
              mt="4"
              justifyContent="space-between"
              color="green.secondary"
            >
              <Text textStyle="body.14.reg">{keyword.relatedArticle.title}</Text>
              <ArrowIcon w="20px" h="20px" transform="rotate(180deg)" />
            </HStack>
          </Link>
        )}
      </ModalBody>
    </CustomModal>
  );
};
