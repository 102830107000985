import { HStack, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export const ResultsBar = (): ReactElement => {
  return (
    <HStack bg="white" px="20px" py="6px" w="100%">
      <Text color="grey.40" textStyle="body.12.reg">
        Ergebnisse
      </Text>
    </HStack>
  );
};
