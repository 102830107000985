import { Box, ModalBody, Text } from '@chakra-ui/react';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import React, { ReactElement } from 'react';
import { Breakpoints } from 'types/breakpoints';
import { ModalTagVariantProps, SharedModalProps } from 'types/Modal';
import { CustomModal } from '../CustomModal';

type Props = SharedModalProps & ModalTagVariantProps;

export const TagModal = ({ handleClose, isShown, title, tagDescription }: Props): ReactElement => {
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);

  return (
    <CustomModal
      isOpen={isShown}
      onClose={handleClose}
      maxWidth="650px"
      isCentered={isDesktop}
      borderRadius={isDesktop ? undefined : '10px 10px 0 0'}
      title={title}
    >
      <ModalBody p="0">
        <Box p={{ base: '15px 20px 40px', md: '25px 20px' }}>
          {tagDescription?.map(({ value }) => (
            <Box key={value}>
              <Text textStyle="body.16.reg" color="grey.tertiary">
                {value}
              </Text>
            </Box>
          ))}
        </Box>
      </ModalBody>
    </CustomModal>
  );
};
