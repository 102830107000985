import React, { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Text, Link as ExternalLink } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { PanelLink } from 'types/NavigationBarDesktop';

export const Panel = ({ to, title, isExternal = false }: PanelLink): ReactElement => {
  const location = useLocation();
  const isLinkActive = location.pathname === to;
  const textColor = !isLinkActive ? 'grey.20' : 'bg.light';

  return isExternal ? (
    <ExternalLink href={to} target="_blank" key={uuidv4()}>
      <Text textStyle="body.14.reg" color="grey.20">
        {title}
      </Text>
    </ExternalLink>
  ) : (
    <Link to={to} key={uuidv4()}>
      <Text textStyle="body.14.reg" color={textColor}>
        {title}
      </Text>
    </Link>
  );
};
