import { Box, Text } from '@chakra-ui/react';
import { useModalService } from 'hooks/useModalService';
import { ReactElement } from 'react';
import { TagDescription } from '@novaheal/types';
import { ModalVariant } from 'types/Modal';

type InfoPillProps = {
  identifier: string;
  description: TagDescription[];
};

export const InfoPill = ({ identifier, description }: InfoPillProps): ReactElement => {
  const modalService = useModalService();

  const handleTagClick = (): void => {
    modalService.show(ModalVariant.TAG, {
      title: identifier,
      tagDescription: description,
    });
  };

  return (
    <Box
      bg="green.click"
      px="9px"
      py="3px"
      borderRadius="10px"
      onClick={handleTagClick}
      cursor="pointer"
    >
      <Text
        textStyle="body.12.reg"
        color="green.secondary"
        // border instead of text-decoration to prevent safari bug. See: https://app.asana.com/0/1200905077481922/1202971030464306/f
        borderBottom={description ? '1px dashed' : '0px'}
        borderColor="green.secondary"
        lineHeight="1em"
        my="1px"
      >
        {identifier}
      </Text>
    </Box>
  );
};
