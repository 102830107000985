import { Flex, Progress, usePrevious } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { clamp } from 'utils/clamp';

type Props = {
  currentStep: number;
  stepCount: number;
  hasNonZeroIndex?: boolean;
  indexOffset?: number;
};

const ANIMATION_CONFIG = {
  startEasing: 'ease-in',
  endEasing: 'ease-out',
  startAndEndEasing: 'ease-in-out',
  duration: 0.5,
};

const getTransitionTiming = (
  index: number,
  currentStep: number,
  previousStep: number
): string | undefined => {
  // Start and end in one Bar => use ease-in-out
  if (previousStep === 0 && currentStep === 0) {
    return ANIMATION_CONFIG.startAndEndEasing;
  }
  // Starting point for animation => use ease-in
  if (index === previousStep) {
    return ANIMATION_CONFIG.startEasing;
  }
  // Ending point for animation => use ease-out
  if (index === currentStep) {
    return ANIMATION_CONFIG.endEasing;
  }
  // Default case for all in between => use linear easing
  return undefined;
};

const getTransitionDelay = (index: number, currentStep: number, previousStep: number): number => {
  if (index > previousStep && index <= currentStep) {
    return (index - previousStep) * ANIMATION_CONFIG.duration;
  }

  return 0; // No delay for everything before & after the delta
};

export const ProgressBar = ({
  currentStep,
  stepCount,
  hasNonZeroIndex = false,
  indexOffset = 1,
}: Props): ReactElement => {
  const adjustedCurrentStep = hasNonZeroIndex ? currentStep - indexOffset : currentStep;
  const progressPerStep = 100;
  const currentProgress = progressPerStep * adjustedCurrentStep + 50;
  const previousStep = usePrevious(adjustedCurrentStep);

  const getProgressPerStep = (step: number): number => {
    const progressPerStep = currentProgress - step * 100;
    return clamp(progressPerStep, 0, 100);
  };

  return (
    <Flex gap="20px" width="100%">
      {Array.from({ length: stepCount }).map((value, index) => (
        <Progress
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          value={getProgressPerStep(index)}
          size="sm"
          colorScheme="green"
          borderRadius="full"
          flexGrow={1}
          sx={{
            '& > div:first-of-type': {
              transitionProperty: 'width',
              transitionDuration: `${ANIMATION_CONFIG.duration}s`,
              transitionTimingFunction: getTransitionTiming(
                index,
                adjustedCurrentStep,
                previousStep
              ),
              transitionDelay: `${getTransitionDelay(index, adjustedCurrentStep, previousStep)}s`,
            },
          }}
        />
      ))}
    </Flex>
  );
};
