import { Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Literature } from '@novaheal/types';
import { ArticleSection } from './ArticleSection';

type Props = {
  literatureData: Literature[];
};

export const LiteratureSection = ({ literatureData }: Props): ReactElement => {
  return (
    <ArticleSection title="Literatur" titleStyle={['body.16.ita', 'body.18.ita']}>
      {literatureData.map((literatureEntry, index) => (
        <Text color="grey.textPrimary" key={literatureEntry} pb="5">
          {`${index + 1}. ${literatureEntry}`}
        </Text>
      ))}
    </ArticleSection>
  );
};
