import { VStack, Button, Stack, Text, Center } from '@chakra-ui/react';
import Joi from 'joi';
import { Form, InputField } from 'components/common/Form';
import { useAuthApi } from 'hooks/api/backend/auth/useAuthApi';
import { PageContext } from 'components/common/PageContext';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { errorMessage } from 'components/common/Form/ErrorMessages';
import { FieldError } from 'react-hook-form';
import { CheckIconLarge } from 'assets/common/CheckIconLarge';
import { CloseIcon, EnvelopeIcon } from 'assets/icons/Icons';
import { InAppLayoutVariant } from 'types/Layout';
import { useFirebase } from 'hooks/useFirebase';

const schema = Joi.object({
  email: Joi.string()
    .email({
      tlds: {
        allow: false,
      },
    })
    .required()
    .messages({
      'string.empty': errorMessage.empty,
      'string.email': errorMessage.email,
    }),
});

type ForgotPasswordInputs = {
  email: string;
};

export const ForgotPassword = (): ReactElement => {
  const { sendResetPasswordMail } = useAuthApi();
  const [isReset, setIsReset] = useState(false);
  const [resetMail, setResetMail] = useState('');
  const { logFirebaseUserFlow } = useFirebase();

  return (
    <PageContext variant={InAppLayoutVariant.TERTIARY}>
      {isReset ? (
        <VStack spacing={6} mt="10" px="40px">
          <CheckIconLarge w="35vw" h="96px" mb="10" />
          <Text textStyle="h1" align="center" color="blue.textHeader">
            Du hast Post von uns!
          </Text>
          <Text align="center" textStyle="h4" color="grey.50">
            Danke! Wir haben dir eine Mail geschickt, mit Infos dazu wie du dein Passwort
            zurücksetzen kannst.
          </Text>
          <Text textStyle="h3" color="green.secondary" pb="10">
            {resetMail}
          </Text>
          <Button variant="primary" w="full">
            <Link to="/auth/login">Zum Login</Link>
          </Button>
        </VStack>
      ) : (
        <VStack align="left" mt="10" spacing="5" px="40px">
          <Text as="h1" textStyle="h1" color="blue.textHeader">
            Passwort vergessen?
          </Text>
          <Text color="grey.secondary" textStyle="body.14.lig">
            Bitte trage hier die E-Mail-Adresse ein, mit der du dich angemeldet hast. Wir werden dir
            anschließend den Link zum Zurücksetzen deines Passworts zusenden.
          </Text>
          <Form<ForgotPasswordInputs, typeof schema>
            onSubmit={async (values: { email: string }) => {
              const resetData = await sendResetPasswordMail(values);
              if (resetData === 201) {
                setResetMail(values.email);
                setIsReset(true);
                logFirebaseUserFlow('forgot_password');
              }
            }}
            schema={schema}
            autoComplete="on"
          >
            {({ register, formState, watch, resetField }) => (
              <>
                <InputField
                  type="email"
                  placeholder="E-Mail"
                  error={formState.errors.email as FieldError}
                  registration={register('email')}
                  leftElement={
                    <EnvelopeIcon color={watch('email') ? 'green.primary' : 'grey.40'} />
                  }
                  rightElement={
                    watch('email') && (
                      <Button
                        bg="none"
                        _focus={{ outline: 'none' }}
                        onClick={() => resetField('email')}
                        pos="relative"
                        right="-20px"
                      >
                        <Center bg="grey.40" rounded="full" boxSize="16px">
                          <CloseIcon color="bg.light" w="10px" m={0} />
                        </Center>
                      </Button>
                    )
                  }
                />
                <Stack align="center">
                  <Button variant="primary" type="submit" w="full" mt="10">
                    Zurücksetzen
                  </Button>
                </Stack>
              </>
            )}
          </Form>
        </VStack>
      )}
    </PageContext>
  );
};
