/* eslint-disable react/jsx-no-useless-fragment */
import { ReactElement, useEffect, useMemo } from 'react';
import { useMetaContext } from 'hooks/utils/useMetaContext';
import { useTopBarContext } from 'hooks/utils/useTopBarContext';
import { MetaProps, TopBarProps } from 'types/Layout';

type Props = MetaProps &
  TopBarProps & {
    children: ReactElement | ReactElement[];
  };

export const PageContext = ({
  // Meta Props
  headTitle,

  // TopBar Props
  title,
  variant,
  customOnBackClick,
  hasSearch,
  hasBackButton,
  topBarVariant,
  hasShareButton,
  hasLikeButton,
  handleLikeChange,
  isLiked,
  shareData,

  children,
}: Props): ReactElement => {
  const { setCurrentTopBarValues, defaultTopBarValues } = useTopBarContext();

  const topBarValues = useMemo(
    () => ({
      title,
      variant,
      customOnBackClick,
      hasSearch,
      hasBackButton,
      topBarVariant,
      hasShareButton,
      hasLikeButton,
      handleLikeChange,
      isLiked,
      shareData,
    }),
    [
      customOnBackClick,
      handleLikeChange,
      hasBackButton,
      hasLikeButton,
      hasSearch,
      hasShareButton,
      isLiked,
      shareData,
      title,
      topBarVariant,
      variant,
    ]
  );

  const metaDataValues = useMemo(
    () => ({
      headTitle,
    }),
    [headTitle]
  );

  useEffect(() => {
    setCurrentTopBarValues(topBarValues);
    return () => setCurrentTopBarValues(defaultTopBarValues);
  }, [setCurrentTopBarValues, defaultTopBarValues, topBarValues]);

  const { setCurrentMetaDataValues, defaultMetaDataValues } = useMetaContext();
  useEffect(() => {
    setCurrentMetaDataValues(metaDataValues);
    return () => setCurrentMetaDataValues(defaultMetaDataValues);
  }, [setCurrentMetaDataValues, defaultMetaDataValues, metaDataValues]);

  return <>{children}</>;
};
