import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { Slider } from 'components/atoms/Slider';
import paymentScreen2024 from 'assets/subscription/payment_screen_2024.png';
import paymentScreen2 from 'assets/subscription/payment_screen_2.jpg';
import paymentScreen3 from 'assets/subscription/payment_screen_3.jpg';
import { SubscriptionSlide } from './SubscriptionSlide';

const SLIDER_DATA = [
  {
    id: '0',
    imageUrl: paymentScreen2024,
    heading: 'Limitiertes Angebot',
    copy: 'Lerne so günstig wie noch nie',
  },
  {
    id: '1',
    imageUrl: paymentScreen2,
    heading: 'Aktuelles Wissen für die Praxis',
    copy: 'Sicher im Berufsalltag',
  },
  {
    id: '2',
    imageUrl: paymentScreen3,
    heading: 'Mehr Freizeit für dich',
    copy: 'Weniger Lernstress',
  },
];

const slides = SLIDER_DATA.map((slideData) => (
  <SubscriptionSlide
    key={slideData.id}
    imageUrl={slideData.imageUrl}
    heading={slideData.heading}
    copy={slideData.copy}
  />
));

export const SubscriptionSlider = (): ReactElement => {
  return (
    <Box display="grid" placeItems="center" height="100%">
      <Slider slides={slides} showPagination autoplay />
    </Box>
  );
};
