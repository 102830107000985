import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const InhalerIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_10416_122114)">
        <path
          d="M6.13008 6.57129L2.67724 9.73027C2.54982 9.84685 2.34486 9.79697 2.28525 9.63488L1.31989 7.00976C1.27605 6.89056 1.33144 6.7577 1.44697 6.70494L5.28655 4.95128C5.40734 4.89611 5.55005 4.94816 5.60697 5.06814L6.18559 6.28793C6.23131 6.38433 6.2088 6.49927 6.13008 6.57129Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.7"
        />
        <path
          d="M12.9066 19.6743H5.98122C5.88323 19.6743 5.79483 19.6155 5.75706 19.525L2.03888 10.6248C1.99919 10.5298 2.0237 10.4201 2.10005 10.351L6.80316 6.09586C6.92843 5.98252 7.12829 6.02848 7.19145 6.18517L10.9073 15.4029C10.9444 15.4948 11.0335 15.555 11.1326 15.555H12.9066C13.0407 15.555 13.1495 15.6637 13.1495 15.7979V19.4314C13.1495 19.5656 13.0407 19.6743 12.9066 19.6743Z"
          stroke="currentColor"
          strokeWidth="0.7"
        />
        <path
          d="M13.4984 16.0189H16.0045V19.3244H13.4984V16.0189Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.7"
        />
        <path
          d="M18.3789 16.6074H20.6384M19.708 17.6973H21.8878"
          stroke="currentColor"
          strokeWidth="0.7"
        />
        <path
          d="M20.7415 18.8998C20.7415 19.5338 21.2555 20.0477 21.8895 20.0477C22.5234 20.0477 23.0374 19.5338 23.0374 18.8998C23.0374 18.6438 22.9535 18.4073 22.8118 18.2164C23.4065 18.1732 23.8755 17.6771 23.8755 17.0715C23.8755 16.4646 23.4045 15.9677 22.8081 15.9264C22.9119 15.7538 22.9716 15.5517 22.9716 15.3356C22.9716 14.7017 22.4577 14.1877 21.8237 14.1877C21.1897 14.1877 20.6758 14.7017 20.6758 15.3356"
          stroke="currentColor"
          strokeWidth="0.7"
        />
      </g>
      <defs>
        <clipPath id="clip0_10416_122114">
          <rect width="24" height="24" fill="white" transform="translate(0.558594 0.449707)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
