import { HStack, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { RelevantYearName, AgeGroup } from '@novaheal/types';
import { getYearCollectionString } from 'utils/getYearCollectionString';
import { AcademicHatIcon, ClockIcon } from 'assets/icons/Icons';
import { VSeparator } from 'components/common/VSeparator';
import AgeGroupIcons from './AgeGroupIcons';

type InformationBarProps = {
  readingTime: number;
  relevantYears: RelevantYearName[];
  ageGroups: AgeGroup[];
};

const InformationBar = ({
  readingTime,
  relevantYears,
  ageGroups,
}: InformationBarProps): ReactElement => {
  const readingTimeText = `${readingTime} min`;
  const relevantYearsText = getYearCollectionString(relevantYears, 'Jahr');

  return (
    <HStack>
      <ClockIcon color="grey.40" />
      <Text textStyle="body.14.reg" color="grey.tertiary">
        {readingTimeText}
      </Text>
      <VSeparator textStyle="body.14.reg" color="grey.tertiary" />
      <AcademicHatIcon color="grey.40" />
      <Text textStyle="body.14.reg" color="grey.tertiary">
        {relevantYearsText}
      </Text>
      <VSeparator textStyle="body.14.reg" color="grey.tertiary" />
      <AgeGroupIcons ageGroups={ageGroups} />
    </HStack>
  );
};

export default InformationBar;
