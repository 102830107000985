import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const VideoIconRound = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_11877_84206)">
        <circle cx="12.001" cy="12" r="10.548" fill="white" stroke="white" strokeWidth="2.34399" />
        <g filter="url(#filter0_d_11877_84206)">
          <circle
            cx="12.0008"
            cy="12.0003"
            r="7.50078"
            fill="transparent"
            stroke="currentColor"
            strokeWidth="0.625065"
            shapeRendering="crispEdges"
          />
        </g>
        <path
          d="M15.2541 11.5589C15.623 11.7745 15.6199 12.3086 15.2487 12.5203L10.8168 15.0478C10.4455 15.2595 9.98444 14.99 9.98686 14.5627L10.0157 9.46189C10.0182 9.03459 10.4822 8.76993 10.8511 8.9855L15.2541 11.5589Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.452484"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_11877_84206"
          x="-10.6578"
          y="-10.6583"
          width="45.3175"
          height="45.3171"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.42265" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11877_84206" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11877_84206"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_11877_84206">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
