// TODO: update useAuth to redirect to homepage and not being able to return null or undefined
import { configureAuth } from 'react-query-auth';
import { AuthenticationType, LoginCredentials, User, RegisterCredentials } from '@novaheal/types';
import storage from 'utils/storage';
import { AxiosError } from 'axios';
import { axios } from './axios';
import { parseJwt } from './auth/parseJwt';
import { loginWithEmail, registerWithEmail } from './auth/local/local-auth';
import { loginWithGoogle, registerWithGoogle } from './auth/google/google-auth';
import { logFirebaseUserFlow } from './firebaseLogs';
import { registerWithApple } from './auth/apple/apple-auth';

async function loginFn(credentials: LoginCredentials): Promise<User | null> {
  switch (credentials.authenticationType) {
    case AuthenticationType.EMAIL:
      return loginWithEmail(credentials);
    case AuthenticationType.GOOGLE:
      return loginWithGoogle(credentials);
    case AuthenticationType.APPLE:
      return registerWithApple(credentials);
    default:
      return credentials;
  }
}

async function registerFn(credentials: RegisterCredentials): Promise<User | null> {
  switch (credentials.authenticationType) {
    case AuthenticationType.GOOGLE:
      return registerWithGoogle(credentials);
    case AuthenticationType.EMAIL:
      return registerWithEmail(credentials);
    case AuthenticationType.APPLE:
      return registerWithApple(credentials);
    default:
      return credentials;
  }
}

async function logoutFn(): Promise<User | undefined> {
  let user;
  try {
    const { data: userData } = await axios.get('/user/me');
    user = userData;
    const { data } = await axios.post('/auth/logout');
    sessionStorage.setItem('hasLoggedOut', 'true');
    return data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 401) {
      await refreshUserToken();
      const { data } = await axios.post('/auth/logout');
      return data;
    }
  } finally {
    storage.clearToken();
    logFirebaseUserFlow('logout', undefined, user);
  }
}

// ?? clear tokens if response ain't 200 and maybe redirect
export async function refreshUserToken(): Promise<boolean> {
  try {
    const response = await axios.post('/auth/refresh');
    if (response.status === 200) {
      storage.setToken(response.data.accessToken);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export async function loadUser(): Promise<User | null> {
  try {
    const token = storage.getToken();
    if (token) {
      const parsedToken = parseJwt(token);
      const isExpired = parsedToken.exp * 1000 < Date.now();
      if (isExpired) {
        const hasToken = await refreshUserToken();
        if (!hasToken) return null;
      }
      const { data: user } = await axios.get('/user/me');
      return user;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export const { useUser, useLogin, useRegister, useLogout } = configureAuth({
  userFn: loadUser,
  loginFn,
  registerFn,
  logoutFn,
});
