import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const DripIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M4.64687 16.3524H6.34068V16.9884H4.64687V16.3524Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="0.7"
      />
      <path
        d="M7.44967 2.27555C7.15158 2.02671 6.15966 1.01147 5.15966 1.01147C4.20084 1.01147 3.42414 1.70201 2.80263 2.27555M7.44967 2.27555H9.21076C10.0219 2.27555 10.6795 2.95746 10.6795 3.79864V11.4141C10.6795 13.9376 8.70678 15.9833 6.27332 15.9833H4.8046C2.37114 15.9833 0.398438 13.9376 0.398438 11.4141V3.79864C0.398438 2.95746 1.05601 2.27555 1.86716 2.27555H2.80263M7.44967 2.27555H2.80263"
        stroke="currentColor"
        strokeWidth="0.7"
        fill="none"
      />
      <path
        d="M0.398438 9.80713C0.398438 12.3307 2.37114 15.9832 4.8046 15.9832H6.27332C8.70678 15.9832 10.6795 12.3307 10.6795 9.80713H0.398438Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.7"
      />
      <path
        d="M5.53906 16.5636C5.55531 18.1157 6.40775 21.1744 9.68759 20.9922C12.9674 20.81 13.4213 18.8609 13.4213 16.5636C13.4213 14.6013 13.4213 11.4769 13.4213 11.4769C13.4213 11.4769 13.1448 4.14645 13.4213 3.3029C13.6979 2.45935 14.6417 0.999992 16.2521 1C17.8627 1.00001 18.8016 1.89403 18.9609 3.3029C19.1985 5.40387 18.9609 13.5774 18.9609 13.5774"
        stroke="currentColor"
        strokeWidth="0.7"
        fill="none"
      />
      <rect
        x="12.6391"
        y="8.08242"
        width="1.49764"
        height="4.96173"
        fill="white"
        stroke="currentColor"
        strokeWidth="0.7"
      />
      <rect
        x="18.5297"
        y="9.60244"
        width="1.02149"
        height="1.92179"
        fill="white"
        stroke="currentColor"
        strokeWidth="0.7"
      />
      <path
        d="M19.0215 15.1698C19.1333 15.3207 19.2664 15.5015 19.4014 15.6967C19.5729 15.9448 19.7406 16.2069 19.8643 16.4475C19.9948 16.7014 20.0495 16.8816 20.0495 16.9809C20.0495 17.5454 19.5959 17.9987 19.0415 17.9987C18.4872 17.9987 18.0336 17.5454 18.0336 16.9809C18.0336 16.8767 18.0869 16.6928 18.2104 16.4395C18.3279 16.1984 18.4881 15.9358 18.6535 15.6887C18.7824 15.496 18.9117 15.3167 19.0215 15.1698Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.7"
      />
    </Icon>
  );
};
