import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const FileIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.393 5.80392L16.3988 5.80947L16.4046 5.81496L18.1081 7.42841L13.3512 7.41943C13.2041 7.41916 13.085 7.29982 13.085 7.15272L13.0858 2.5956L16.393 5.80392ZM11.7525 2.59275L5.71615 2.59329V21.4069H18.6657V8.76279L13.3487 8.75276C12.4661 8.7511 11.7515 8.03507 11.7517 7.15249L11.7525 2.59275ZM13.8948 1.5227L17.3214 4.84691L19.7075 7.10686C19.8936 7.28313 19.9991 7.52817 19.9991 7.7845V21.8069C19.9991 22.3224 19.5812 22.7403 19.0657 22.7403H5.31615C4.80068 22.7403 4.38281 22.3224 4.38281 21.8069V2.19332C4.38281 1.67789 4.80063 1.26004 5.31606 1.25999L13.2448 1.25928C13.4875 1.25926 13.7206 1.35374 13.8948 1.5227Z"
        fill="currentColor"
      />
    </Icon>
  );
};
