const containerWidth = {
  md: '1120px',
};

const containerPaddingX = {
  md: '80px',
};

const containerPaddingTop = {
  md: '0px',
};

const containerPaddingBottom = {
  md: '80px',
};

export const container = {
  width: containerWidth,
  paddingX: containerPaddingX,
  paddingTop: containerPaddingTop,
  paddingBottom: containerPaddingBottom,
};
