import { axios } from 'utils/axios';
import { AutocompleteItem } from 'types/autocompleteItem';

// Todo: rethink why we use an object here instead of a direct function return
export const useSchoolApi = (): {
  getAutoCompleteItems: (searchTerm: string) => Promise<AutocompleteItem[]>;
} => {
  const getAutoCompleteItems = async (searchTerm: string): Promise<AutocompleteItem[]> => {
    const result = await axios.get(`/schools/search?searchTerm=${searchTerm.trim()}`);
    return result.data;
  };

  return { getAutoCompleteItems };
};
