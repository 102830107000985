import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { axios } from 'utils/axios';
import { LikedIds } from '@novaheal/types';

export const fetchLikes = async (): Promise<LikedIds> => {
  const { data: likes } = await axios.get('/user/likes');
  return likes;
};

export const useLikes = (): UseQueryResult<LikedIds> => {
  return useQuery({
    queryKey: ['likes'],
    queryFn: fetchLikes,
  });
};
