import { MetaContext } from 'components/providers/MetaProvider';
import { useContext } from 'react';
import { MetaContextProps } from 'types/Layout';

export const useMetaContext = (): MetaContextProps => {
  const context = useContext(MetaContext);

  if (!context) {
    throw new Error('useMetaContext must be used within a TopBarProvider');
  }
  return context;
};
