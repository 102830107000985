import { AxiosError } from 'axios';

class ErrorSubject {
  errorCallback: ((error: AxiosError) => void) | undefined;

  public setErrorCallback(e: (error: AxiosError) => void): void {
    this.errorCallback = e;
  }

  public removeErrorCallback(): void {
    this.errorCallback = undefined;
  }

  public notify(error: AxiosError): void {
    if (this.errorCallback !== undefined) {
      this.errorCallback(error);
    }
  }
}

const errorSubject = new ErrorSubject();

export default errorSubject;
