import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const LockShieldIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M21.252 4.77317C21.2492 4.61688 21.1778 4.46884 21.055 4.36577C20.9268 4.26337 20.7592 4.21807 20.594 4.24131C20.5467 4.24131 15.7831 4.87505 12.4302 1.64957C12.1969 1.43484 11.8282 1.43484 11.595 1.64957C8.24204 4.87505 3.4784 4.2489 3.43112 4.24131C3.26666 4.21942 3.10008 4.26304 2.9701 4.3619C2.84064 4.46379 2.76202 4.61301 2.7534 4.77317C2.54056 8.85119 2.49729 14.5817 4.58939 17.3393C6.74464 20.1687 11.0156 22.2436 11.7212 22.4699C11.9113 22.5253 12.1143 22.5253 12.3045 22.4699C13.0098 22.2587 17.2807 20.1687 19.4362 17.3393C21.5086 14.5892 21.4496 8.85093 21.2526 4.77317L21.252 4.77317ZM18.4584 16.6719C16.6539 19.0447 12.8757 21.0519 11.9926 21.3688C11.11 21.067 7.33163 19.0487 5.52684 16.6719C3.72214 14.2952 3.71827 9.26612 3.8838 5.41816C5.35748 5.49361 9.00592 5.38801 11.9923 2.8303C14.9709 5.38802 18.6315 5.49361 20.1008 5.41816C20.2663 9.26612 20.2387 14.3329 18.4577 16.6719H18.4584ZM11.9926 8.47799C11.2927 8.47327 10.6346 8.79562 10.2286 9.34144C9.82258 9.88726 9.72004 10.5882 9.95346 11.2199C10.1871 11.8517 10.7274 12.3347 11.4016 12.5145V14.9515C11.4016 15.2641 11.6662 15.5174 11.9926 15.5174C12.3191 15.5174 12.5836 15.2641 12.5836 14.9515V12.5145C13.2579 12.3347 13.7982 11.8517 14.0318 11.2199C14.2652 10.5882 14.1627 9.88727 13.7567 9.34144C13.3507 8.79561 12.6925 8.47325 11.9926 8.47799H11.9926ZM11.9926 11.4659C11.7357 11.4659 11.489 11.368 11.3073 11.194C11.1255 11.0199 11.0234 10.7839 11.0234 10.5377C11.0234 10.2917 11.1255 10.0555 11.3073 9.88155C11.489 9.70741 11.7356 9.60972 11.9926 9.60972C12.2496 9.60972 12.4963 9.70741 12.678 9.88155C12.8598 10.0555 12.9618 10.2916 12.9618 10.5377C12.9618 10.7839 12.8598 11.0199 12.678 11.194C12.4962 11.368 12.2496 11.4659 11.9926 11.4659Z"
        fill="currentColor"
      />
    </Icon>
  );
};
