import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const CheckIconLarge = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 149 97" transform="translateX(20px)" {...props}>
      <ellipse opacity="0.3" cx="54.3984" cy="45.4546" rx="44.5" ry="44" fill="#43B188" />
      <circle opacity="0.3" cx="113.898" cy="8.45459" r="8" fill="#387D64" />
      <circle opacity="0.3" cx="143.898" cy="45.4546" r="5" fill="#387D64" />
      <path
        d="M38.6488 61.2044L27.4206 49.5378L24.5039 52.4544L38.6488 67.0378L63.6488 42.0378L60.7322 39.1211L38.6488 61.2044Z"
        fill="#387D64"
      />
      <path
        d="M88.8984 52.4546C88.8984 76.1922 69.4335 95.4546 45.3984 95.4546C21.3634 95.4546 1.89844 76.1922 1.89844 52.4546C1.89844 28.717 21.3634 9.45459 45.3984 9.45459C69.4335 9.45459 88.8984 28.717 88.8984 52.4546Z"
        stroke="#43B188"
        strokeWidth="2"
        fill="none"
      />
    </Icon>
  );
};
