import { Search } from '@novaheal/types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { axios } from 'utils/axios';

const getSearchHistoryEvents = async (): Promise<Search[]> => {
  try {
    const searchEvents = await axios.get<Search[]>('/user/search-event');
    return searchEvents.data ?? [];
  } catch (error) {
    return [];
  }
};

export const useSearchHistoryEvents = (): UseQueryResult<Search[], Error> => {
  return useQuery({
    queryKey: ['search-history'],
    queryFn: () => getSearchHistoryEvents(),
  });
};
