import { useContext } from 'react';
import { FeatureFlags } from 'components/providers/FeatureFlags';

export const useFeatureFlagsContext = (): { isEnabled: (name: string) => boolean } => {
  const context = useContext(FeatureFlags);

  if (!context) {
    throw new Error('useFeatureFlagsContext must be used within a FeatureFlagsProvider');
  }

  const isEnabled = (name: string): boolean => {
    return context.get(name) ?? false;
  };

  return { isEnabled };
};
