import { SubscriptionStatus, User } from '@novaheal/types';

export const isSubscriptionStatus = (
  user: User | null | undefined,
  subscriptionStatus: SubscriptionStatus | SubscriptionStatus[]
): boolean | null => {
  if (!user?.subscription) return null;

  if (Array.isArray(subscriptionStatus)) {
    return subscriptionStatus.includes(user?.subscription?.status);
  }

  return user?.subscription?.status === subscriptionStatus;
};
