export enum InAppLayoutVariant {
  PRIMARY,
  SECONDARY,
  TERTIARY,
}

export enum TopBarVariant {
  PRIMARY,
  SECONDARY,
}

export type TopBarProps = {
  title?: string;
  variant?: InAppLayoutVariant;
  customOnBackClick?: () => void;

  // Primary only
  hasSearch?: boolean;
  hasBackButton?: boolean;
  topBarVariant?: TopBarVariant;

  // Secondary only
  hasShareButton?: boolean;
  hasLikeButton?: boolean;
  handleLikeChange?: () => void;
  isLiked?: boolean | null;
  shareData?: ShareData;
};

export type TopBarContextProps = {
  currentTopBarValues: TopBarProps;
  setCurrentTopBarValues: (value: TopBarProps) => void;
  defaultTopBarValues: TopBarProps;
};

export type MetaProps = {
  headTitle?: string;
};

export type MetaContextProps = {
  currentMetaDataValues: MetaProps;
  setCurrentMetaDataValues: (value: MetaProps) => void;
  defaultMetaDataValues: MetaProps;
};
