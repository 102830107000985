import { useEffect, useState } from 'react';
import { Like, ContentType } from '@novaheal/types';
import { useLikes, useAddLike, useRemoveLike } from 'hooks/api/backend/user/like/likes';
import { useFirebase } from 'hooks/useFirebase';

type UseIsLiked = {
  isLiked: boolean | null;
  handleLikeChange: (title: string) => Promise<void>;
};

export const useIsLiked = (id: string | undefined, type: ContentType): UseIsLiked => {
  const [isLiked, setIsLiked] = useState<boolean | null>(null);
  const updateLikeMutation = useAddLike();
  const removeLikeMutation = useRemoveLike();
  const { data: allLikes, refetch: refetchIds } = useLikes();
  const { logFirebaseClick } = useFirebase();
  useEffect(() => {
    if (allLikes && id) {
      const likesToCheck =
        type === ContentType.ARTICLE ? allLikes.likedArticles : allLikes.likedMedications;
      setIsLiked(likesToCheck.filter((like: Like) => like.contentfulId === id).length > 0);
    }
  }, [allLikes, setIsLiked, id, type]);

  const handleLikeChange = async (title: string): Promise<void> => {
    if (!id) return;
    if (isLiked) {
      logFirebaseClick('remove-like', title, { contentType: type });
      await removeLikeMutation.mutateAsync(id);
      setIsLiked(false);
    } else {
      logFirebaseClick('add-like', title, { contentType: type });
      await updateLikeMutation.mutateAsync({ id, type });
      setIsLiked(true);
    }
    await refetchIds();
  };

  return { isLiked, handleLikeChange };
};
