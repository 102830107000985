import { Box } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

type Props = {
  height?: number;
  width?: number;
};

export const CustomPlayButton = ({ height = 26, width = 23 }: Props): ReactElement => {
  return (
    <Box
      height="60px"
      width="60px"
      bg="grey.10"
      borderRadius="50%"
      display="grid"
      placeItems="center"
      cursor="pointer"
    >
      <Box
        width={0}
        height={0}
        transform="translateX(3px)"
        borderTop={`${height / 2}px solid transparent`}
        borderBottom={`${height / 2}px solid transparent`}
        borderLeft={`${width}px solid`}
        borderLeftColor="grey.tertiary"
      />
    </Box>
  );
};
