import { Text, HStack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { MetaInformation } from '@novaheal/types';
import parse from 'html-react-parser';
import { htmlToReactOptions } from '../../utils/htmlToReactOptions';

type Props = {
  metaData: MetaInformation[];
};

export const MetaSection = ({ metaData }: Props): ReactElement => {
  const metaDataText = metaData
    .map((meta) => meta.value.replaceAll('\n', '<br/>'))
    .join('<br/><br/>');

  return (
    <HStack
      align="left"
      spacing="10px"
      alignItems="stretch"
      mb="30px"
      mt="20px"
      px={{ base: '20px', md: '40px' }}
    >
      <Text flex="0 1 auto" color="grey.textPrimary" textStyle="body.14.lig">
        {parse(metaDataText, htmlToReactOptions)}
      </Text>
    </HStack>
  );
};
