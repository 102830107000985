import { EducationStatus, School } from '@novaheal/types';
import { PostSurveyDTO, QuestionnaireQuestion } from './Survey';

export type WelcomeScreenPost = {
  data: UpdateNameDto | UpdateEducationDataDto | PostSurveyDTO;
  totalSteps: number;
  step: number;
  stepIdentifier: OnboardingStepIdentifier;
};

export type WelcomeScreenGet = {
  currentStep: number;
  totalSteps: number;
  nextStepIdentifier: OnboardingStepIdentifier;
  currentStepIdentifier: OnboardingStepIdentifier;
  previousStepIdentifier: OnboardingStepIdentifier;
  currentData: StepData;
};
export type WelcomeScreenPostResponse = {
  currentStep: number;
  totalSteps: number;
  nextStepIdentifier: OnboardingStepIdentifier;
  currentStepIdentifier: OnboardingStepIdentifier;
  previousStepIdentifier: OnboardingStepIdentifier;
  currentData: StepData;
};

export enum OnboardingStepIdentifier {
  NAME_STEP = 'NAME_STEP',
  EDUCATION_STEP = 'EDUCATION_STEP',
  QUESTIONNAIRE_STEP = 'QUESTIONNAIRE_STEP',
}

export type UpdateEducationDataDto = {
  b2bSchoolData?: School; // Assuming IsOptional means this field is optional

  b2cSchoolData: School;

  educationStatus: EducationStatus;

  customEducationStatus?: string | null;

  examination?: {
    month: number;
    year: number;
  };
};

export type UpdateNameDto = {
  firstName: string;
  lastName: string;
};

type WelcomeScreenQuestionnaireData = {
  id: string;
  codeIdentifier: string;
  description: string | null;
  createdAt: Date;
  updatedAt: Date | null;
  questions: QuestionnaireQuestion[];
};

export type EduPrefillData = {
  educationStatus: EducationStatus;
  school: {
    value: string;
    id: string;
    isDisabled: boolean;
  };
  userExamination: any;
};

export type NamePrefillData = {
  firstName?: string;
  lastName?: string;
};

type StepData = {
  data: WelcomeScreenQuestionnaireData | null;
  prefillData: NamePrefillData | EduPrefillData | null;
};
