import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const PowderIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_10416_121994)">
        <path
          d="M22.4541 11.2633L19.711 13.8431C19.6457 13.9045 19.5446 13.8998 19.4852 13.8327L14.4653 8.15243C14.406 8.08525 14.4108 7.98099 14.4761 7.91957L17.2192 5.33983C18.8095 3.84433 21.2705 3.95802 22.716 5.59376C24.1616 7.22949 24.0444 9.76785 22.4541 11.2633Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.7"
        />
        <path
          d="M2.66305 12.2736L5.40621 14.8533C5.47152 14.9148 5.5726 14.9101 5.63197 14.8429L10.6519 9.16268C10.7112 9.0955 10.7064 8.99125 10.6411 8.92983L7.89794 6.35008C6.30772 4.85459 3.84671 4.96828 2.40114 6.60401C0.955566 8.23974 1.07283 10.7781 2.66305 12.2736Z"
          stroke="currentColor"
          fill="none"
          strokeWidth="0.7"
        />
        <circle
          cx="12.3711"
          cy="13.3586"
          r="0.7"
          stroke="currentColor"
          strokeWidth="0.6"
          fill="none"
        />
        <circle
          cx="9.37109"
          cy="15.4666"
          r="0.7"
          stroke="currentColor"
          strokeWidth="0.6"
          fill="none"
        />
        <circle
          cx="14.8711"
          cy="16.0437"
          r="0.7"
          stroke="currentColor"
          strokeWidth="0.6"
          fill="none"
        />
        <circle
          cx="12.3711"
          cy="18.6194"
          r="0.7"
          stroke="currentColor"
          strokeWidth="0.6"
          fill="none"
        />
        <circle
          cx="10.3711"
          cy="21.6716"
          r="0.7"
          stroke="currentColor"
          strokeWidth="0.6"
          fill="none"
        />
        <circle
          cx="13.8711"
          cy="21.6716"
          r="0.7"
          stroke="currentColor"
          strokeWidth="0.6"
          fill="none"
        />
      </g>
      <defs>
        <clipPath id="clip0_10416_121994">
          <rect width="24" height="24" fill="white" transform="translate(0.558594 0.449707)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
