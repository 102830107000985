import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const HistoryIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M22.2266 11.9219C22.2266 17.4447 17.7494 21.9219 12.2266 21.9219C6.70372 21.9219 2.22656 17.4447 2.22656 11.9219C2.22656 6.39903 6.70372 1.92188 12.2266 1.92188C14.8076 1.92188 17.1602 2.89967 18.9342 4.50505"
        stroke="currentColor"
        strokeWidth="1.33333"
        fill="none"
      />
      <path
        d="M20.4027 3.06527C20.4843 3.00563 20.6 3.05485 20.6136 3.15498L21.1394 7.03238C21.1533 7.13489 21.0502 7.21349 20.9551 7.17299L17.2707 5.60518C17.1755 5.56467 17.1607 5.43592 17.2442 5.37486L20.4027 3.06527Z"
        fill="currentColor"
      />
      <path
        d="M11.7734 6.4436V12.794L15.7255 15.5918"
        stroke="currentColor"
        strokeWidth="1.33333"
        fill="none"
      />
    </Icon>
  );
};
