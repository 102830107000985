import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { BackButton } from 'components/layouts/TopBar/BackButton';
import { ReactElement } from 'react';
import { TopBarVariant } from 'types/Layout';

type TopBarPrimaryProps = {
  onBackClick?: () => void;
  hasBackButton?: boolean;
  title?: string;
  topBarVariant?: TopBarVariant;
};

export const TopBarPrimary = ({
  onBackClick,
  title = '',
  hasBackButton = false,
  topBarVariant = TopBarVariant.PRIMARY,
}: TopBarPrimaryProps): ReactElement => {
  const theme = {
    [TopBarVariant.PRIMARY]: {
      bgColor: 'green.primary',
      color: 'grey.textinvers',
    },
    [TopBarVariant.SECONDARY]: {
      bgColor: 'bg.light',
      color: 'grey.secondary',
    },
  };

  const activeTheme = theme[topBarVariant];

  return (
    <Flex bg={activeTheme.bgColor} width="100%" height="60px" pt="env(safe-area-inset-top)">
      <HStack>
        {hasBackButton && (
          <Box ml="8px">
            <BackButton alternativeFunc={onBackClick} color={activeTheme.color} />
          </Box>
        )}
        <Box pos="absolute" left="50%" transform="translateX(-50%)" margin="0 !important">
          <Text as="h3" textStyle="h3" color={activeTheme.color} noOfLines={1}>
            {title}
          </Text>
        </Box>
      </HStack>
    </Flex>
  );
};
