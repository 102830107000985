import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const SprayIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_10416_122157)">
        <path
          d="M15.0352 3.9917H17.5886M16.5372 5.22336H19.0005"
          stroke="currentColor"
          strokeWidth="0.7"
        />
        <path
          d="M17.7032 6.58231C17.7032 7.29875 18.284 7.87954 19.0005 7.87954C19.7169 7.87954 20.2977 7.29875 20.2977 6.58231C20.2977 6.29297 20.2029 6.02575 20.0428 5.80998C20.7148 5.76123 21.2448 5.20061 21.2448 4.5162C21.2448 3.83037 20.7126 3.26885 20.0386 3.22212C20.1559 3.02709 20.2234 2.7987 20.2234 2.55455C20.2234 1.83811 19.6426 1.25732 18.9261 1.25732C18.2097 1.25732 17.6289 1.83811 17.6289 2.55455"
          stroke="currentColor"
          fill="none"
          strokeWidth="0.7"
        />
        <path
          d="M4.225 8.64242C4.225 8.41445 4.40981 8.22964 4.63778 8.22964H13.2164C13.4443 8.22964 13.6291 8.41445 13.6291 8.64242V20.9724C13.6291 22.2535 12.5906 23.2921 11.3094 23.2921H6.54473C5.26358 23.2921 4.225 22.2535 4.225 20.9724V8.64242Z"
          stroke="currentColor"
          fill="none"
          strokeWidth="0.7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.608 12.3254H4.24609V19.3299H13.608V12.3254ZM8.66604 13.6895H9.2042C9.45656 13.6895 9.66113 13.894 9.66113 14.1464V14.8852H10.4C10.6523 14.8852 10.8569 15.0898 10.8569 15.3422V15.8803C10.8569 16.1327 10.6523 16.3372 10.4 16.3372H9.66113V17.0761C9.66113 17.3284 9.45656 17.533 9.2042 17.533H8.66604C8.41369 17.533 8.20912 17.3284 8.20912 17.0761V16.3372H7.47027C7.21791 16.3372 7.01334 16.1327 7.01334 15.8803V15.3422C7.01334 15.0898 7.21791 14.8852 7.47027 14.8852H8.20912V14.1464C8.20912 13.894 8.41369 13.6895 8.66604 13.6895Z"
          fill="currentColor"
        />
        <rect
          x="5.96484"
          y="5.74561"
          width="5.92776"
          height="1.75139"
          rx="0.762779"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.93856 1.86035C7.51729 1.86035 7.17578 2.20186 7.17578 2.62313V4.60034C7.17578 5.02161 7.51729 5.36312 7.93856 5.36312H9.91577C10.337 5.36312 10.6786 5.02161 10.6786 4.60034V4.21936C10.5966 4.25995 10.5058 4.28251 10.4102 4.28251C10.0458 4.28251 9.75035 3.9543 9.75035 3.54945C9.75035 3.14459 10.0458 2.81639 10.4102 2.81639C10.5058 2.81639 10.5966 2.83895 10.6786 2.87953V2.62313C10.6786 2.20186 10.337 1.86035 9.91577 1.86035H7.93856Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10416_122157">
          <rect width="24" height="24" fill="white" transform="translate(0.558594 0.449707)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
