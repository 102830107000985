import React, { ReactElement, cloneElement } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { LinkStackProps } from 'types/NavigationBarDesktop';

export const LinkStack = ({ Icon, hasTitle, title, isActive }: LinkStackProps): ReactElement => {
  return (
    <HStack px="24px">
      {cloneElement(Icon, {
        ...Icon.props,
        color: isActive ? 'grey.textinvers' : 'grey.20',
        filled: isActive,
        w: '24px',
        h: '24px',
      })}
      {hasTitle && (
        <Text textStyle="body.16.med" color={isActive ? 'grey.textinvers' : 'grey.20'}>
          {title}
        </Text>
      )}
    </HStack>
  );
};
