import React, { ReactElement } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ConditionalWrapper } from 'components/common/ConditionalWrapper';
import { TickCircleIcon } from 'assets/icons/TickCircleIcon';
import { SubscriptionButtonBorder } from './SubscriptionButtonBorder';

type Props = {
  isActive: boolean;
  onClick: () => void;
  duration: string;
  price?: string;
  pricePerMonth: string;
  additionalInfo?: string;
  oldMonthlyPricing?: string;
  savedPercentage?: number;
  hasPromoCode?: boolean;
};

export const SubscriptionButton = ({
  isActive,
  onClick,
  duration,
  price,
  pricePerMonth,
  additionalInfo,
  oldMonthlyPricing,
  savedPercentage,
  hasPromoCode,
}: Props): ReactElement => {
  const renderButtonBorder = (children: React.ReactNode): ReactElement => (
    <SubscriptionButtonBorder percentage={savedPercentage}>{children}</SubscriptionButtonBorder>
  );
  const shouldShowNudging = !!savedPercentage && !isActive;

  return (
    <ConditionalWrapper condition={isActive} wrapper={renderButtonBorder}>
      <Box px={!isActive ? '10px' : '0'} onClick={onClick}>
        <Box
          width="100%"
          borderRadius="10px"
          bg={isActive ? 'green.primary' : 'green.click'}
          border="1px solid"
          borderColor="green.primary"
          p="15px"
          cursor="pointer"
          position="relative"
        >
          {shouldShowNudging && (
            <Text
              color="grey.textPrimary"
              bg="green.quaternary"
              borderRadius="5px"
              border="1px solid"
              borderColor="green.primary"
              position="absolute"
              top="-9px"
              right="20px"
              textAlign="center"
              lineHeight={1}
              p="2px 8px"
              textStyle="copy.12.reg"
              fontSize="12px"
            >
              Spare {savedPercentage} %
            </Text>
          )}
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            color={isActive ? 'grey.textinvers' : 'grey.textPrimary'}
          >
            <Flex flexDirection="column">
              <Text textStyle="body.16.med">{duration}</Text>
              {price && <Text textStyle="body.16.med">{price}</Text>}
            </Flex>
            <Flex flexDirection="column">
              <Text textStyle="body.16.med">{pricePerMonth}</Text>
              <Text as="s" textStyle="body.12.med">
                {oldMonthlyPricing}
              </Text>
            </Flex>
          </Flex>
          {additionalInfo && (
            <Text
              textStyle="body.16.med"
              color={isActive ? 'grey.10' : 'grey.textPrimary'}
              fontWeight="300"
            >
              {additionalInfo}
            </Text>
          )}
        </Box>
      </Box>
      {hasPromoCode && isActive && (
        <Box pl="15px" pt="10px" display="flex" flexDir="row" gap="5px" alignItems="center">
          <Text textStyle="body.12.reg" color="grey.tertiary">
            Dein Promo Code ist aktiv
          </Text>
          <TickCircleIcon color="grey.tertiary" height="12px" width="12px" />
        </Box>
      )}
    </ConditionalWrapper>
  );
};
