import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

type Props = IconProps & {
  filled?: boolean;
};

export const HouseIcon = ({ filled, ...props }: Props): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M11.0405 2.84223L2.93359 10.5784V21.4473H9.30376V15.1865C9.30376 14.6342 9.75147 14.1865 10.3038 14.1865H13.8273C14.3796 14.1865 14.8273 14.6342 14.8273 15.1865V21.4473H21.0635V10.5784L12.3979 2.82063C12.0091 2.47257 11.418 2.48197 11.0405 2.84223Z"
        stroke="currentColor"
        strokeWidth="1.2"
        fill={filled ? 'currentColor' : 'none'}
      />
    </Icon>
  );
};
