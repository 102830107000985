/**
 * Button Variants:
 * base = without specific styles,
 * primary = green fill (Disabled: lighter green)
 * secondary = green font/border (Disabled: lighter green)
 * tertiary = red font
 */

export const ButtonStyles = {
  baseStyle: {
    w: 'auto',
    _hover: { cursor: 'pointer' },
  },

  sizes: {
    xl: {
      fontSize: 'lg',
      px: '10%',
      py: '4%',
      height: '3.2rem',
    },
    lg: {
      fontSize: 'lg',
      px: '10%',
      py: '3%',
      height: '2.8rem',
    },
    md: {
      fontSize: 'lg',
      px: '12px',
    },
    sm: {
      fontSize: 'lg',
      px: '12px',
    },
  },

  variants: {
    base: {
      bg: 'none',
      borderRadius: '10px',
      _hover: { background: 'none' },
      _active: { background: 'none' },
      fontWeight: '400',
    },
    primary: {
      bg: 'green.primary',
      color: 'grey.textinvers',
      borderRadius: '10px',
      border: '1px solid',
      borderColor: 'green.primary',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '100%',
      _hover: {
        _disabled: {
          bgColor: 'green.inactive',
          cursor: 'not-allowed',
        },
      },
      _disabled: {
        bg: 'green.inactive',
        color: 'grey.textinvers',
        borderRadius: '10px',
        border: '1px solid',
        borderColor: 'green.inactive',
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '100%',
      },
    },
    // Does this work?
    primaryDisabled: {
      bg: 'green.inactive',
      color: 'grey.textinvers',
      borderRadius: '10px',
      border: '1px solid',
      borderColor: 'green.inactive',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '100%',
    },
    secondary: {
      bg: 'grey.textinvers',
      color: 'green.primary',
      borderRadius: '10px',
      border: '1px solid',
      borderColor: 'green.primary',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '100%',
    },
    secondaryDisabled: {
      bg: 'grey.textinvers',
      color: 'green.inactive',
      borderRadius: '10px',
      border: '1px solid',
      borderColor: 'green.inactive',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '100%',
    },
    secondaryMore: {
      bg: 'grey.textinvers',
      color: 'green.secondary',
      borderRadius: '10px',
      border: '1.5px solid',
      borderColor: 'green.secondary',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '120%',
    },
    tertiary: {
      bg: 'grey.textinvers',
      color: 'system.error',
      borderRadius: '10px',
      border: '1px solid',
      borderColor: 'grey.textinvers',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '100%',
    },
  },

  defaultProps: {},
};
