import { axios } from 'utils/axios';
import { useMutation } from '@tanstack/react-query';
import { useUser } from 'utils/auth';
import { useSearchHistoryEvents } from './useSearchEvent';

const addSearchEvent = async (searchTerm: string, userId: string | undefined) => {
  if (userId) {
    await axios.post('/user/search-event', {
      id: userId,
      searchTerm,
    });
  }
};

export const useAddSearchEvent = () => {
  const user = useUser();
  const searchHistoryQuery = useSearchHistoryEvents();
  return useMutation({
    mutationFn: (searchTerm: string) => addSearchEvent(searchTerm, user.data?.id),
    onSuccess: () => searchHistoryQuery.refetch(),
  });
};
