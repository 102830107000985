import { useContext } from 'react';
import { BreadcrumbContext } from 'components/providers/BreadcrumbProvider';
import { Breadcrumb } from 'types/breadcrumbs';

type UseBreadcrumbs = {
  additionalBreadcrumbs: Breadcrumb[];
  addBreadcrumbs: (breadcrumbs: Breadcrumb | Breadcrumb[]) => void;
  removeBreadcrumbs: () => void;
  hideBreadcrumbs: () => void;
  showBreadcrumbs: () => void;
};

export const useBreadcrumbs = (): UseBreadcrumbs => {
  const { additionalBreadcrumbs, setAdditionalBreadcrumbs, setShowBreadcrumbs } =
    useContext(BreadcrumbContext);

  const addBreadcrumbs = (breadcrumbs: Breadcrumb | Breadcrumb[]): void => {
    if (Array.isArray(breadcrumbs)) {
      setAdditionalBreadcrumbs((prev: Breadcrumb[]) => [...prev, ...breadcrumbs]);
    } else {
      setAdditionalBreadcrumbs((prev: Breadcrumb[]) => [...prev, breadcrumbs]);
    }
  };

  const removeBreadcrumbs = (): void => {
    setAdditionalBreadcrumbs(() => []);
  };

  const hideBreadcrumbs = (): void => {
    setShowBreadcrumbs(false);
  };

  const showBreadcrumbs = (): void => {
    setShowBreadcrumbs(true);
  };

  return {
    additionalBreadcrumbs,
    addBreadcrumbs,
    removeBreadcrumbs,
    hideBreadcrumbs,
    showBreadcrumbs,
  };
};
