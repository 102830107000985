import { Button, ModalBody, Text, Flex } from '@chakra-ui/react';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import React, { ReactElement } from 'react';
import { Breakpoints } from 'types/breakpoints';
import { ModalStopEditingVariantProps, SharedModalProps } from 'types/Modal';
import { CustomModal } from '../CustomModal';

type Props = SharedModalProps & ModalStopEditingVariantProps;

export const StopEditingModal = ({ handleClose, isShown, onStopEditing }: Props): ReactElement => {
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);

  const handleStopEditing = (): void => {
    if (onStopEditing) onStopEditing();
    handleClose();
  };
  return (
    <CustomModal
      isOpen={isShown}
      onClose={handleStopEditing}
      isCentered
      maxWidth={{ base: '400px', md: '600px' }}
      hideHeader={!isDesktop}
    >
      <ModalBody p={{ base: '40px 30px', md: '0 40px 30px' }} display="flex" flexDirection="column">
        <Flex textAlign="left" mb="30px" flexDir="column" gap="15px">
          <Text textStyle="h3">Achtung</Text>
          <Text>
            Du hast deine Änderungen nicht gespeichert. Möchtest du die Einstellungen wirklich
            verlassen?
          </Text>
        </Flex>
        <Flex
          justifyContent="flex-end"
          w="full"
          h="fit-content"
          borderTop="none"
          borderColor="grey.10"
          p="0px"
        >
          <Button
            onClick={handleClose}
            bg="none"
            w="220px"
            h="40px"
            borderRight="none"
            borderColor="grey.10"
            borderRadius="0px"
            textStyle="h4.reg"
            fontWeight="normal"
            color="grey.secondary"
            _hover={{ bg: 'none' }}
          >
            Weiter anpassen
          </Button>
          <Button
            variant="primary"
            minW={{ base: '150px', md: '220px' }}
            _hover={{ opacity: '0.8' }}
            onClick={handleStopEditing}
          >
            Schließen
          </Button>
        </Flex>
      </ModalBody>
    </CustomModal>
  );
};
