export enum ToastVariant {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export type UseNovaToast = {
  show: (props: ShowToastProps) => void;
};

export type ShowToastProps = {
  id?: string;
  title?: string;
  description?: string;
  variant: ToastVariant;
};
