import { create } from 'zustand';

type StoreState = {
  isDropdownOpen: boolean;
  setDropdownOpen: (value: boolean) => void;
};

export const useStore = create<StoreState>((set) => ({
  isDropdownOpen: false,
  setDropdownOpen: (value: boolean) =>
    set(() => ({
      isDropdownOpen: value,
    })),
}));
