import { ResponsiveValue, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';

type Props = {
  textStyle: ResponsiveValue<string & unknown>;
  color: string;
};

export const VSeparator = ({ textStyle, color }: Props): ReactElement => {
  return (
    <Text textStyle={textStyle} color={color}>
      |
    </Text>
  );
};
