export const colors = {
  bg: {
    light: '#FFFFFF',
    darken: '#F9F9F9',
    input: '#FDFCFE',
    dark: '#0F1313',
    fortext: '#0F1313CC',
    notification: '#D62E04',
    examKnowledge: '#FAF1AD',
    desktopNav: '#20333B',
  },
  green: {
    primary: '#3EA37E',
    secondary: '#387D64',
    inactive: '#78BFA5',
    tertiary: '#A1D8C3',
    click: '#ECF7F3',
    quaternary: '#F5FAF9',
    hover: '#338A6A',
    active: '#2B765A',
  },
  grey: {
    textPrimary: '#333333',
    secondary: '#4C4C4C',
    tertiary: '#666666',
    50: '#808080',
    40: '#999999',
    30: '#B3B3B3',
    20: '#CCCCCC',
    10: '#E5E5E5',
    textinvers: '#FFFFFF',
  },
  system: {
    attention: '#FFD15C',
    dissatisfied: '#DE911D',
    error: '#CB4A4A',
  },
  blue: {
    textHeader: '#3C4375',
    highlighted: '#356EC8',
    tertiary: '#698BC1',
    quaternary: '#B4C5E0',
    google: '#5084E8',
  },
  fc: {
    highlighted: '#6FA7FF',
    textprimary: '#FFFFFF',
  },
  black: '#000000',
};
