import { ReactElement } from 'react';
import { VStack, Box, Text, SkeletonText, Link } from '@chakra-ui/react';
import { RelevantYearName, BaseFolder, AgeGroup, Tags, Category } from '@novaheal/types';
import { ProtectedRoutes } from 'types/Routes';
import InformationBar from './InformationBar';
import { ArticleTags } from './ArticleTags';

export type TopInformationProps = {
  isLoading: boolean;
  title: string;
  tags: Tags;
  category: Category | null;
  parentFolder?: BaseFolder | null;
  readingTime: number;
  relevantYears: RelevantYearName[];
  ageGroups: AgeGroup[];
};

export const TopInformation = ({
  isLoading,
  category,
  parentFolder,
  title,
  tags,
  readingTime,
  ageGroups,
  relevantYears,
}: TopInformationProps): ReactElement => {
  return (
    <Box px="5" mb="0" pb="30px">
      <VStack align="left" spacing="4" pt="4" pb="2">
        <SkeletonText noOfLines={1} isLoaded={!isLoading}>
          <Text textStyle={['body.16.reg', 'body.18.reg']} fontWeight="400" color="grey.tertiary">
            {category && (
              <Link href={`${ProtectedRoutes.CATEGORY}/${category.id}`}>{category.title}</Link>
            )}
            {parentFolder && category && ' / '}
            {parentFolder && (
              <Link href={`${ProtectedRoutes.FOLDER}/${parentFolder.id}`}>{parentFolder.name}</Link>
            )}
          </Text>
        </SkeletonText>
        <SkeletonText noOfLines={1} isLoaded={!isLoading}>
          <Text as="h4" textStyle={['h2', 'h1']} mt="3" color="blue.textHeader" mb="4">
            {title}
          </Text>
        </SkeletonText>
        <VStack align="left" spacing="12px">
          <InformationBar
            readingTime={readingTime}
            relevantYears={relevantYears}
            ageGroups={ageGroups}
          />
          <ArticleTags tags={tags} />
        </VStack>
      </VStack>
    </Box>
  );
};
