import { connectAutocomplete } from 'instantsearch.js/es/connectors';
import { useConnector } from 'react-instantsearch';

import type {
  AutocompleteConnectorParams,
  AutocompleteRenderState,
  AutocompleteWidgetDescription,
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete';

type UseAutocompleteProps = AutocompleteConnectorParams;

export const useAutocomplete = (props?: UseAutocompleteProps): AutocompleteRenderState => {
  return useConnector<AutocompleteConnectorParams, AutocompleteWidgetDescription>(
    connectAutocomplete,
    props
  );
};
