import { Flex, VStack } from '@chakra-ui/react';
import { renderError } from 'components/common/ErrorFallback';
import { useCurrentRoute } from 'hooks/utils/useCurrentRoute';
import React, { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import { useStore } from 'store/useStore';
import { ModalTarget } from 'types/Modal';
import { AllRoutes, RouteType } from 'types/Routes';
import { NavigationBar } from '../NavigationBar/NavigationBarMobile/NavigationBar';
import { TopBar } from '../TopBar/TopBar';

type Props = {
  children: ReactElement;
};
export const MobileLayout = ({ children }: Props): ReactElement => {
  const location = useLocation();
  const { isDropdownOpen } = useStore();
  const { routeType, currentRoute } = useCurrentRoute(location);

  const shouldShowNavbar = routeType === RouteType.PROTECTED;
  const shouldHideTopBar =
    (currentRoute === AllRoutes.BASE && routeType === RouteType.PUBLIC) || isDropdownOpen;

  return (
    <Flex
      flexDirection="column"
      bg="bg.darken"
      minHeight="100vh"
      w="full"
      mx="auto"
      position="relative"
    >
      <TopBar isHidden={shouldHideTopBar} />
      <VStack spacing={0} flexGrow={1} id={ModalTarget.PAGE_MOBILE} position="relative">
        <ErrorBoundary fallbackRender={renderError}>{children}</ErrorBoundary>
      </VStack>
      {shouldShowNavbar && <NavigationBar />}
    </Flex>
  );
};
