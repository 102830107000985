import { axios } from 'utils/axios';
import storage from 'utils/storage';
import { logFirebaseUserFlow } from 'utils/firebaseLogs';
import { EmailLoginCredentials, User, EmailRegisterCredentials } from '@novaheal/types';

export async function loginWithEmail(credentials: EmailLoginCredentials): Promise<User> {
  const response = await axios.post('/auth/login', credentials);
  const { updatedUser, accessToken } = response.data;
  storage.setToken(accessToken);
  logFirebaseUserFlow('login', { ourUserId: updatedUser.id, loginMethod: 'email' }); // named ourUserId, as userId is blocked by ga
  return updatedUser;
}

export async function registerWithEmail(data: EmailRegisterCredentials): Promise<User> {
  const response = await axios.post<User>('/auth/register', {
    ...data,
    acceptedDataPrivacy: data.dataSecurity,
    acceptedAgb: data.agb,
  });
  logFirebaseUserFlow('register', { ourUserId: response.data.id, registerMethod: 'email' }); // named ourUserId, as userId is blocked by ga
  return response.data;
}
