export const Z_INDEX = {
  BASE: 1,
  SEARCH_BAR: 90,
  SEARCH_BAR_SUGGESTIONS: 90,
  NAVBAR: 100,
  BANNER: 110,
  MODAL: 1000,
  MODAL_OVERLAY: 1000,
  TOAST: 1010,
  DEV_TAG: 10000,
};
