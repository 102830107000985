import { SubscriptionStatus, VoucherUsageStatusType } from '@novaheal/types';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { axios } from 'utils/axios';

type RedeemVoucherDto = {
  status: VoucherUsageStatusType;
  message: string;
  previousSubscriptionStatus?: SubscriptionStatus;
};

export const redeemVoucher = async (shortCode: string): Promise<RedeemVoucherDto> => {
  try {
    const { data } = await axios.post<RedeemVoucherDto>('/voucher/redeem', { shortCode });
    const { status, message, previousSubscriptionStatus } = data;

    return { status, message, previousSubscriptionStatus };
  } catch (e: unknown) {
    const axiosError = e as AxiosError<{ errorType: VoucherUsageStatusType; message: string }>;
    const errorType = axiosError.response?.data.errorType ?? VoucherUsageStatusType.NOT_AVAILABLE;
    const message = axiosError.response?.data.message ?? '';

    return {
      status: errorType,
      message,
    };
  }
};

export const useRedeemVoucher = (): UseMutationResult<
  RedeemVoucherDto,
  unknown,
  string,
  unknown
> => {
  return useMutation({
    mutationKey: ['redeem-voucher'],
    mutationFn: redeemVoucher,
  });
};
