import { ReactElement, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRegister } from 'utils/auth';
import { IndexRoutes, ProtectedRoutes } from 'types/Routes';
import { AuthenticationType } from '@novaheal/types';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { toastContent } from 'config/toast';
import { ErrorStatusType } from 'types/StatusTypes';
import { appleAuthHelpers, useScript } from 'react-apple-signin-auth';
import { AppleUserDto } from '@novaheal/types/lib/auth/loginCredentials/appleLoginCredentials';
import { AppleIcon } from 'assets/icons/AppleIcon';
import { authWithApple } from 'utils/auth/apple/apple-auth';

export const AppleButton = (): ReactElement => {
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useNovaToast();
  const register = useRegister();

  const displayErrorMessage = (): void => {
    setIsLoading(false);
    toast.show(toastContent.error[ErrorStatusType.APPLE_LOGIN]);
  };

  const authorizeWithApple = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response = await authWithApple();

      if (!response) return;
      const appleUser: AppleUserDto = {
        email: response?.user?.email ?? '',
        fullName: {
          givenName: response?.user?.name?.firstName ?? '',
          familyName: response?.user?.name?.lastName ?? '',
        },
        identityToken: response?.authorization?.id_token ?? '',
        authorizationCode: response?.authorization?.code ?? '',
        realUserStatus: 0,
        user: response?.user ? JSON.stringify(response.user) : 'LOGIN',
      };

      const user = await register.mutateAsync({
        authenticationType: AuthenticationType.APPLE,
        appleUser,
      });
      if (!user) return;
      const nextRoute = user.isFirstTimeUse ? IndexRoutes.FIRST_TIME : ProtectedRoutes.BASE;
      navigate(nextRoute);
    } catch (error) {
      displayErrorMessage();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      w="full"
      h="44px"
      leftIcon={<AppleIcon height="20px" />}
      bg="black"
      border="black"
      color="bg.light"
      variant="primary"
      onClick={authorizeWithApple}
      isLoading={isLoading}
    >
      Weiter mit Apple
    </Button>
  );
};
