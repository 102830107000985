import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

type Props = IconProps & {
  filled?: boolean;
};

export const HumanIcon = ({ filled, ...props }: Props): ReactElement => {
  return (
    <Icon viewBox="0 0 22 22" {...props}>
      {filled ? (
        <path
          d="M0.149634 18.9571C0.671004 16.686 2.2266 14.2091 4.0403 12.8045C5.17533 11.9262 6.11139 10.8944 7.5623 10.5359C7.06266 10.1724 6.84993 9.99643 6.7381 9.88845C5.66277 8.8501 4.99717 7.41475 4.99717 5.82984C4.99717 4.24554 5.66209 2.81023 6.7381 1.77188C7.81411 0.732872 9.29991 0.0908203 10.9413 0.0908203C12.5826 0.0908203 14.0684 0.732872 15.1445 1.77188C16.2198 2.81023 16.8854 4.24558 16.8854 5.82984C16.8854 7.41475 16.458 8.73298 15.1445 9.88845C14.8171 10.1764 14.7587 10.1966 14.2301 10.5359C15.6801 10.8943 17.0875 11.9262 18.2226 12.8045C20.0361 14.2083 21.3274 16.2752 21.8488 18.5463C21.9285 18.8946 21.927 19.8112 21.8488 20.1346C21.7705 20.4594 21.6141 20.7656 21.3836 21.0431C21.1553 21.3178 20.8794 21.5308 20.5651 21.6762C20.2492 21.8225 19.6452 21.909 19.2817 21.909H3.09551C2.73204 21.909 1.78391 21.8225 1.46875 21.6762C1.15505 21.5306 0.841865 21.1531 0.613649 20.8779C0.3833 20.6004 0.226703 20.2941 0.148498 19.9693C0.0702794 19.6458 0.0699277 19.3052 0.149634 18.9571Z"
          fill="currentColor"
        />
      ) : (
        <path
          d="M0.149634 18.9571C0.671004 16.686 2.2266 14.2091 4.0403 12.8045C5.17533 11.9262 6.11139 10.8944 7.5623 10.5359C7.06266 10.1724 6.84993 9.99643 6.7381 9.88845C5.66277 8.8501 4.99717 7.41475 4.99717 5.82984C4.99717 4.24554 5.66209 2.81023 6.7381 1.77188C7.81411 0.732872 9.29991 0.0908203 10.9413 0.0908203C12.5826 0.0908203 14.0684 0.732872 15.1445 1.77188C16.2198 2.81023 16.8854 4.24558 16.8854 5.82984C16.8854 7.41475 16.458 8.73298 15.1445 9.88845C14.8171 10.1764 14.7587 10.1966 14.2301 10.5359C15.6801 10.8943 17.0875 11.9262 18.2226 12.8045C20.0361 14.2083 21.3274 16.2752 21.8488 18.5463C21.9285 18.8946 21.927 19.8112 21.8488 20.1346C21.7705 20.4594 21.6141 20.7656 21.3836 21.0431C21.1553 21.3178 20.8794 21.5308 20.5651 21.6762C20.2492 21.8225 19.6452 21.909 19.2817 21.909H3.09551C2.73204 21.909 1.78391 21.8225 1.46875 21.6762C1.15505 21.5306 0.841865 21.1531 0.613649 20.8779C0.3833 20.6004 0.226703 20.2941 0.148498 19.9693C0.0702794 19.6458 0.0699277 19.3052 0.149634 18.9571ZM13.7627 2.7112C12.9093 1.88712 12.0937 1.77188 10.9407 1.77188C9.78764 1.77188 8.97203 1.88712 8.1186 2.7112C7.26517 3.53528 6.7381 4.57243 6.7381 5.82984C6.7381 7.08729 7.26578 8.22591 8.11921 9.04999C8.97263 9.87406 9.63779 10.072 10.9407 10.072C12.2429 10.072 12.9099 9.87406 13.7633 9.04999C14.6168 8.22591 15.1445 7.0879 15.1445 5.82984C15.1445 4.57239 14.6162 3.53528 13.7627 2.7112ZM5.20888 13.812C3.6065 15.0529 2.44451 16.8 1.98498 18.801C1.94662 18.9699 1.94579 19.1341 1.9835 19.2893C2.02054 19.443 2.09594 19.5901 2.20888 19.7253C2.32331 19.8633 2.4556 19.9671 2.59997 20.0337C2.74366 20.1002 2.91002 20.1346 3.09488 20.1346H18.7868C18.9716 20.1346 19.1382 20.1002 19.2817 20.0337C19.4267 19.9664 19.5583 19.8633 19.6728 19.7253C19.7852 19.5901 19.8613 19.443 19.8982 19.2893C19.9359 19.1341 19.9352 18.9699 19.8967 18.801C19.4373 16.7998 18.2751 15.0528 16.6721 13.812C15.104 12.5978 13.1112 11.8719 10.9407 11.8719C8.77014 11.8719 6.77696 12.5978 5.20888 13.812Z"
          fill="currentColor"
        />
      )}
    </Icon>
  );
};
