import React, { createContext, ReactElement, useMemo, useState } from 'react';
import { TopBarContextProps, TopBarProps } from 'types/Layout';

const defaultTopBarValues: TopBarProps = {};

export const TopBarContext = createContext<TopBarContextProps>({
  currentTopBarValues: {},
  setCurrentTopBarValues: () => null,
  defaultTopBarValues,
});

type Props = {
  children: ReactElement;
};

export const TopBarProvider = ({ children }: Props): ReactElement => {
  const [currentTopBarValues, setCurrentTopBarValues] = useState<TopBarProps>({});

  const contextValue = useMemo(
    () => ({
      currentTopBarValues,
      setCurrentTopBarValues,
      defaultTopBarValues,
    }),
    [currentTopBarValues, setCurrentTopBarValues]
  );

  return <TopBarContext.Provider value={contextValue}> {children} </TopBarContext.Provider>;
};
