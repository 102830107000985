import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const CreamIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M13.0463 8.88687C13.5447 8.88687 14.0212 9.09201 14.3637 9.45411C14.6613 9.76862 14.8365 10.1792 14.8576 10.6116L14.8707 10.8779V21.7591C14.8707 22.3539 14.3885 22.8361 13.7937 22.8361H7.08711C6.49233 22.8361 6.01016 22.3539 6.01016 21.7591V11.1019C6.01016 10.6153 6.17038 10.1422 6.46608 9.75581C6.8852 9.2081 7.53551 8.88687 8.22518 8.88687H13.0463Z"
        stroke="currentColor"
        strokeWidth="0.7"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5548 12.7839H6.32812V18.939H14.5548V12.7839ZM10.2121 13.9826H10.685C10.9068 13.9826 11.0865 14.1623 11.0865 14.3841V15.0333H11.7358C11.9575 15.0333 12.1373 15.2131 12.1373 15.4349V15.9078C12.1373 16.1295 11.9575 16.3093 11.7358 16.3093H11.0865V16.9585C11.0865 17.1803 10.9068 17.3601 10.685 17.3601H10.2121C9.99035 17.3601 9.81058 17.1803 9.81058 16.9585V16.3093H9.16133C8.93958 16.3093 8.75981 16.1295 8.75981 15.9078V15.4349C8.75981 15.2131 8.93958 15.0333 9.16133 15.0333H9.81058V14.3841C9.81058 14.1623 9.99035 13.9826 10.2121 13.9826Z"
        fill="currentColor"
      />
      <rect
        x="7.59375"
        y="5.7688"
        width="5.69722"
        height="2.22935"
        rx="0.475652"
        fill="currentColor"
      />
      <rect
        x="8.8125"
        y="3.42554"
        width="3.25769"
        height="1.80462"
        rx="0.190261"
        fill="currentColor"
      />
      <path
        d="M6.16016 1.71338L7.52556 2.46009C7.61384 2.50836 7.71284 2.53366 7.81345 2.53366H15.91C16.0881 2.53366 16.257 2.61276 16.371 2.74955L17.3629 3.93986"
        stroke="currentColor"
        strokeLinecap="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0259 6.00537L19.2681 8.48424C19.2916 8.5259 19.3131 8.5688 19.3326 8.6128L19.3353 8.61898C19.4145 8.80056 19.4584 9.00087 19.4584 9.21138C19.4584 10.0348 18.7865 10.7023 17.9577 10.7023C17.1289 10.7023 16.457 10.0348 16.457 9.21138C16.457 9.0021 16.5 8.80288 16.5783 8.62214L16.5804 8.61845C16.6176 8.53328 16.6835 8.38384 16.7354 8.30781L18.0259 6.00537ZM17.5233 8.98386C17.4811 8.86607 17.426 8.71211 17.46 8.57362C17.5001 8.41035 17.6074 8.32635 17.6932 8.25917C17.7768 8.19365 17.84 8.14412 17.8007 8.05266C17.5407 7.92786 17.266 8.17832 17.1403 8.44028C17.0145 8.70224 17.1233 9.01577 17.3832 9.14057C17.6126 9.23304 17.5794 9.14039 17.5233 8.98386Z"
        fill="currentColor"
      />
    </Icon>
  );
};
