import { Box, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';

type Props = {
  text: string;
};

export const Separator = ({ text }: Props): ReactElement => {
  return (
    <Box position="relative" display="grid" placeContent="center" width="100%">
      <Box position="absolute" height="1px" width="100%" backgroundColor="grey.50" top="50%" />
      <Text as="span" paddingX="20px" backgroundColor="bg.darken" position="relative">
        {text}
      </Text>
    </Box>
  );
};
