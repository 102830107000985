import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

type Props = IconProps & {
  filled?: boolean;
};

export const HeartIcon = ({ filled, ...props }: Props): ReactElement => {
  return (
    <Icon viewBox="0 0 24 22" {...props}>
      <path
        d="M6.54439 1.27271C3.53239 1.27271 1.08984 3.69016 1.08984 6.67271C1.08984 9.08034 2.04439 14.7945 11.4404 20.5709C11.6087 20.6733 11.8019 20.7275 11.9989 20.7275C12.1959 20.7275 12.3892 20.6733 12.5575 20.5709C21.9535 14.7945 22.908 9.08034 22.908 6.67271C22.908 3.69016 20.4655 1.27271 17.4535 1.27271C14.4415 1.27271 11.9989 4.54543 11.9989 4.54543C11.9989 4.54543 9.55639 1.27271 6.54439 1.27271Z"
        stroke={filled ? 'none' : 'currentColor'}
        fill={filled ? 'currentColor' : 'none'}
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
