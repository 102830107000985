const camelToSnakeCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

const isObject = (obj: unknown): obj is Record<string, unknown> => {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
};

export const keysToSnakeCase = <T extends Record<string, unknown>>(
  obj: T
): Record<string, unknown> => {
  if (isObject(obj)) {
    return Object.keys(obj).reduce((result: Record<string, unknown>, key) => {
      // Convert key from camel case to snake case
      const snakeCaseKey = camelToSnakeCase(key);

      // Check wether recursion is needed
      const isValueObject = isObject(obj[key]);

      if (isValueObject) {
        result[snakeCaseKey] = keysToSnakeCase(obj[key] as Record<string, unknown>);
      } else {
        // sync values of snakeCase key
        result[snakeCaseKey] = obj[key];
      }
      return result;
    }, {});
  }
  return obj;
};
