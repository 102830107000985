import { Box } from '@chakra-ui/react';
import { ArrowIcon } from 'assets/icons/ArrowIcon';
import React, { ReactElement } from 'react';

type Props = {
  variant?: 'previous' | 'next';
  onClick?: () => void;
};

export const SliderButton = ({ variant = 'next', onClick }: Props): ReactElement => {
  const isNext = variant === 'next';
  return (
    <Box
      height="101%" // remove bg line bug in chrome
      mt="-1px"
      width="56px"
      background="bg.light"
      opacity="0.95"
      display="grid"
      placeItems="center"
      cursor="pointer"
      onClick={onClick}
    >
      <ArrowIcon transform={isNext ? 'rotate(180deg)' : undefined} />
    </Box>
  );
};
