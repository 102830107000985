import { CloseIcon } from '@chakra-ui/icons';
import { Text, HStack, VStack, Box, IconButton, Spacer } from '@chakra-ui/react';
import { ExclamationCircleIcon, ExclamationTriangleIcon, TickCircleIcon } from 'assets/icons/Icons';

import { ReactElement } from 'react';
import { ToastVariant } from 'types/Toast';

type NovaToastProps = {
  title?: string;
  description?: string;
  onClose: () => void;
  variant: ToastVariant;
};

const IconSize = 7;

const VariantIcon = ({ variant }: { variant: ToastVariant }): ReactElement => {
  switch (variant) {
    case ToastVariant.ERROR:
      return <ExclamationCircleIcon w={IconSize} h={IconSize} color="#CB4A4A" />;
    case ToastVariant.SUCCESS:
      return <TickCircleIcon w={IconSize} h={IconSize} color="green" />;
    case ToastVariant.WARNING:
      return <ExclamationTriangleIcon w={IconSize} h={IconSize} color="#E9AA0C" />;
    default:
      return <TickCircleIcon w={IconSize} h={IconSize} color="green" />;
  }
};

const NovaToast = ({ title, description, onClose, variant }: NovaToastProps): ReactElement => {
  return (
    <Box color="grey.textPrimary" p={4} bg="white" boxShadow="md" w={{ base: '', md: '500px' }}>
      <HStack spacing={4}>
        <VariantIcon variant={variant} />
        <VStack alignItems="start" gap="10px">
          <Text textStyle="h4.med">{title}</Text>
          <Text textStyle="body.14.reg">{description}</Text>
        </VStack>
        <Spacer />
        <IconButton
          aria-label="Close Toast"
          variant="gray"
          icon={<CloseIcon />}
          onClick={onClose}
        />
      </HStack>
    </Box>
  );
};

export const renderNovaToast = (props: NovaToastProps): ReactElement => <NovaToast {...props} />;
