import { HStack } from '@chakra-ui/react';
import { AgeGroup } from '@novaheal/types';
import { AgegroupAdultsIcon, AgegroupChildrenIcon, AgegroupSeniorsIcon } from 'assets/icons/Icons';
import { TooltipWithTouch } from 'components/common/TooltipWithTouch';
import { ReactElement } from 'react';

type AgeGroupIconProps = {
  ageGroup: AgeGroup;
};

const AgeGroupIcon = ({ ageGroup }: AgeGroupIconProps): ReactElement => {
  const getIcon = (): ReactElement => {
    switch (ageGroup) {
      case AgeGroup.CHILDREN:
        return <AgegroupChildrenIcon />;
      case AgeGroup.ADULTS:
        return <AgegroupAdultsIcon />;
      case AgeGroup.SENIORS:
        return <AgegroupSeniorsIcon />;
      default:
        return <AgegroupAdultsIcon />;
    }
  };

  const getAgeGroupLabel = (): string => {
    switch (ageGroup) {
      case AgeGroup.CHILDREN:
        return 'Kinder';
      case AgeGroup.ADULTS:
        return 'Erwachsene';
      case AgeGroup.SENIORS:
        return 'Senior:innen';
      default:
        return 'Erwachsene';
    }
  };

  return (
    <TooltipWithTouch hasArrow shouldWrapChildren label={getAgeGroupLabel()}>
      {getIcon()}
    </TooltipWithTouch>
  );
};

type AgeGroupIconsProps = {
  ageGroups: AgeGroup[];
};

const AgeGroupIcons = ({ ageGroups }: AgeGroupIconsProps): ReactElement => {
  return (
    <HStack textStyle="body.14.reg" color="grey.tertiary" spacing="5px">
      {ageGroups.map((ageGroup) => (
        <AgeGroupIcon key={ageGroup} ageGroup={ageGroup} />
      ))}
    </HStack>
  );
};

export default AgeGroupIcons;
