import { Checkbox } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { ReactElement } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Checkboxprops = FieldWrapperPassThroughProps & {
  type?: 'boolean';
  className?: string;
  placeholder?: string;
  registration: Partial<UseFormRegisterReturn>;
  labelElement: React.ReactNode;
};

export const CheckboxField = (props: Checkboxprops): ReactElement => {
  const { type, className, registration, error, placeholder, labelElement } = props;
  return (
    <FieldWrapper error={error}>
      <Checkbox
        type={type}
        className={className}
        colorScheme="green"
        {...registration}
        placeholder={placeholder}
        spacing=".8rem"
        size="lg"
        css={css`
          > .chakra-checkbox__control {
            border: 1px solid #1ea689;
            borderradius: 4px;
          }
        `}
      >
        {labelElement}
      </Checkbox>
    </FieldWrapper>
  );
};
