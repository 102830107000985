import { Box, Button, Text, HStack, VStack, useBoolean } from '@chakra-ui/react';
import { ReactElement, useState } from 'react';
import { useFeedbackMessage } from 'hooks/api/backend/feedback/addFeedbackMessage';
import { useFeedback } from 'hooks/api/backend/feedback/addFeedbackNum';
import { ContentType, FeedbackValue, FeedbackMessage, FeedbackType } from '@novaheal/types';
import { CloseIcon } from 'assets/icons/Icons';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import { Breakpoints } from 'types/breakpoints';
import { useFirebase } from 'hooks/useFirebase';
import { FeedbackInput } from './FeedbackInput';
import { FeedbackSmiley, Smiley } from './FeedbackSmiley';

type FeedbackFormProps = {
  id: string;
  title: string;
  type: ContentType;
};

export const FeedbackForm = ({ id, title, type }: FeedbackFormProps): ReactElement | null => {
  const [step, setStep] = useState(0);
  const [feedbackType, setFeedbackType] = useState<FeedbackType>(FeedbackType.POSITIVE);
  const { logFirebaseFeedbackVote, logFirebaseFeedback } = useFirebase();
  const addFeebackVotingMutation = useFeedback();
  const addFeedbackMessageMutation = useFeedbackMessage();
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);

  const handleSmileyClick = async (level: FeedbackValue): Promise<void> => {
    const currentFeedbackType =
      level === FeedbackValue.ZERO ? FeedbackType.NEGATIVE : FeedbackType.POSITIVE;
    logFirebaseFeedbackVote(title, {
      feedbackSentiment: currentFeedbackType,
    });
    setFeedbackType(currentFeedbackType);
    setStep(1);
    await addFeebackVotingMutation.mutateAsync({
      id,
      title,
      votingNumber: level,
      type,
    });
  };

  const onSubmit = async ({ message, shortMessages }: FeedbackMessage): Promise<void> => {
    shortMessages.forEach((shortMessage) => {
      logFirebaseFeedback(title, {
        feedbackMessage: shortMessage,
      });
    });
    await addFeedbackMessageMutation.mutateAsync({ message, shortMessages });
    setStep(2);
  };

  const shouldShowCenterHeading = isDesktop;
  const headingTextAlign = shouldShowCenterHeading ? 'center' : 'left';
  const shouldShowEmojis = step === 0;
  const shouldShowInput = step === 1;
  const shouldShowEndingMessage = step === 2;
  const shouldShowTitle = shouldShowEmojis || shouldShowInput;

  const smileys: Smiley[] = [
    {
      level: FeedbackValue.ZERO,
      icon: '👎',
    },
    {
      level: FeedbackValue.FIRST,
      icon: '👍',
    },
  ];

  return (
    <VStack
      bg="bg.light"
      p={{ base: '5', md: '8' }}
      boxShadow="0px 0px 16px rgba(0, 0, 0, 0.1)"
      borderRadius="20px"
      align="left"
      position="relative"
    >
      {shouldShowTitle && (
        <Text
          as="h4"
          textStyle="h4.med"
          mb="4"
          color="blue.textHeader"
          textAlign={headingTextAlign}
        >
          Wie hat dir der Lerninhalt gefallen?
        </Text>
      )}

      {shouldShowEndingMessage && (
        <Text
          as="h4"
          textStyle="h4.med"
          mb="4"
          color="blue.textHeader"
          textAlign={headingTextAlign}
        >
          Goldstück
        </Text>
      )}
      {shouldShowEmojis && (
        <HStack mt="4" justifyContent="center">
          {smileys.map(({ icon, level }: Smiley) => (
            <FeedbackSmiley
              key={level}
              icon={icon}
              level={level}
              handleSmileyClick={() => handleSmileyClick(level)}
            />
          ))}
        </HStack>
      )}

      {shouldShowInput && <FeedbackInput onSubmit={onSubmit} feedbackType={feedbackType} />}
      {shouldShowEndingMessage && (
        <Box>
          <Button
            bg="none"
            position="absolute"
            top="2px"
            right="5px"
            onClick={() => {
              setStep(0);
            }}
          >
            <CloseIcon color="grey.secondary" />
          </Button>
          <Text textStyle="body.16.reg" color="grey.tertiary" textAlign="center">
            Durch dein Feedback gibst du uns die Chance das Lernerlebnis von dir und vielen Anderen
            zu verbessern!
          </Text>
        </Box>
      )}
    </VStack>
  );
};
