import { VStack, HStack, Box, Divider } from '@chakra-ui/react';
import { ArrowIcon, FileIcon, FlaskIcon, HistoryIcon, LensIcon } from 'assets/icons/Icons';
import { ReactElement } from 'react';
import { IconType } from 'types/iconType';
import { SearchContentType } from '@novaheal/types';

type SearchDropdownListItemProps = {
  text: string;
  onItemClick: () => void;
  onTopItemClick: () => void;
  iconType: IconType;
  contentType?: SearchContentType;
  shouldHideDivider?: boolean;
  isTopItem?: boolean;
};

export const SearchDropdownListItem = ({
  text,
  onItemClick,
  onTopItemClick,
  iconType,
  contentType,
  shouldHideDivider = false,
  isTopItem = false,
}: SearchDropdownListItemProps): ReactElement => {
  const showHistory = iconType === IconType.HISTORY;

  const getContentTypeIcon = (): ReactElement => {
    if (showHistory) return <HistoryIcon color="grey.50" mt="2px" />;
    if (isTopItem) {
      switch (contentType) {
        case SearchContentType.ARTICLE:
          return <FileIcon color="grey.50" />;
        case SearchContentType.MEDICATION:
          return <FlaskIcon color="grey.50" />;
        default:
          return <LensIcon color="grey.50" />;
      }
    }
    return <LensIcon color="grey.50" />;
  };

  const showArrow =
    contentType === SearchContentType.ARTICLE || contentType === SearchContentType.MEDICATION;
  const icon = getContentTypeIcon();

  const onClick = isTopItem ? onTopItemClick : onItemClick;

  return (
    <VStack
      w="full"
      align="left"
      pt="11px"
      cursor="pointer"
      _hover={{ bg: 'bg.darken' }}
      onMouseDown={onClick}
    >
      <HStack spacing="10px" px="4" pb="11px">
        {icon}
        <Box
          as="button"
          color="grey.textPrimary"
          textStyle="body.16.reg"
          fontWeight="normal"
          bg="none"
          outline="none"
          _hover={{ background: 'none' }}
          textAlign="left"
          w="full"
        >
          {text}
        </Box>
        {showArrow && <ArrowIcon color="grey.40" h="18px" w="18px" transform="rotate(180deg)" />}
      </HStack>
      {!shouldHideDivider && <Divider m="0 !important" />}
    </VStack>
  );
};
