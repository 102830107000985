import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const DesktopNavArrowIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 21 16" {...props}>
      <rect
        x="13.2734"
        y="2.45825"
        width="13.2183"
        height="1.7"
        rx="0.5"
        transform="rotate(-180 13.2734 2.45825)"
        fill="currentColor"
      />
      <rect
        x="13.2734"
        y="15.5405"
        width="13.2183"
        height="1.7"
        rx="0.5"
        transform="rotate(-180 13.2734 15.5405)"
        fill="currentColor"
      />
      <rect
        x="17.6484"
        y="9"
        width="17.5636"
        height="1.7"
        rx="0.5"
        transform="rotate(-180 17.6484 9)"
        fill="currentColor"
      />
      <path
        d="M20.1874 8.02951C20.2664 8.09033 20.2664 8.20948 20.1874 8.2703L16.5506 11.0699C16.4507 11.1468 16.306 11.0755 16.306 10.9495V5.35034C16.306 5.22426 16.4507 5.15304 16.5506 5.22994L20.1874 8.02951Z"
        fill="currentColor"
      />
    </Icon>
  );
};
