import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const AcademyIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5917 3.34944C12.2374 3.09446 11.7597 3.09446 11.4054 3.34944L2.49974 9.75834H2.26179C2.09631 9.75834 1.96217 9.89248 1.96217 10.058V11.0262C1.96217 11.1916 2.09632 11.3258 2.26179 11.3258H3.15563L3.15594 19.1743H1.51446C1.34899 19.1743 1.21484 19.3084 1.21484 19.4739V20.542C1.21484 20.7075 1.34899 20.8416 1.51446 20.8416H22.4818C22.6472 20.8416 22.7814 20.7075 22.7814 20.542V19.4739C22.7814 19.3084 22.6472 19.1743 22.4818 19.1743H21.1693L21.169 11.3258H21.7018C21.8673 11.3258 22.0014 11.1916 22.0014 11.0262V10.058C22.0014 9.89248 21.8673 9.75834 21.7018 9.75834H21.4974L12.5917 3.34944ZM19.0465 9.75834L11.9986 4.68639L4.95067 9.75834H19.0465ZM4.45741 19.1743L4.4571 11.3258H5.72933L5.72964 19.1743H4.45741ZM7.03111 19.1743L7.0308 11.3258H10.3506L10.3509 19.1743H7.03111ZM11.6523 19.1743L11.652 11.3258H13.0501L13.0504 19.1743H11.6523ZM14.3519 19.1743L14.3516 11.3258H17.1718L17.1721 19.1743H14.3519ZM18.4736 19.1743L18.4733 11.3258H19.8676L19.8679 19.1743H18.4736Z"
        fill="currentColor"
      />
    </Icon>
  );
};
