import { ReactElement, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useRegister } from 'utils/auth';
import { IndexRoutes, ProtectedRoutes } from 'types/Routes';
import { GoogleColoredIcon } from 'assets/common/GoogleColoredIcon';
import { AuthenticationType } from '@novaheal/types';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { toastContent } from 'config/toast';
import { ErrorStatusType } from 'types/StatusTypes';

export const GoogleButton = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useNovaToast();
  const register = useRegister();

  const displayErrorMessage = (): void => {
    setIsLoading(false);
    toast.show(toastContent.error[ErrorStatusType.GOOGLE_LOGIN]);
  };

  const handleAuthentication = async (accessToken: string): Promise<void> => {
    try {
      setIsLoading(true);
      const user = await register.mutateAsync({
        authenticationType: AuthenticationType.GOOGLE,
        token: accessToken,
      });
      if (!user) return;

      const nextRoute = user.isFirstTimeUse ? IndexRoutes.FIRST_TIME : ProtectedRoutes.BASE;
      navigate(nextRoute);
    } catch (error) {
      displayErrorMessage();
    } finally {
      setIsLoading(false);
    }
  };

  const authWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse: TokenResponse) => {
      await handleAuthentication(tokenResponse.access_token);
    },
  });

  return (
    <Button
      w="full"
      h="44px"
      leftIcon={<GoogleColoredIcon height="20px" />}
      bg="blue.google"
      border="blue.google"
      color="fc.textprimary"
      variant="primary"
      onClick={() => authWithGoogle()}
      isLoading={isLoading}
    >
      Weiter mit Google
    </Button>
  );
};
