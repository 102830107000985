import { VStack, Text } from '@chakra-ui/react';
import { PageContext } from 'components/common/PageContext';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { InAppLayoutVariant } from 'types/Layout';

type registrationLayoutProps = {
  children: React.ReactNode;
  backButtonFn?: () => void;
};

export const RegistrationLayout = ({
  children,
  backButtonFn,
}: registrationLayoutProps): ReactElement => {
  return (
    <PageContext
      title="Registrierung"
      customOnBackClick={backButtonFn}
      variant={InAppLayoutVariant.TERTIARY}
    >
      <VStack width="100%" mt="40px">
        <VStack align="left" spacing="15px" width="100%" px="20px" mb="14px">
          <Text as="h1" textStyle="h1" color="blue.textHeader">
            Account erstellen
          </Text>
          <Text textStyle="body.16.reg" color="grey.secondary">
            Du hast schon einen Account?
            <Link to="/auth/login">
              <Text ml="4px" as="span" textStyle="body.16.med" color="blue.highlighted">
                Jetzt einloggen
              </Text>
            </Link>
          </Text>
        </VStack>
        {children}
      </VStack>
    </PageContext>
  );
};
