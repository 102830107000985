import { Z_INDEX } from 'styles/zIndex';

export const ToastStyles = {
  variants: {
    error: {
      color: 'grey.textPrimary',
      zIndex: Z_INDEX.TOAST,
    },
  },
};
