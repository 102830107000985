import { useEffect, useState } from 'react';
import {
  ExtractModalOptionsParameters,
  ModalProps,
  ModalPropsVariant,
  ModalVariant,
} from 'types/Modal';
import { useModalContext } from './utils/useModalContext';

type ShowFunctionType = <T extends ModalPropsVariant>(
  variant: T,
  ...args: ExtractModalOptionsParameters<ModalProps, T> extends undefined
    ? [] // No arguments are allowed if no options are required.
    : [ExtractModalOptionsParameters<ModalProps, T>] // Require arguments if they exist.
) => void;

type ModalService = {
  show: ShowFunctionType;
  hide: () => void;
  activeModal: ModalVariant | null;
};

export const useModalService = (): ModalService => {
  const { setCurrentModalValues, currentModalValues } = useModalContext();
  const [activeModal, setActiveModal] = useState<ModalVariant | null>(null);
  useEffect(() => {
    if (!currentModalValues.isShown) {
      setActiveModal(null);
      return;
    }
    setActiveModal(currentModalValues.variant);
  }, [currentModalValues]);

  const show = <T extends ModalPropsVariant>(
    variant: T,
    ...args: ExtractModalOptionsParameters<ModalProps, T> extends undefined
      ? [] // No arguments are allowed if no options are required.
      : [ExtractModalOptionsParameters<ModalProps, T>] // Require arguments if they exist.
  ): void => {
    const argKeyList = Object.keys(args[0] || {});
    const tmpArgs = args[0] as Record<string, unknown>;
    const tmpModalValues = currentModalValues as Record<string, unknown>;
    const didArgsChange = argKeyList.some((key) => {
      if (typeof tmpArgs?.[key] === 'function') return false; // skip functions
      return tmpArgs?.[key] !== tmpModalValues?.[key];
    });
    if (currentModalValues.variant === variant && currentModalValues.isShown && !didArgsChange)
      return;
    setActiveModal(variant);
    setCurrentModalValues({ variant, isShown: true, ...tmpArgs });
  };

  const hide = (): void => {
    setCurrentModalValues({ ...currentModalValues, isShown: false });
  };

  return { show, hide, activeModal };
};
