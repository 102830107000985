import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { ReactElement, cloneElement } from 'react';
import { NavLink } from 'react-router-dom';
import { NavigationLinkProps } from 'types/NavigationBarDesktop';

export const NavigationLink = ({
  to,
  Icon,
  isActive,
  title,
}: NavigationLinkProps): ReactElement => {
  const textColor = isActive ? 'green.primary' : 'grey.40';
  const boxBg = isActive ? 'green.primary' : 'bg.light';
  return (
    <NavLink to={to} style={{ width: '20%', backgroundColor: 'bg.white' }}>
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        _active={{ bg: 'bg.light' }}
      >
        <Box bg={boxBg} h="3px" w="75%" borderRadius="5px" />
        <IconButton
          bg="none"
          aria-label="icon-button"
          color={textColor}
          transition="all 0.1s ease-in"
          w="40px"
          h="40px"
          borderRadius="12px"
          _active={{ bg: 'bg.light' }}
          _pressed={{ bg: 'bg.light' }}
          _focus={{ bg: 'bg.light' }}
        >
          {cloneElement(Icon, { ...Icon.props, filled: isActive, w: '22px', h: '22px' })}
        </IconButton>
        <Text color={textColor} marginTop="-5px">
          {title}
        </Text>
      </Flex>
    </NavLink>
  );
};
