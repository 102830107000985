import Joi from 'joi';
import { Stack, Button, VStack, Link as ExternalLink, Text, Input } from '@chakra-ui/react';
import { Form, InputField } from 'components/common/Form';
import { passwordRegex } from 'config/regex';
import { useRegister } from 'utils/auth';
import { CheckboxField } from 'components/common/Form/CheckboxField';
import { AuthenticationType, EmailRegisterCredentials } from '@novaheal/types';
import { errorMessage } from 'components/common/Form/ErrorMessages';
import { axios, caughtErrorGlobally } from 'utils/axios';
import { toastContent } from 'config/toast';
import { AxiosError } from 'axios';
import { ReactElement, useState } from 'react';
import { HumanIcon } from 'assets/icons/Icons';
import { FieldError } from 'react-hook-form';
import { RegisterStepProps, RegistrationFields } from 'pages/auth/Registration';
import { ExternalRoutes } from 'types/Routes';
import { PasswordInputField } from 'components/common/Form/PasswordInputField';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { ToastVariant } from 'types/Toast';
import { RegistrationLayout } from './RegistrationLayout';
import { EmailStatusType, ErrorStatusType } from '../../types/StatusTypes';

const schema = Joi.object({
  referral: Joi.string().optional(),
  email: Joi.string()
    .email({
      tlds: {
        allow: false,
      },
    })
    .required()
    .messages({
      'string.empty': errorMessage.empty,
      'string.email': errorMessage.email,
    }),
  password: Joi.string().min(8).pattern(new RegExp(passwordRegex)).required().messages({
    'string.min': errorMessage.pwLength,
    'string.empty': errorMessage.empty,
    'string.pattern.base': errorMessage.pwRequirements,
  }),
  firstName: Joi.string().required().messages({
    'string.empty': errorMessage.empty,
  }),
  lastName: Joi.string().required().messages({
    'string.empty': errorMessage.empty,
  }),
  dataSecurity: Joi.boolean().invalid(false).required().messages({
    'any.invalid': errorMessage.checkboxRequired,
  }),
  agb: Joi.boolean().required().invalid(false).messages({
    'any.invalid': errorMessage.checkboxRequired,
  }),
});

export const RegisterStep2 = ({
  prevStep,
  nextStep,
  handleChanges,
  values,
}: RegisterStepProps): ReactElement => {
  const register = useRegister();
  const toast = useNovaToast();
  const [registerButtonDisabled, setRegisterButtonDisabled] = useState(false);

  const handleSubmit = async (registerValues: EmailRegisterCredentials): Promise<void> => {
    setRegisterButtonDisabled(true);
    const { email } = registerValues;

    try {
      const validateMail = await axios.post('/auth/validate', {
        email,
      });

      if (validateMail.data === 'USED') {
        toast.show(toastContent.email[EmailStatusType.TAKEN]);
        setRegisterButtonDisabled(false);
      } else {
        await register.mutateAsync({
          ...registerValues,
          authenticationType: AuthenticationType.EMAIL,
        });
        setRegisterButtonDisabled(false);
        nextStep();
      }
    } catch (error) {
      if (!caughtErrorGlobally(error as AxiosError)) {
        toast.show(toastContent.error[ErrorStatusType.SERVER_ERROR]);
        return;
      }
      toast.show({
        variant: ToastVariant.ERROR,
      });
    } finally {
      setRegisterButtonDisabled(false);
    }
  };
  return (
    <RegistrationLayout backButtonFn={prevStep}>
      <Stack width="100%" px="20px">
        <Form<RegistrationFields, typeof schema>
          onSubmit={handleSubmit}
          schema={schema}
          autoComplete="off"
        >
          {({ register, formState, watch, resetField }) => (
            <>
              <InputField
                type="text"
                placeholder="Vorname"
                error={formState.errors.firstName as FieldError}
                registration={register('firstName', {
                  onChange: handleChanges('firstName'),
                  value: values.firstName,
                })}
                leftElement={<HumanIcon color={watch('firstName') ? 'green.primary' : 'grey.40'} />}
              />
              <InputField
                type="text"
                placeholder="Nachname"
                error={formState.errors.lastName as FieldError}
                registration={register('lastName', {
                  onChange: handleChanges('lastName'),
                  value: values.lastName,
                })}
                leftElement={<HumanIcon color={watch('lastName') ? 'green.primary' : 'grey.40'} />}
              />

              <input type="hidden" value={values.email} {...register('email')} />
              {/* hidden input to catch chromes autofill behavior, as it skips autoComplete="off|false" */}
              <Input display="none" />
              <PasswordInputField
                placeholder="Passwort"
                autoComplete="new-password"
                error={formState.errors.password as FieldError}
                register={() =>
                  register('password', {
                    onChange: handleChanges('password'),
                    value: values.password,
                  })
                }
                value={watch('password')}
                reset={() => resetField('password')}
              />

              <VStack align="left" pt="5">
                <CheckboxField
                  type="boolean"
                  error={formState.errors.dataSecurity as FieldError}
                  registration={register('dataSecurity', {
                    onChange: handleChanges('dataSecurity'),
                    value: values.dataSecurity,
                  })}
                  labelElement={
                    <Text textStyle="body.16.reg">
                      Hiermit bestätige ich dass ich die{' '}
                      <ExternalLink
                        color="blue.highlighted"
                        href={ExternalRoutes.PRIVACY}
                        target="_blank"
                      >
                        Datenschutzerklärung
                      </ExternalLink>{' '}
                      gelesen habe und akzeptiere.
                    </Text>
                  }
                />
                <CheckboxField
                  type="boolean"
                  error={formState.errors.agb as FieldError}
                  registration={register('agb', {
                    onChange: handleChanges('agb'),
                    value: values.agb,
                  })}
                  labelElement={
                    <Text textStyle="body.16.reg">
                      Hiermit bestätige ich, dass ich die{' '}
                      <ExternalLink
                        color="blue.highlighted"
                        href={ExternalRoutes.TERMS}
                        target="_blank"
                      >
                        AGBs
                      </ExternalLink>{' '}
                      gelesen habe und akzeptiere.
                    </Text>
                  }
                />
              </VStack>
              <Stack align="center" pt="10">
                <Button
                  variant="primary"
                  type="submit"
                  w="full"
                  isDisabled={registerButtonDisabled || !formState.isValid}
                >
                  Registrieren
                </Button>
              </Stack>
            </>
          )}
        </Form>
      </Stack>
    </RegistrationLayout>
  );
};
