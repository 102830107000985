/**
 * "Given an enum and a value, return the key of the enum that matches the value."
 *
 * The function takes two parameters:
 *
 * myEnum: This is the enum that we want to reverse map.
 * enumValue: This is the value that we want to find the key for.
 * The function returns a keyof T | null. The keyof T part means that the function will return a key of
 * the enum. The null part means that the function will return null if the value is not found
 * T extends an object/Enum type. This means that the function will only work with enums.
 * @param {T} myEnum - This is the enum you want to reverse map.
 * @param {string} enumValue - The value of the enum you want to reverse map.
 * @returns A function that takes a string and returns a string
 */
export function reverseMapEnum<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | null {
  const keys = Object.keys(myEnum).filter((enumKey) => myEnum[enumKey] === enumValue);

  return keys.length > 0 ? keys[0] : null;
}
