import { Article } from '@novaheal/types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { axios } from 'utils/axios';
import { parseArticle } from '@novaheal/parsers';
import { parse } from 'flatted';

const getArticle = async (id: string): Promise<Article> => {
  const response = await axios.get(`/ctf/article/${id}`);
  const parsedArticle = parseArticle(parse(response.data.data));
  return parsedArticle;
};

export const useArticleApi = (): UseQueryResult<Article, unknown> => {
  const { id } = useParams();

  return useQuery({
    queryKey: [`article-${id}`], // Key for caching the query
    queryFn: () => getArticle(id as string),
    enabled: !!id,
    retry: 1,
  });
};
