import { Box, Link as OnClickLink } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { NavigationLinkProps } from 'types/NavigationBarDesktop';
import { LinkStack } from './NavigationLinkStack';

export const NavigationLink = ({
  to,
  Icon,
  isActive,
  title,
  hasTitle,
  isInternalLink = true,
  isOnClickLink = false,
  onClick,
}: NavigationLinkProps): ReactElement => {
  const shouldShowOnClickLink = isOnClickLink && onClick && !isInternalLink;
  const shouldShowInternalLink = isInternalLink && !isOnClickLink;

  if (isInternalLink && isOnClickLink)
    throw new Error('isInternalLink and isOnClickLink cant be both true');

  return (
    <Box position="relative">
      {isActive && (
        <Box
          h="40px"
          bg="grey.textinvers"
          w="4px"
          position="absolute"
          top="50%"
          transform="translateY(-50%)"
          borderTopRightRadius="5px"
          borderBottomRightRadius="5px"
        />
      )}
      {shouldShowOnClickLink && (
        <OnClickLink onClick={onClick} _hover={{ textDecoration: 'none' }}>
          <LinkStack Icon={Icon} hasTitle={hasTitle} title={title} isActive={isActive} />
        </OnClickLink>
      )}
      {shouldShowInternalLink && (
        <Link to={to}>
          <LinkStack Icon={Icon} hasTitle={hasTitle} title={title} isActive={isActive} />
        </Link>
      )}
    </Box>
  );
};
