import { AxiosError } from 'axios';
import { axios } from 'utils/axios';
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';
import {
  OnboardingStepIdentifier,
  WelcomeScreenGet,
  WelcomeScreenPost,
  WelcomeScreenPostResponse,
} from 'types/WelcomeScreen';

const route = '/onboarding';

const postStep = async (postData: WelcomeScreenPost): Promise<WelcomeScreenGet> => {
  const { data, totalSteps, stepIdentifier, step } = postData;
  const routeWithStep = stepIdentifier ? `${route}/${stepIdentifier}` : route;
  const { data: updatedData } = await axios.post<WelcomeScreenPostResponse>(routeWithStep, {
    data,
    totalSteps,
    step,
  });

  return updatedData;
};

const getStep = async (options?: {
  id?: OnboardingStepIdentifier;
  totalSteps?: number;
}): Promise<WelcomeScreenGet> => {
  const routeWithStep = options?.id ? `${route}/${options.id}` : route;
  try {
    const { data } = await axios.get<WelcomeScreenGet>(routeWithStep, {
      params: {
        totalSteps: options?.totalSteps,
      },
    });

    return data;
  } catch (e: unknown) {
    const axiosError = e as AxiosError;
    // return generateError(axiosError);
    throw axiosError;
  }
};

export const useWelcomeGetStep = (options?: {
  id?: OnboardingStepIdentifier;
  step?: number;
  totalSteps?: number;
}): UseQueryResult<WelcomeScreenGet, unknown> => {
  return useQuery({
    queryKey: [`getWelcomeStep-${options?.id}`], // Key for caching the query
    queryFn: () => getStep(options),
    retry: 1,
  });
};

export const useWelcomePostStep = (): UseMutationResult<
  WelcomeScreenGet,
  AxiosError<unknown>,
  WelcomeScreenPost,
  unknown
> => {
  return useMutation({
    mutationFn: (postData: WelcomeScreenPost) => postStep(postData),
  });
};
