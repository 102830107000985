import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

type Props = IconProps & {
  filled?: boolean;
};

export const LensIcon = ({ filled, ...props }: Props): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M15.6796 15.8755L15.6569 15.6738L15.8754 15.7041L21.4508 20.9394L21.3062 21.0672L15.6796 15.8755Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M16.1924 9.94385C16.1924 13.6426 13.1905 16.6422 9.48605 16.6422C5.78161 16.6422 2.77969 13.6426 2.77969 9.94385C2.77969 6.24508 5.78161 3.24551 9.48605 3.24551C13.1905 3.24551 16.1924 6.24508 16.1924 9.94385Z"
        stroke="currentColor"
        strokeWidth="1.2"
        fill={filled ? 'currentColor' : 'none'}
      />
    </Icon>
  );
};
