import { useEffect, useState } from 'react';
import { Location } from 'react-router-dom';
import { AllRoutes, IndexRoutes, ProtectedRoutes, PublicRoutes, RouteType } from 'types/Routes';
import { reverseMapEnum } from 'utils/reverseMapEnum';

/**
 * It takes a location object and returns the current route, current route key and whether it's protected or not
 * @param {Location} location - Location - this is the location object from the react-router-dom
 * package.
 * @returns An object with three properties: currentRouteKey, currentRoute and routeType.
 */
export const useCurrentRoute = (
  location: Location
): {
  currentRouteKey: keyof typeof AllRoutes | null;
  routeType: RouteType;
  currentRoute: ProtectedRoutes | PublicRoutes | IndexRoutes | null;
} => {
  const [currentRouteKey, setCurrentRouteKey] = useState<keyof typeof AllRoutes | null>(null);
  const [routeType, setRouteType] = useState<RouteType>(RouteType.NOT_DEFINED);
  const [currentRoute, setCurrentRoute] = useState<
    ProtectedRoutes | PublicRoutes | IndexRoutes | null
  >(null);

  useEffect(() => {
    const cleanRoutePath = (): string => {
      const getRouteWithoutId = (route: string, separator: ProtectedRoutes): string => {
        const id = route.split(separator)[1];
        const routeWIthoutId = route.split(id)[0];

        return routeWIthoutId;
      };
      if (
        location.pathname.includes(AllRoutes.MEDICATION) &&
        !location.pathname.endsWith(AllRoutes.MEDICATION)
      ) {
        return getRouteWithoutId(location.pathname, AllRoutes.MEDICATION);
      }
      if (
        location.pathname.includes(AllRoutes.FOLDER) &&
        !location.pathname.endsWith(AllRoutes.FOLDER)
      ) {
        return getRouteWithoutId(location.pathname, AllRoutes.FOLDER);
      }
      if (
        location.pathname.includes(AllRoutes.CATEGORY) &&
        !location.pathname.endsWith(AllRoutes.CATEGORY)
      ) {
        return getRouteWithoutId(location.pathname, AllRoutes.CATEGORY);
      }
      if (
        location.pathname.includes(AllRoutes.ARTICLE) &&
        !location.pathname.endsWith(AllRoutes.ARTICLE)
      ) {
        return getRouteWithoutId(location.pathname, AllRoutes.ARTICLE);
      }
      if (
        location.pathname.includes(AllRoutes.CURATED_LIST) &&
        !location.pathname.endsWith(AllRoutes.CURATED_LIST)
      ) {
        return getRouteWithoutId(location.pathname, AllRoutes.CURATED_LIST);
      }
      if (
        location.pathname.includes(AllRoutes.NEWS) &&
        !location.pathname.endsWith(AllRoutes.NEWS)
      ) {
        return getRouteWithoutId(location.pathname, AllRoutes.NEWS);
      }

      return location.pathname;
    };

    const cleanedRoutePath = cleanRoutePath();

    const getRouteType = (): RouteType => {
      if (Object.values(ProtectedRoutes).includes(cleanedRoutePath as ProtectedRoutes))
        return RouteType.PROTECTED;

      if (Object.values(IndexRoutes).includes(cleanedRoutePath as IndexRoutes))
        return RouteType.PUBLIC;

      if (Object.values(PublicRoutes).includes(cleanedRoutePath as PublicRoutes))
        return RouteType.PUBLIC;

      return RouteType.NOT_DEFINED;
    };

    const pathToEnum = (path: string): keyof typeof AllRoutes | null => {
      return reverseMapEnum(AllRoutes, path);
    };

    setCurrentRouteKey(pathToEnum(cleanedRoutePath));
    setRouteType(getRouteType());
  }, [location.pathname]);

  useEffect(() => {
    if (currentRouteKey) {
      setCurrentRoute(AllRoutes[currentRouteKey]);
    } else {
      setCurrentRoute(null);
    }
  }, [currentRouteKey]);

  return { currentRouteKey, routeType, currentRoute };
};
