import {
  Text,
  Box,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  useStyleConfig,
} from '@chakra-ui/react';
import { AccordionArrowIcon } from 'assets/icons/AccordionArrowIcon';
import { ArticleVideoIcon } from 'assets/icons/ArticleVideoIcon';
import { ReactElement, ReactNode, useEffect, useState } from 'react';

type ArticleSectionProps = {
  title: string;
  children: ReactNode;
  titleStyle?: Array<'body.16.med' | 'body.16.ita' | 'body.18.ita' | 'h2'>;
  hasVideo?: boolean | null;
  id?: string;
  isPreopened?: boolean;
};

export const ArticleSection = ({
  title,
  children,
  titleStyle = ['body.16.med', 'h2'],
  hasVideo,
  id,
  isPreopened = false,
}: ArticleSectionProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false); // isOpen only controls the arrow state. Accordion handling gets done by chakra UI

  useEffect(() => {
    if (isPreopened) {
      setIsOpen(true);
    }
  }, [isPreopened]);

  const toggleAccordion = (): void => {
    setIsOpen(!isOpen);
  };

  const styles = useStyleConfig('Accordion');

  return (
    <Accordion allowToggle id={id} defaultIndex={isPreopened ? [0] : []}>
      <AccordionItem bg="bg.light" borderTop="none" px="0px" _expanded={{ borderBottom: 'none' }}>
        <AccordionButton
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={{ base: '20px', md: '40px' }}
          position="relative"
          onClick={toggleAccordion}
        >
          <Box>
            <Text as="h2" textStyle={titleStyle} color="grey.textPrimary" align="left" py="2">
              {title}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap="20px">
            {hasVideo && <ArticleVideoIcon />}
            <AccordionArrowIcon
              fontSize="25px"
              mb="-10px"
              isExpanded={isOpen}
              color="grey.secondary"
            />
          </Box>
        </AccordionButton>
        <AccordionPanel px="20px">
          <Box
            textStyle="body.16.reg"
            color="grey.textPrimary"
            p={{ base: '15px 32px', md: '20px' }}
            __css={styles}
          >
            {children}
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
