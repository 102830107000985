import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const AgegroupAdultsIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <rect
        x="2.08438"
        y="1.15762"
        width="19.8312"
        height="21.6847"
        rx="3.4"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.2"
      />
      <path
        d="M8.76 17C8.64267 17 8.552 16.968 8.488 16.904C8.424 16.8293 8.392 16.7387 8.392 16.632V6.184C8.392 6.06667 8.424 5.976 8.488 5.912C8.552 5.83733 8.64267 5.8 8.76 5.8H15.368C15.4853 5.8 15.576 5.83733 15.64 5.912C15.704 5.976 15.736 6.06667 15.736 6.184V6.792C15.736 6.90933 15.704 7 15.64 7.064C15.576 7.128 15.4853 7.16 15.368 7.16H9.928V10.664H15.016C15.1333 10.664 15.224 10.7013 15.288 10.776C15.352 10.84 15.384 10.9307 15.384 11.048V11.64C15.384 11.7467 15.352 11.8373 15.288 11.912C15.224 11.976 15.1333 12.008 15.016 12.008H9.928V15.64H15.496C15.6133 15.64 15.704 15.672 15.768 15.736C15.832 15.8 15.864 15.8907 15.864 16.008V16.632C15.864 16.7387 15.832 16.8293 15.768 16.904C15.704 16.968 15.6133 17 15.496 17H8.76Z"
        fill="currentColor"
      />
    </Icon>
  );
};
