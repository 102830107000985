import { Button } from '@chakra-ui/react';
import { ArrowIcon } from 'assets/icons/Icons';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

type BackButtonProps = {
  alternativeFunc?: () => void;
  color?: string;
};

export const BackButton = ({ alternativeFunc, color }: BackButtonProps): ReactElement => {
  const navigate = useNavigate();
  return (
    <Button
      variant="base"
      verticalAlign="middle"
      fontWeight="300"
      color="fc.textprimary"
      px="0"
      ml="0px"
      onClick={() => (alternativeFunc ? alternativeFunc() : navigate(-1))}
    >
      <ArrowIcon w="22px" h="22px" color={color} />
    </Button>
  );
};
