import { Box, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

type Props = {
  percentage?: number;
  children: React.ReactNode;
};

export const SubscriptionButtonBorder = ({ percentage, children }: Props): ReactElement => {
  return (
    <Box
      borderRadius="10px"
      border="1px solid"
      borderColor="green.primary"
      p="10px"
      position="relative"
    >
      {percentage && (
        <Text
          color="grey.textPrimary"
          bg="green.quaternary"
          borderRadius="5px"
          border="1px solid"
          borderColor="green.primary"
          position="absolute"
          top="0"
          right="20px"
          transform="translateY(-60%)"
          textAlign="center"
          lineHeight={1}
          p="2px 8px"
          textStyle="copy.12.reg"
          fontSize="12px"
        >
          Spare {percentage} %
        </Text>
      )}
      {children}
    </Box>
  );
};
