import { axios } from 'utils/axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { queryClient } from 'utils/react-query';
import { ContentType } from '@novaheal/types';

type MutationData = {
  id: string;
  type: ContentType;
};

export const addLike = async ({ id, type }: MutationData): Promise<void> => {
  await axios.patch(`user/like/${id}`, { type });
};

export const useAddLike = (): UseMutationResult<void, unknown, MutationData, unknown> => {
  return useMutation({
    mutationFn: addLike,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['likes'],
      });
    },
  });
};
