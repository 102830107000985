import { Button, ModalBody, VStack, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { SharedModalProps } from 'types/Modal';
import { useLogout } from 'utils/auth';
import { useSentry } from 'hooks/useSentry';
import { CustomModal } from '../CustomModal';

type Props = SharedModalProps;

export const LogoutModal = ({ handleClose, isShown }: Props): ReactElement => {
  const logout = useLogout();
  const { clearUser: clearSentryUser } = useSentry();

  const handleLogout = (): void => {
    logout.mutate({});
    clearSentryUser();
    handleClose();
  };
  return (
    <CustomModal isOpen={isShown} onClose={handleClose} isCentered hideHeader>
      <ModalBody p="12px 20px">
        <VStack textAlign="center">
          <Text textStyle="body.16.reg" my="5">
            Bist du sicher, dass du dich aus deinem Account ausloggen möchtest?
          </Text>

          <Button onClick={handleLogout} variant="primary" w="full">
            Ausloggen
          </Button>
          <Button onClick={handleClose} variant="base" color="green.primary">
            Abbrechen
          </Button>
        </VStack>
      </ModalBody>
    </CustomModal>
  );
};
