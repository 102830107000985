export const API_URL = process.env.REACT_APP_API_URL as string;
export const BASE_URL = process.env.REACT_APP_BASE_URL as string;
export const CONTENTFUL_URL = process.env.REACT_APP_CONTENTFUL_URL as string;
export const CONTENTFUL_ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string;
export const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID as string;
export const CONTENTFUL_MANAGEMENT_API_ACCESS_TOKEN = process.env
  .REACT_APP_CONTENTFUL_MANAGEMENT_API_ACCESS_TOKEN as string;
export const CONTENTFUL_PREVIEW_ACCESS_TOKEN = process.env
  .REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN as string;
export const CONTENTFUL_ENVIRONMENT = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT as string;
export const CONTENTFUL_SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID as string;
export const CONTENTFUL_HOME_ENTRY_ID = process.env.REACT_APP_CONTENTFUL_HOME_ENTRY_ID as string;
// a variable to decide if the app is loaded in preview mode
export const CONTENTFUL_IS_PREVIEW = process.env.REACT_APP_CONTENTFUL_IS_PREVIEW === 'true';
export const ENV = process.env.REACT_APP_NODE_ENV;
export const ALGOLIA_APPLICATION_ID = process.env.REACT_APP_ALGOLIA_APPLICATION_ID as string;
export const ALGOLIA_APPLICATION_SEARCH_API_KEY = process.env
  .REACT_APP_ALGOLIA_SEARCH_API_KEY as string;
export const ALGOLIA_INDEX_NAME = process.env.REACT_APP_ALGOLIA_INDEX_NAME as string;
export const NOVAHEAL_API_KEY = process.env.REACT_APP_API_KEY as string;
export const APPLE_LOGIN_BUNDLE_ID = process.env.REACT_APP_APPLE_LOGIN_BUNDLE_ID as string;
export const APPLE_LOGIN_SCOPE = process.env.REACT_APP_APPLE_LOGIN_SCOPE as string;
export const APPLE_LOGIN_REDIRECT_URL = process.env.REACT_APP_APPLE_LOGIN_REDIRECT_URL as string;
export const APPLE_LOGIN_POP_UP = (process.env.REACT_APP_APPLE_LOGIN_POP_UP as string) !== 'false';
