import { PostSurveyDTO } from 'types/Survey';

export type SurveyInput = Record<string, string | boolean>;

export const clearSurveyInput = (inputValues: SurveyInput): PostSurveyDTO => {
  return Object.entries(inputValues)
    .filter(
      ([key, value]) =>
        /^\/?[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/?$/.test(key) && // filter out non-uuid keys
        (typeof value === 'string' || typeof value === 'boolean') && // filter out non-string/boolean values
        !!value // filter out empty input fields
    )
    .map(([key, value]) => ({
      id: key,
      answer: value.toString(), // only include answer if value is a string
    }));
};
