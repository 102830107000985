/** Use like this:
 *
 * <ConditionalWrapper
 *      condition={isShowPage}
 *      wrapper={(componentChildren: React.ReactNode) => <YourWrapperComponent>{componentChildren}</YourWrapperComponent>}
 *  >
 *         {...yourComponents}
 *  </ConditionalWrapper>
 *
 * */

import React, { ReactElement } from 'react';

type Props = {
  condition: boolean;
  wrapper: (children: React.ReactNode) => ReactElement;
  children: React.ReactNode;
};

export const ConditionalWrapper = ({ condition, wrapper, children }: Props): ReactElement => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return condition ? wrapper(children) : <>{children}</>;
};
