import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { FeedbackValue } from '@novaheal/types';

export type Smiley = {
  level: FeedbackValue;
  icon: string;
};

type Props = {
  handleSmileyClick: (level: FeedbackValue) => Promise<void>;
};

export const FeedbackSmiley = ({
  icon,
  level,
  handleSmileyClick,
}: Smiley & Props): ReactElement => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={async () => handleSmileyClick(level)}
      bg="bg.darken"
      borderRadius="10px"
      fontSize="24px"
      py="3"
      px="5"
      w={{ base: '114px', md: '214px' }}
      h="44px"
      cursor="pointer"
    >
      {icon}
    </Box>
  );
};
