import { AxiosResponse } from 'axios';
import { axios } from 'utils/axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { Feedback, FeedbackMessage, ShortFeedbackMessage } from '@novaheal/types';
import { v4 as uuidv4 } from 'uuid';

export const addFeedbackMessage = async ({
  message,
  shortMessages,
}: FeedbackMessage): Promise<AxiosResponse<Feedback>> => {
  const id = window.localStorage.getItem('feedback') || uuidv4();

  return axios.post(`feedback/message`, { id, message, shortMessages });
};

export const useFeedbackMessage = (): UseMutationResult<
  AxiosResponse<Feedback, ShortFeedbackMessage>,
  unknown,
  FeedbackMessage,
  unknown
> => {
  return useMutation({
    mutationFn: addFeedbackMessage,
    onSuccess: () => {
      window.localStorage.removeItem('feedback');
    },
  });
};
