import { Button, ModalBody, Text, Flex, Box } from '@chakra-ui/react';
import { useBreakpoint } from 'hooks/utils/layout/useBreakpoint';
import React, { ReactElement } from 'react';
import { Breakpoints } from 'types/breakpoints';
import { ModalDislikeVariantProps, SharedModalProps } from 'types/Modal';
import { CustomModal } from '../CustomModal';

type Props = SharedModalProps & ModalDislikeVariantProps;

export const DislikeModal = ({ handleClose, isShown, name, onAccept }: Props): ReactElement => {
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP);
  const handleAccept = async (): Promise<void> => {
    if (onAccept) await onAccept();
    handleClose();
  };
  return (
    <CustomModal
      isOpen={isShown}
      onClose={handleClose}
      isCentered
      maxWidth={{ base: '300px', md: '600px' }}
      hideHeader={!isDesktop}
    >
      <ModalBody p={{ base: '0', md: '0 40px 30px' }} display="flex" flexDirection="column">
        <Box
          p={{ base: '20px', md: '0' }}
          textAlign={{ base: 'center', md: 'left' }}
          mb={{ md: '30px' }}
        >
          <Text>&quot;{name}&quot; wirklich aus den Favoriten entfernen?</Text>
        </Box>
        <Flex
          justifyContent="flex-end"
          w="full"
          h={{ base: '60px', md: 'fit-content' }}
          borderTop={{ base: '1px solid', md: 'none' }}
          borderColor="grey.10"
          p="0px"
        >
          <Button
            onClick={handleClose}
            bg="none"
            w={{ base: 'full', md: '220px' }}
            h={{ base: 'full', md: '40px' }}
            borderRight={{ base: '1px solid', md: 'none' }}
            borderColor="grey.10"
            borderRadius="0px"
            textStyle="h4.reg"
            fontWeight="normal"
            color="grey.secondary"
            _hover={{ bg: 'none' }}
          >
            Zurück
          </Button>
          <Button
            bg={{ base: 'none', md: 'system.error' }}
            textStyle="h4.reg"
            w={{ base: 'full', md: '220px' }}
            h={{ base: 'full', md: '40px' }}
            color={{ base: 'system.error', md: 'grey.textinvers' }}
            borderRadius={{ base: '0px', md: '10px' }}
            fontWeight="normal"
            _hover={{ opacity: '0.8' }}
            onClick={handleAccept}
          >
            Löschen
          </Button>
        </Flex>
      </ModalBody>
    </CustomModal>
  );
};
