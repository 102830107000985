export const errorMessage = {
  empty: 'Bitte angeben.',
  email: 'Bitte eine gültige E-Mail-Adresse angeben.',
  year: 'Bitte gib ein gültiges Jahr in dem Format YYYY ein, bspw. 2030.',
  pwLength: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
  pwRequirements:
    'Das Passwort muss mindestens enthalten: einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl.',
  pwMatching: 'Die Passwörter stimmen nicht überein.',
  checkboxRequired: 'Bitte bestätigen.',

  emptyMessage: 'Deine Nachricht darf nicht leer sein.',
  pwChange: {
    ErrorTitle: 'Änderung fehlgeschlagen.',
    ErrorDescription: 'Dein aktuelles Passwort wurde leider nicht korrekt eingegeben.',
  },
  invalidLetters: 'Die Eingabe darf keine Zahlen oder Sonderzeichen enthalten.',
};
