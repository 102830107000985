import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const EyeIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M11.9999 5.54883C7.16152 5.54883 3.02955 8.22391 1.35547 12C3.02955 15.7761 7.16152 18.4512 11.9999 18.4512C16.8383 18.4512 20.9703 15.7761 22.6443 12C20.9703 8.22391 16.8383 5.54883 11.9999 5.54883ZM11.9999 16.3008C9.32912 16.3008 7.16152 14.374 7.16152 12C7.16152 9.62597 9.32912 7.69922 11.9999 7.69922C14.6707 7.69922 16.8383 9.62597 16.8383 12C16.8383 14.374 14.6707 16.3008 11.9999 16.3008ZM11.9999 9.41953C10.3936 9.41953 9.09688 10.5721 9.09688 12C9.09688 13.4279 10.3936 14.5805 11.9999 14.5805C13.6062 14.5805 14.9029 13.4279 14.9029 12C14.9029 10.5721 13.6062 9.41953 11.9999 9.41953Z"
        fill="currentColor"
      />
    </Icon>
  );
};
