import { ReactElement, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import { FormErrorMessage, FormLabel, FormControl, TypographyProps } from '@chakra-ui/react';

type Props = {
  label?: string;
  labelPosition?: TypographyProps['textAlign'];
  className?: string;
  children: ReactNode;
  error?: FieldError | undefined;
};

export type FieldWrapperPassThroughProps = Omit<Props, 'className' | 'children'>;

export const FieldWrapper = (props: Props): ReactElement => {
  const { label, labelPosition, className, error, children } = props;

  return (
    <FormControl isInvalid={!!error?.message}>
      {label && (
        <FormLabel
          htmlFor={label}
          className={className}
          textStyle="body.16.reg"
          color="grey.tertiary"
          textAlign={labelPosition}
        >
          {label}
        </FormLabel>
      )}
      {children}
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
