import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const CalendarIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 24 24" {...props} fill="none">
      <rect width="24" height="24" />
      <g clipPath="url(#clip0_2_151)">
        <rect x="-476" y="-439" width="866" height="825" rx="24" />
        <rect x="-438" y="-200" width="630" height="387" rx="20" />
        <mask id="path-4-inside-1_2_151" fill="white">
          <path d="M2.39844 7.08594C2.39844 5.42908 3.74158 4.08594 5.39844 4.08594H18.6016C20.2584 4.08594 21.6016 5.42908 21.6016 7.08594V9.33594V19.0859C21.6016 20.7428 20.2584 22.0859 18.6016 22.0859H5.39844C3.74158 22.0859 2.39844 20.7428 2.39844 19.0859V9.33594V7.08594Z" />
        </mask>
        <path
          d="M2.39844 10.0859H21.6016V8.58594H2.39844V10.0859ZM5.39844 5.58594H18.6016V2.58594H5.39844V5.58594ZM18.6016 20.5859H5.39844V23.5859H18.6016V20.5859ZM3.89844 19.0859V9.33594H0.898438V19.0859H3.89844ZM3.89844 9.33594V7.08594H0.898438V9.33594H3.89844ZM20.1016 7.08594V9.33594H23.1016V7.08594H20.1016ZM20.1016 9.33594V19.0859H23.1016V9.33594H20.1016ZM5.39844 20.5859C4.57001 20.5859 3.89844 19.9144 3.89844 19.0859H0.898438C0.898438 21.5712 2.91316 23.5859 5.39844 23.5859V20.5859ZM18.6016 23.5859C21.0868 23.5859 23.1016 21.5712 23.1016 19.0859H20.1016C20.1016 19.9144 19.43 20.5859 18.6016 20.5859V23.5859ZM18.6016 5.58594C19.43 5.58594 20.1016 6.25751 20.1016 7.08594H23.1016C23.1016 4.60066 21.0868 2.58594 18.6016 2.58594V5.58594ZM5.39844 2.58594C2.91316 2.58594 0.898438 4.60066 0.898438 7.08594H3.89844C3.89844 6.25751 4.57001 5.58594 5.39844 5.58594V2.58594Z"
          fill="currentColor"
          mask="url(#path-4-inside-1_2_151)"
        />
        <path
          d="M8 1.91406V6.25684"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M16 1.91406V6.25684"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_151">
          <rect x="-476" y="-439" width="866" height="825" rx="24" />
        </clipPath>
      </defs>
    </Icon>
  );
};
