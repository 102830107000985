import { UseFormRegisterReturn } from 'react-hook-form';
import { InputGroup, InputLeftElement, Select, SelectProps } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { css } from '@emotion/react';
import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type SelectOption = {
  value: string | number;
  label: string;
};

type SelectFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  placeholder: string;
  options: SelectOption[];
  registration: Partial<UseFormRegisterReturn>;
  leftElement?: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  defaultValue?: SelectProps['defaultValue'];
};

export const SelectField = ({
  className,
  label,
  registration,
  error,
  placeholder,
  options,
  leftElement,
  onChange,
  defaultValue,
}: SelectFieldProps): ReactElement => {
  return (
    <FieldWrapper label={label} error={error}>
      <InputGroup>
        {leftElement && (
          <InputLeftElement
            w="40px"
            h="40px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {leftElement}
          </InputLeftElement>
        )}
        <Select
          placeholder={placeholder}
          className={className}
          {...registration}
          bg="bg.input"
          onChange={onChange}
          defaultValue={defaultValue}
          css={css`
            padding-left: 42px;
            & :first-of-type {
              color: grey;
            }
          `}
        >
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </InputGroup>
    </FieldWrapper>
  );
};
