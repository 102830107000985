import { Button, ModalBody, Image, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { ModalPaywallVariantProps, SharedModalProps } from 'types/Modal';
import paywallImage from 'assets/subscription/paywall.png';
import { CustomModal } from '../CustomModal';

type Props = SharedModalProps & ModalPaywallVariantProps;

export const PaywallModal = ({
  handleClose,
  isShown,
  target,
  onUpgradeClick,
}: Props): ReactElement => {
  return (
    <CustomModal
      isOpen={isShown}
      onClose={handleClose}
      hideHeader
      isCentered
      target={target}
      maxWidth={{ base: '80%', md: '800px' }}
    >
      <ModalBody display="flex" gap="25px" flexDirection="column" alignItems="center" p="40px 30px">
        <Image src={paywallImage} maxWidth="400px" />
        <Text textStyle="h2">Du brauchst Premium, um diesen Inhalt zu sehen!</Text>
        <Text textStyle="body.16.reg">
          Leider ist Ihr Abonnement ausgelaufen. Bitte wählen Sie ein für Sie passendes Abonnement
          und viel Spaß beim Lernen!
        </Text>
        <Button variant="primary" onClick={onUpgradeClick}>
          Upgrade auf Premium
        </Button>
      </ModalBody>
    </CustomModal>
  );
};
