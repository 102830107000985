import { createContext, Dispatch, ReactElement, SetStateAction, useMemo, useState } from 'react';
import { Breadcrumb } from 'types/breadcrumbs';

type BreadcrumbContextProps = {
  additionalBreadcrumbs: Breadcrumb[];
  setAdditionalBreadcrumbs: Dispatch<SetStateAction<Breadcrumb[]>>;
  showBreadcrumbs: boolean;
  setShowBreadcrumbs: Dispatch<SetStateAction<boolean>>;
};

export const BreadcrumbContext = createContext<BreadcrumbContextProps>({
  additionalBreadcrumbs: [],
  setAdditionalBreadcrumbs: () => [],
  showBreadcrumbs: true,
  setShowBreadcrumbs: () => null,
});

type Props = {
  children: ReactElement;
};

export const BreadcrumbProvider = ({ children }: Props): ReactElement => {
  const [additionalBreadcrumbs, setAdditionalBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(true);

  const contextValue = useMemo(
    () => ({
      additionalBreadcrumbs,
      setAdditionalBreadcrumbs,
      showBreadcrumbs,
      setShowBreadcrumbs,
    }),
    [additionalBreadcrumbs, setAdditionalBreadcrumbs, showBreadcrumbs, setShowBreadcrumbs]
  );

  return <BreadcrumbContext.Provider value={contextValue}>{children}</BreadcrumbContext.Provider>;
};
