import { Text, Button, Stack, VStack } from '@chakra-ui/react';
import { ReactElement, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Joi from 'joi';
import { Form } from 'components/common/Form';
import { passwordRegex } from 'config/regex';
import { useAuthApi } from 'hooks/api/backend/auth/useAuthApi';
import { ResetPasswordDto } from 'hooks/api/backend/auth/dto/resetPassword.dto';
import { toastContent } from 'config/toast';
import { PageContext } from 'components/common/PageContext';
import { errorMessage } from 'components/common/Form/ErrorMessages';
import { PublicRoutes } from 'types/Routes';
import { InAppLayoutVariant } from 'types/Layout';
import { FieldError } from 'react-hook-form';
import { PasswordInputField } from 'components/common/Form/PasswordInputField';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { PasswordStatusType } from 'types/StatusTypes';
import { useFirebase } from 'hooks/useFirebase';

const schema = Joi.object({
  password: Joi.string().min(8).pattern(new RegExp(passwordRegex)).required().messages({
    'string.min': errorMessage.pwLength,
    'string.empty': errorMessage.empty,
    'string.pattern.base': errorMessage.pwRequirements,
  }),
  passwordConfirmation: Joi.any().valid(Joi.ref('password')).required().messages({
    'any.only': errorMessage.pwMatching,
  }),
});

type ResetPasswordInputs = {
  password: string;
  passwordConfirmation: string;
  token: string;
};

export const ResetPassword = (): ReactElement => {
  const [searchParams] = useSearchParams();
  const { resetPassword } = useAuthApi();
  const navigate = useNavigate();
  const toast = useNovaToast();
  const { logFirebaseUserFlow } = useFirebase();

  const handleSubmit = async (values: ResetPasswordDto): Promise<void> => {
    await resetPassword({
      ...values,
      token: searchParams.get('token'),
    });
    logFirebaseUserFlow('reset_password');
  };

  useEffect(() => {
    if (!searchParams.get('token')) {
      toast.show(toastContent.password[PasswordStatusType.NO_RESET_TOKEN]);

      setTimeout(() => {
        navigate(PublicRoutes.LOGIN);
      }, 1000);
    }
  });

  return (
    <PageContext variant={InAppLayoutVariant.TERTIARY}>
      <VStack align="left" pt="10" m="10" spacing="5" w="82%">
        <Text as="h1" textStyle="h1" color="blue.textHeader">
          Passwort zurücksetzen
        </Text>
        <Text color="grey.secondary" textStyle="body.14.lig">
          Hier kannst du ein neues Passwort vergeben:
        </Text>
        <Form<ResetPasswordInputs, typeof schema>
          onSubmit={handleSubmit}
          schema={schema}
          autoComplete="off"
        >
          {({ register, formState, watch, resetField }) => (
            <>
              <PasswordInputField
                error={formState.errors.password as FieldError}
                placeholder="Passwort"
                register={() => register('password')}
                value={watch('password')}
                reset={() => resetField('password')}
              />
              <PasswordInputField
                error={formState.errors.passwordConfirmation as FieldError}
                placeholder="Passwort wiederholen"
                register={() => register('passwordConfirmation')}
                value={watch('passwordConfirmation')}
                reset={() => resetField('passwordConfirmation')}
              />
              <Stack align="center">
                <Button variant="primary" type="submit" w="full" mt="10">
                  Zurücksetzen
                </Button>
              </Stack>
            </>
          )}
        </Form>
      </VStack>
    </PageContext>
  );
};
