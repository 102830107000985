import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const LightBulbIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 30 27" {...props}>
      <path
        d="M15.102 26.9974C14.5532 26.9727 14.1597 26.4153 14.0408 26.0508H16.2621C16.0437 26.7224 15.6805 27.0233 15.102 26.9974Z"
        fill="#2F9BBA"
      />
      <path
        d="M22.5047 12.2851C22.5047 14.7463 21.3708 16.934 19.6132 18.3214C18.3763 19.2978 16.8304 19.8778 15.1533 19.8778C11.0933 19.8778 7.802 16.4784 7.802 12.2851C7.802 8.09175 11.0933 4.69238 15.1533 4.69238C19.2134 4.69238 22.5047 8.09175 22.5047 12.2851Z"
        fill="#FFD978"
      />
      <path
        d="M10.2869 17.9181C11.1629 19.0298 12.3626 21.3114 12.7449 22.1309H17.6743C18.0529 21.0949 19.0942 19.2237 20.2222 17.7725L10.2869 17.9181Z"
        fill="#FFD978"
      />
      <path
        d="M14.0789 11.4391C12.9569 11.1285 12.5486 11.6697 12.495 12.3821C12.2802 15.2369 13.3173 19.3577 14.0795 22.4139L16.4458 22.2908C16.9004 20.3173 17.8496 15.8277 18.0101 13.6569C18.1705 11.4861 17.7208 11.1283 17.0875 11.1285C15.9444 11.0873 15.7128 13.4925 16.5461 13.4925C17.6498 13.4925 16.9334 11.4391 16.3455 11.5395C16.1853 11.3784 15.8275 11.2254 14.0789 11.4391ZM14.0789 11.4391C14.7082 11.8632 14.6395 13.6569 13.8186 13.6569C13.3124 13.6569 12.7635 12.1461 14.0789 11.4391Z"
        stroke="#BEA447"
        strokeWidth="0.4"
        fill="none"
      />
      <mask
        id="mask0_8058_103789"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="12"
        y="22"
        width="6"
        height="5"
      >
        <path
          d="M12.7422 24.6248V22.1104H17.6802L17.551 24.6248C17.5769 24.9026 17.239 25.4023 17.0669 25.6175C16.8861 25.8821 16.2062 26.1248 15.8888 26.213H14.3879C13.2389 26.1071 12.812 25.1101 12.7422 24.6248Z"
          fill="#4AB7D6"
        />
      </mask>
      <g mask="url(#mask0_8058_103789)">
        <path
          d="M12.7422 24.6248V22.1104H17.6802L17.551 24.6248C17.5769 24.9026 17.239 25.4023 17.0669 25.6175C16.8861 25.8821 16.2062 26.1248 15.8888 26.213H14.3879C13.2389 26.1071 12.812 25.1101 12.7422 24.6248Z"
          fill="#4AB7D6"
        />
        <path
          d="M12.7715 23.2458C14.0048 23.3683 16.2794 23.5241 17.65 22.8018"
          stroke="#2198BA"
          strokeWidth="0.4"
          strokeLinecap="round"
        />
        <path
          d="M12.8152 24.1735C14.0485 24.2961 16.3231 24.4519 17.6937 23.7295"
          stroke="#2198BA"
          strokeWidth="0.4"
          strokeLinecap="round"
        />
        <path
          d="M12.8757 25.0221C14.109 25.1447 16.3837 25.3005 17.7542 24.5781"
          stroke="#2198BA"
          strokeWidth="0.4"
          strokeLinecap="round"
        />
      </g>
      <ellipse
        rx="1.48279"
        ry="0.789775"
        transform="matrix(0.881272 0.472609 -0.454617 0.890687 17.9243 7.91203)"
        fill="#FFEED9"
      />
      <path
        d="M18.6588 17.1323C18.6588 17.1323 19.5928 16.8259 19.9706 16.3605C20.3442 15.9001 20.4723 14.916 20.4723 14.916"
        stroke="#FFEED9"
        strokeWidth="0.2"
        fill="none"
      />
      <path
        d="M18.5923 16.3512C18.5923 16.3512 19.1965 16.153 19.4409 15.8519C19.6825 15.5541 19.7655 14.9174 19.7655 14.9174"
        stroke="#FFEED9"
        strokeWidth="0.2"
        fill="none"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="3.59698"
        y2="-0.5"
        transform="matrix(0.755387 -0.655279 0.636671 0.771135 21.7979 5.80762)"
        stroke="#FFD978"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="3.59698"
        y2="-0.5"
        transform="matrix(-0.755387 -0.655279 -0.636671 0.771135 8.20215 5.80859)"
        stroke="#FFD978"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="2.62478"
        y2="-0.5"
        transform="matrix(0.88847 -0.458934 0.441192 0.897413 24.5518 9.7373)"
        stroke="#FFD978"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="2.62478"
        y2="-0.5"
        transform="matrix(-0.88847 -0.458934 -0.441192 0.897413 5.44775 9.73828)"
        stroke="#FFD978"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="2.85493"
        y2="-0.5"
        transform="matrix(0.305548 -0.952177 0.952731 0.303814 17.6765 3.44434)"
        stroke="#FFD978"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="2.85429"
        y2="-0.5"
        transform="matrix(-0.179948 -0.983676 -0.983875 0.178859 12.3054 3.49609)"
        stroke="#FFD978"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="4.34917"
        y2="-0.5"
        transform="matrix(0.998871 -0.0474972 0.0452109 0.998977 24.7761 14.624)"
        stroke="#FFD978"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="4.34917"
        y2="-0.5"
        transform="matrix(-0.998871 -0.0474972 -0.0452109 0.998977 5.22339 14.625)"
        stroke="#FFD978"
        strokeLinecap="round"
      />
    </Icon>
  );
};
