// TODO: (Later iteration) autofill & disable school fields if voucher got entered

import { Text, Button, Flex } from '@chakra-ui/react';
import { ReactElement, useState } from 'react';
import Joi from 'joi';
import { Form, InputField } from 'components/common/Form';
import { FieldError } from 'react-hook-form';
import { caughtErrorGlobally } from 'utils/axios';
import { AxiosError } from 'axios';
import { useNovaToast } from 'hooks/utils/useNovaToast';
import { ToastVariant } from 'types/Toast';
import { errorMessage } from 'components/common/Form/ErrorMessages';
import { useWelcomePostStep } from 'hooks/api/backend/welcome/useWelcomeStep';
import { NamePrefillData } from 'types/WelcomeScreen';
import { WelcomeScreenOptions } from './Welcome';

type NameValues = {
  firstName: string;
  lastName: string;
};

const schema = Joi.object({
  firstName: Joi.string().required().messages({
    'string.empty': errorMessage.empty,
  }),
  lastName: Joi.string().required().messages({
    'string.empty': errorMessage.empty,
  }),
});

export const UserInfo = (props: WelcomeScreenOptions): ReactElement => {
  const { onContinue, stepData } = props;
  const prefillData = stepData.currentData.prefillData as NamePrefillData;
  const toast = useNovaToast();
  const [isLoading, setLoading] = useState(false);
  const { mutateAsync: updateWelcomeStep } = useWelcomePostStep();

  const handleSubmit = async (values: NameValues): Promise<void> => {
    setLoading(true);
    try {
      const onboardingData = await updateWelcomeStep({
        stepIdentifier: stepData.currentStepIdentifier,
        totalSteps: stepData.totalSteps,
        step: stepData.currentStep,
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
        },
      });
      onContinue(onboardingData);
    } catch (error) {
      if (!caughtErrorGlobally(error as AxiosError)) {
        toast.show({
          variant: ToastVariant.ERROR,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex width="100%" direction="column" gap="0px">
      <Text as="h1" textStyle="h2" color="blue.textHeader" mb="25px">
        Wer bist du?
      </Text>
      <Text textStyle="body.16.reg" color="grey.secondary" mb="25px">
        Gib uns noch ein paar Infos über dich.
      </Text>

      <Form<NameValues, typeof schema> onSubmit={handleSubmit} schema={schema} autoComplete="off">
        {({ register, formState }) => {
          return (
            <>
              <Flex direction="column" gap="20px">
                <InputField
                  registration={register('firstName')}
                  placeholder="Vorname"
                  error={formState.errors.firstName as FieldError}
                  defaultValue={prefillData.firstName}
                />
                <InputField
                  registration={register('lastName')}
                  placeholder="Nachname"
                  error={formState.errors.lastName as FieldError}
                  defaultValue={prefillData.lastName}
                />
              </Flex>

              <Button
                translate="no"
                variant="primary"
                type="submit"
                isLoading={isLoading}
                loadingText="Speichern"
                mt="40px"
                w="full"
                py="10px"
              >
                Weiter
              </Button>
            </>
          );
        }}
      </Form>
    </Flex>
  );
};
