import { Flex, Image, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

type Props = {
  imageUrl: string;
  title: string;
  description: string;
};

export const SubscriptionBenefit = ({ imageUrl, description, title }: Props): ReactElement => {
  return (
    <Flex flexDirection="row" gap="15px" alignItems="center">
      <Image src={imageUrl} width="40px" height="40px" />
      <Flex flexDirection="column" color="grey.textPrimary" gap="5px">
        <Text textStyle="body.14.med">{title}</Text>
        <Text textStyle="body.12.reg">{description}</Text>
      </Flex>
    </Flex>
  );
};
