import { ReactElement } from 'react';
import { Text, Tab, Circle, useTab, Box, VStack } from '@chakra-ui/react';
import { TabData } from 'types/tab';
import { TabBarVariant } from './TabBar';

type Props = TabData & {
  variant?: TabBarVariant;
};

export const CustomTab = ({
  icon,
  tabName,
  num,
  variant = TabBarVariant.ARTICLE,
  ...rest
}: Props): ReactElement => {
  const tabProps = useTab(rest);
  const isSelected = !!tabProps['aria-selected'];
  const showNum = !isSelected && variant !== TabBarVariant.ARTICLE;
  const bgColor: string = variant === TabBarVariant.SEARCH && isSelected ? 'blue.tertiary' : '';
  const textAlign = variant === TabBarVariant.SEARCH ? 'left' : 'center';
  const textStyle = variant === TabBarVariant.SEARCH ? 'body.12.reg' : 'body.16.reg';
  const marginRight = variant === TabBarVariant.SEARCH ? '12px' : '0px';
  const position = variant === TabBarVariant.SEARCH ? 'absolute' : 'static';
  const rightAlignment = variant === TabBarVariant.SEARCH ? '-3px' : '';
  const getBorderColor = (tabVariant: TabBarVariant): string => {
    switch (tabVariant) {
      case TabBarVariant.ARTICLE:
        return 'green.secondary';
      case TabBarVariant.FAVORITE:
        return 'blue.tertiary';
      default:
        return '';
    }
  };
  const color = variant === TabBarVariant.FAVORITE ? 'grey.textPrimary' : getBorderColor(variant);

  return (
    <Tab
      mr={marginRight}
      position="relative"
      borderBottomWidth={3}
      _selected={{
        borderColor: getBorderColor(variant),
        color,
      }}
      _focus={{ outline: 'none' }}
      {...tabProps}
    >
      {icon}
      <VStack align={textAlign} mt="10px" spacing="4px">
        <Text textStyle={textStyle} mx="6px">
          {tabName}
        </Text>
        <Box h="3px" w="100%" bg={bgColor} borderRadius="1px" />
      </VStack>
      {showNum && (
        <Circle
          bg="blue.quaternary"
          size="20px"
          color="bg.light"
          ml="4px"
          mt="3px"
          border="none"
          position={position}
          right={rightAlignment}
        >
          <Text textStyle="body.12.reg">{num}</Text>
        </Circle>
      )}
    </Tab>
  );
};
