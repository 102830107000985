import { useMetaContext } from 'hooks/utils/useMetaContext';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

export const MetaData = (): ReactElement => {
  const { currentMetaDataValues } = useMetaContext();

  return (
    <Helmet>
      <title>
        {currentMetaDataValues.headTitle
          ? `Novaheal | ${currentMetaDataValues.headTitle}`
          : 'Novaheal'}
      </title>
    </Helmet>
  );
};
